import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

import Moment from 'moment';
import Draggable from 'react-draggable';

//Store
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n/Types';

import { AdtrackTheme } from '../../Themes';

//Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MoreIcon from '@material-ui/icons/More';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import RadioIcon from '@material-ui/icons/Radio';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import TvIcon from '@material-ui/icons/Tv';
import LanguageIcon from '@material-ui/icons/Language';

// Material components
import MuiDialogTitle from '@material-ui/core/DialogTitle';

//Router
import { Link } from 'react-router-dom';

import {
  ISearchParams,
  ISearchState,
  ISpotRegroupement,
  IMetadatas,
  IOrderValue,
  IAds,
  CAdsInitalState,
  COUNTRY_OPTIONS,
  IMusicStateTypes,
  EMediaTypes,
  Result,
} from '../../Store/Search/Types';
import {
  getMediaWeb,
  launchSearch,
  paginateSearch,
} from '../../Store/Search/Actions';
import { selectMusic, setSpotDetails } from '../../Store/SpotDetails/Actions';
import {
  ISpotDetailsState,
  IFingerprintBucketPair,
  ESpotDetailsSource,
  SPOT_STATUS_GERMANY,
  GROUP_STATUS_FRANCE,
} from '../../Store/SpotDetails/Types';
import { fetchFormulaire } from '../../Store/Formulaire/Actions';
import { TableCell, TableHeaderCell } from '../../Themes/StyledElements';
import { InfoIcon } from '../../Themes/svg/info_icon';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import { isNullOrUndefined } from '../../Utils/Various';

import { IUserState } from '../../Store/User/Types';
import { PaperComponent } from '../HelpFunction';
import { PlayerMaster } from '../PlayerMaster';

const styles = (theme: Theme) =>
  MD.createStyles({
    closeButton: {
      position: 'absolute',
      right: '8px',
      top: '5px',
      color: theme.palette.text.primary,
    },
    contentTableRow: {
      borderCollapse: 'separate',
      borderRadius: '5px',
      marginBottom: '1px',
    },
    detailsIcon: {
      transform: 'rotate(0.5turn) rotateX(45deg)',
      display: 'block',
      margin: '0 auto',
    },
    iconStyle: {
      fontSize: '17px',
    },
    paperOverflow: {
      background: 'none',
      boxShadow: 'none',
    },
    tableContainer: {
      padding: '0px',
      overflow: 'auto',
      maxHeight: 'calc(100vh - 124px)',
    },
    videoPlayer: {
      maxWidth: '100%',
      margin: '0 auto',
      marginTop: '20px',
      display: 'block',
    },
    thumbnail: {
      zIndex: 1201,
      position: 'fixed',
      marginTop: '-135px',
      marginLeft: '-30px',
    },
    muiTablePaginationCaption: {
      fontSize: '11px',
    },
    reduceSvgIconSize: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
    resultDetails: {
      fontSize: '14px',
      fontFamily: 'roboto',
      fontWeight: 500,
      width: '30%',
      marginRight: '30px',
      marginLeft: '30px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      paddingTop: '0px',
      paddingLeft: '10px',
      paddingBottom: '0px',
      top: '0px',
      zIndex: 1,
    },
    noMusic: {
      paddingLeft: '5px',
      padding: '2px',
      fontSize: '14px',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    buttonDensed: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    title: {
      flex: '1 1 100%',
    },
    caption: {
      flex: '1 1 100%',
    },
  });

interface IPropsFromState {
  i18n: I18N;
  search: ISearchState;
  spotDetails: ISpotDetailsState;
  user: IUserState;
  localeInfos: ILocaleInfos;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
  fetchFormulaire: typeof fetchFormulaire;
  launchSearch: typeof launchSearch;
  setSpotDetails: typeof setSpotDetails;
  paginateSearch: typeof paginateSearch;
  getMediaWeb: typeof getMediaWeb;
  selectMusic: typeof selectMusic;
}

type AllProps = WithStyles<typeof styles> &
  IPropsFromState &
  IPropsFromDispatch &
  IPropsTheme &
  IConnectedReduxProps;

interface IState {
  currentPage: number;
  perPage: number;
  totalPages: number;
  totalTrackCount: number;
  videoDialogOpened: boolean;
  currentVideoPlayingSource: string | null;
  metadataPlaying: any;
  selectedItem: IMetadatas | null;
  selectedItemIndex: number;
  hover: boolean;
  selected: any[];
  playerMasterBucketName: string;
  playerMasterFilePath: string;
  isPlayerMasterOpen: boolean;
}

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  i18n: I18N;
  theme: AdtrackTheme;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  totalPages: number;
  i18n: I18N;
  theme: any;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props: any) => props.theme.palette.text.primary};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, i18n, onClose, theme, ...other } = props;
  return (
    <MuiDialogTitle
      {...other}
      style={{
        cursor: 'move',
        color: theme.palette.primary.main,
        padding: theme.spacing(2),
      }}
    >
      <MD.Typography>{children}</MD.Typography>
      {onClose ? (
        <MD.Tooltip title={i18n._('Close')}>
          <MD.IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseRoundedIcon />
          </MD.IconButton>
        </MD.Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, totalPages, onPageChange, theme } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (page >= 1) {
      onPageChange(event, page - 1);
    }
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.primary,

        flexDirection: 'row',
        flexFlow: 'row nowrap',
        flexShrink: 0,
      }}
    >
      <MD.IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </MD.IconButton>
      <MD.IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </MD.IconButton>
      <MD.Typography variant="caption" component="div">
        Page {page + 1} of {totalPages}
      </MD.Typography>
      <MD.IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </MD.IconButton>
      {!(page >= Math.ceil(count / rowsPerPage) - 1) && (
        <MD.IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </MD.IconButton>
      )}
    </div>
  );
}

class DashboardContentComponent extends React.Component<AllProps, IState> {
  private htmlSelectedRef: React.RefObject<HTMLInputElement>;
  private videoNode: React.RefObject<HTMLVideoElement>;

  public constructor(props: AllProps) {
    super(props);
    this.state = {
      currentPage: 0,
      perPage: 100,
      totalPages: 0,
      totalTrackCount: 0,
      videoDialogOpened: false,
      currentVideoPlayingSource: null,
      metadataPlaying: null,
      selectedItem: null,
      selectedItemIndex: -1,
      hover: false,
      selected: [],
      playerMasterBucketName: '',
      playerMasterFilePath: '',
      isPlayerMasterOpen: false,
    };
    this.htmlSelectedRef = React.createRef();
    this.videoNode = React.createRef();
  }

  public componentDidMount() {
    if (isNullOrUndefined(this.props.search.searchResults)) {
      let params = this.props.search.params;
      let country = params.country;
      if (isNullOrUndefined(country)) {
        country =
          this.props.user.userInfo.localisation &&
            this.props.user.userInfo.localisation[0] &&
            this.props.user.userInfo.localisation[0].toLowerCase() === 'de'
            ? COUNTRY_OPTIONS.GERMANY
            : COUNTRY_OPTIONS.FRANCE;
      }
      this.props.launchSearch({
        ...params,
        country,
      });
    }

    if (this.props.search.supportType.length === 0) {
      this.props.getMediaWeb();
    }
  }

  static getDerivedStateFromProps(props: AllProps, state: IState) {
    state.currentPage = props.search.params.params.page - 1;

    return state;
  }

  private handleClose = () => {
    this.setState({ videoDialogOpened: false });
  };

  private onOrderByClick = (fieldValue: string) => {
    const { params } = this.props.search;

    const currentOrderIndex = this.props.search.params.params.order_by
      .map((item) => item.field)
      .indexOf(fieldValue);

    let newOrderList = [...this.props.search.params.params.order_by];

    if (currentOrderIndex > -1) {
      // Order field is already in the order list. Switch asc => desc or desactivate
      if (newOrderList[currentOrderIndex].value === IOrderValue.DESC) {
        //desacivate
        if (newOrderList.length > 1) {
          newOrderList.splice(currentOrderIndex, 1);
        } else {
          newOrderList = [];
        }
      } else {
        newOrderList[currentOrderIndex].value = IOrderValue.DESC;
      }
    } else {
      // Order field is not in the order list. Push it to the head of list and set with ASC value
      newOrderList = [
        {
          field: fieldValue,
          value: IOrderValue.ASC,
        },
        ...newOrderList,
      ];
    }

    let searchParams = {
      ...params,
      params: {
        ...params.params,
        order_by: newOrderList,
      },
    };
    this.props.launchSearch(searchParams);
  };

  private drawOrderByIcons = (fieldValue: string) => {
    const { theme } = this.props;
    let colorASC = theme.palette.text.primary;
    let colorDESC = theme.palette.text.primary;
    const currentOrderIndex = this.props.search.params.params.order_by
      .map((item) => item.field)
      .indexOf(fieldValue);

    if (
      currentOrderIndex > -1 &&
      this.props.search.params.params.order_by[currentOrderIndex].field ===
      fieldValue
    ) {
      if (
        this.props.search.params.params.order_by[currentOrderIndex].value ===
        IOrderValue.ASC
      ) {
        colorASC = theme.palette.primary.main;
      } else {
        colorDESC = theme.palette.primary.main;
      }
    }
    return (
      <MD.IconButton
        style={{ padding: '4px' }}
        onClick={this.onOrderByClick.bind(this, fieldValue)}
      >
        <MD.Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <MD.Grid item style={{ marginBottom: '-20px' }}>
            <ArrowDropUpIcon style={{ height: '20px', color: colorASC }} />
          </MD.Grid>
          <MD.Grid item>
            <ArrowDropDownIcon style={{ height: '20px', color: colorDESC }} />
          </MD.Grid>
        </MD.Grid>
      </MD.IconButton>
    );
  };

  private drawProiverHeaderColLabel = () => {
    const { media } = this.props.search.params;
    if (media.includes(EMediaTypes.RADIO)) {
      return this.props.i18n._('Radio station');
    }
    return this.props.i18n._('channel');
  };

  private drawResultsTableHeaders = () => {
    let headCells: Array<{
      id: number;
      numeric: boolean;
      disablePadding: boolean;
      label: string;
      fieldValue: null | string;
    }> = [];
    const { params } = this.props.search;
    headCells = [
      {
        id: 0,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('Media'),
        fieldValue: null,
      },
      {
        id: 2,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('Advertiser'),
        fieldValue: 'ads.metadatas.advertiser.name.raw',
      },
      {
        id: 3,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('Product'),
        fieldValue: 'ads.metadatas.product_label.raw',
      },
      {
        id: 4,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('Arpp Code'),
        fieldValue: 'ads.metadatas.arpp_code.raw',
      },
      {
        id: 5,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('GRP'),
        fieldValue: 'ads.nb_films',
      },
      {
        id: 6,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('1st broadcast'),
        fieldValue: 'start_date',
      },
      {
        id: 7,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('duration'),
        fieldValue: 'ads.metadatas.duration',
      },
      {
        id: 8,
        numeric: false,
        disablePadding: true,
        label: this.drawProiverHeaderColLabel(),
        fieldValue: 'ads.metadatas.media.name.raw',
      },
      {
        id: 9,
        numeric: false,
        disablePadding: true,
        label: this.props.i18n._('Details'),
        fieldValue: null,
      },
    ];
    if (params.media[0].toLowerCase() === 'radio') {
      headCells = [
        {
          id: 0,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Media'),
          fieldValue: null,
        },
        {
          id: 3,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Advertiser'),
          fieldValue: 'ads.metadatas.advertiser.name.raw',
        },
        {
          id: 4,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Product'),
          fieldValue: 'ads.metadatas.product_label.raw',
        },
        {
          id: 6,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('GRP'),
          fieldValue: 'ads.nb_films',
        },
        {
          id: 7,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('1st broadcast'),
          fieldValue: 'start_date',
        },
        {
          id: 8,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('duration'),
          fieldValue: 'ads.metadatas.duration',
        },
        {
          id: 9,
          numeric: false,
          disablePadding: true,
          label: this.drawProiverHeaderColLabel(),
          fieldValue: 'ads.metadatas.media.name.raw',
        },
        {
          id: 10,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Details'),
          fieldValue: null,
        },
      ];
    }
    return (
      <MD.TableHead>
        <MD.TableRow>
          {headCells.map(
            (
              headCell: {
                id: number;
                numeric: boolean;
                disablePadding: boolean;
                label: string;
                fieldValue: null | string;
              },
              index: number
            ) => {
              return (
                <TableHeaderCell
                  key={index}
                  style={{
                    textTransform: 'uppercase',
                  }}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <MD.Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    style={{ fontSize: '10px' }}
                  >
                    {this.displayLabelWithBadge(headCell)}
                    {headCell.fieldValue !== null ? (
                      <MD.Grid item>
                        {this.drawOrderByIcons(headCell.fieldValue)}
                      </MD.Grid>
                    ) : null}
                  </MD.Grid>
                </TableHeaderCell>
              );
            }
          )}
        </MD.TableRow>
      </MD.TableHead>
    );
  };

  private displayLabelWithBadge = (headCell: any) => {
    //const badgeCount = this.props.search.params.params.order_by.map((item)=>item.field).indexOf(headCell.fieldValue) + 1;
    if (headCell.label) {
      /* if (badgeCount) {
          return <MD.Badge badgeContent={badgeCount} color="default" style={{ height: '10px', color: theme.palette.primary.main}}> <MD.Grid item>{headCell.label}</MD.Grid> </MD.Badge>
        } else { */
      return <MD.Grid item>{headCell.label}</MD.Grid>;
      //}
    }
    return <MD.Grid item></MD.Grid>;
  };

  private handleDetailsClicked = (item: ISpotRegroupement) => {
    let startDate = this.props.search.params.start_date;
    let endDate = this.props.search.params.end_date;
    if (startDate !== null && typeof startDate !== 'string') {
      startDate = startDate as Moment.Moment;
      startDate = startDate.format('YYYY-MM-DDTHH:mm:ss');
    }

    if (endDate !== null && typeof endDate !== 'string') {
      endDate = endDate as Moment.Moment;
      endDate = endDate.format('YYYY-MM-DDTHH:mm:ss');
    }
    this.props.setSpotDetails(item, startDate, endDate);
  };

  private showMusicIcon = (item: any, ads: IAds) => {
    if (ads.music_state === IMusicStateTypes.IDENTIFIED) {
      return (
        <MD.Tooltip title={this.props.i18n._('identified')}>
          <MusicNoteIcon style={{ fontSize: '23px', color: '#008000' }} />
        </MD.Tooltip>
      );
    }
    if (ads.music_state === IMusicStateTypes.NON_IDENTIFIED) {
      return (
        <MD.Tooltip title={this.props.i18n._('non identified')}>
          <MusicNoteIcon style={{ fontSize: '23px', color: '#cc0c0c' }} />
        </MD.Tooltip>
      );
    }
    if (
      this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
      ads.music_state === IMusicStateTypes.NO_MUSIC &&
      GROUP_STATUS_FRANCE.VALIDATED.indexOf(item.state) > -1
    ) {
      // TODO check retour item.state
      return (
        <MD.Tooltip title={this.props.i18n._('no music')}>
          <MD.Typography className={this.props.classes.noMusic}>
            {'0'}
          </MD.Typography>
        </MD.Tooltip>
      );
    } else if (
      this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
      ads.music_state === IMusicStateTypes.NO_MUSIC &&
      SPOT_STATUS_GERMANY.VALIDATED === item.state
    ) {
      return (
        <MD.Tooltip title={this.props.i18n._('no music')}>
          <MD.Typography className={this.props.classes.noMusic}>
            {'0'}
          </MD.Typography>
        </MD.Tooltip>
      );
    }
  };

  private handleShowVideoPlayerClick = (
    filepath: string,
    id: string,
    metadatas: any
  ) => {
    if (isNullOrUndefined(filepath)) return;

    const bucket_name = metadatas.bucket_id === 1 ? 'arpp_tv_ad' : 'kantar_fr_radio'
    const filepath2 = bucket_name === 'arpp_tv_ad' ? filepath.replace('/mnt/nas04/arpp_extracts', '').replace('/mnt/nas11/arpp_extracts', '') : '/' + filepath

    this.setState({
      playerMasterBucketName: bucket_name,
      playerMasterFilePath: filepath2,
      isPlayerMasterOpen: true,
    });
    this.setState({
      currentVideoPlayingSource: filepath,
      videoDialogOpened: true,
      metadataPlaying: metadatas,
      selected: [id],
    });
  };

  private showVideoPlayer = () => {
    const { classes, theme } = this.props;
    let videoSource = this.state.currentVideoPlayingSource;
    if (videoSource) {
      return (
        <MD.Dialog
          onClose={this.handleClose.bind(this)}
          open={this.state.videoDialogOpened}
          maxWidth="lg"
          style={{ overflow: 'hidden' }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            theme={theme}
            i18n={this.props.i18n}
            id="draggable-dialog-title"
            onClose={this.handleClose.bind(this)}
          >
            Title
          </DialogTitle>
          <MD.DialogContent style={{ padding: '2px 12px' }}>
            <video autoPlay controls className={classes.videoPlayer}>
              <source src={videoSource} />
            </video>
          </MD.DialogContent>
        </MD.Dialog>
      );
    }
    return null;
  };

  private isValidatedSpot(item: any) {
    const { classes } = this.props;
    if (
      item.state &&
      ((this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
        item.state !== 11 &&
        item.state !== 12) ||
        (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
          (item.state === SPOT_STATUS_GERMANY.MUSIC_RECO_OK ||
            item.state === SPOT_STATUS_GERMANY.CREATED)))
    ) {
      return (
        <MD.Tooltip
          title={this.props.i18n._(
            'Advertising awaiting verification by an operator'
          )}
        >
          <span className={classes.reduceSvgIconSize}>
            <SvgIcon
              component={InfoIcon}
              htmlColor={this.props.theme.palette.text.primary}
            />
          </span>
        </MD.Tooltip>
      );
    }
  }

  private toggleHover(item: IMetadatas, item_index: number, hover: boolean) {
    if (!hover) {
      this.setState({
        hover: hover,
        selectedItem: item,
        selectedItemIndex: item_index,
      });
    } else {
      this.setState({
        hover: hover,
        selectedItem: item,
        selectedItemIndex: item_index,
      });
    }
  }
  private showThumbnail(src: string) {
    const { classes } = this.props;
    return (
      <img
        src={src}
        className={classes.thumbnail}
        alt="thumbnail"
        width="200"
        height="150"
      />
    );
  }
  private isSelected(id: any) {
    return this.state.selected.indexOf(id) !== -1;
  }
  private handleDetailsClick(fgpid: any) {
    this.props.selectMusic(fgpid);
    this.setState({
      selected: [],
    });
  }
  private handleDeDetailsClick(fgpid: any) {
    this.props.selectMusic(fgpid);
  }
  private getFilepath(firstBroadcast: any) {
    let filepath: any = '';
    let bucketId = firstBroadcast.bucket_id;
    if (bucketId === ESpotDetailsSource.ARPP) {
      filepath = firstBroadcast.master_video_filepath;
    } else if (
      (bucketId === ESpotDetailsSource.KANTAR ||
        bucketId === ESpotDetailsSource.FR_RADIO) &&
      firstBroadcast.master_filepath !== null
    ) {
      filepath = firstBroadcast.master_filepath.includes('/media/')
        ? '/' + firstBroadcast.master_filepath.split('/media/')[1]
        : firstBroadcast.master_filepath;
    } else filepath = firstBroadcast.master_filepath;
    return filepath;
  }

  private drawResultsTableRow = (item: Result, index: number) => {
    const { classes, spotDetails, theme, i18n, localeInfos } = this.props;
    let ads: any = item.ads;
    let shouldScrollTo = false;
    let rowProps: any = {
      className: classes.contentTableRow,
      key: 'listitemtext-' + item.id,
    };

    if (!ads) {
      ads = { ...CAdsInitalState } as IAds;
    }
    let metadatas = ads.metadatas;

    if (metadatas.length === 0) return;

    let firstBroadcast = metadatas[0];

    for (
      let i = 0;
      firstBroadcast.media === null && i < metadatas.length;
      i += 1
    ) {
      firstBroadcast = metadatas[i];
    }
    for (
      let i = 0;
      (firstBroadcast.master_filepath === null ||
        firstBroadcast.master_filepath === '') &&
      i < metadatas.length;
      i += 1
    ) {
      firstBroadcast = metadatas[i];
    }

    if (spotDetails.fingerprint_id === firstBroadcast.fingerprint_id) {
      shouldScrollTo = true;
      rowProps = {
        ...rowProps,
        ref: this.htmlSelectedRef,
      };
    }

    let startDate: Moment.Moment | string = item.start_date || '';
    if (startDate !== null && startDate !== '') {
      let locale: string = 'fr';
      if (localeInfos.momentLocale === 'en-us') {
        locale = 'en-us';
      } else if (localeInfos.momentLocale === 'fr') {
        locale = 'fr';
      } else if (localeInfos.momentLocale === 'de') {
        locale = 'en-us';
      }
      startDate = Moment.utc(startDate)
        .locale(locale)
        .local()
        .format('DD MMM YYYY');
    }

    const mediaTypeIconFct: any = (color?: any) => {
      let mediaTypeIcon: any = null;
      if (
        (firstBroadcast.media && firstBroadcast.media.type === 'Video') ||
        firstBroadcast.media_type === 'TV'
      ) {
        mediaTypeIcon = (
          <TvIcon className={classes.iconStyle} style={{ color }} />
        );
      } else if (
        (firstBroadcast.media &&
          firstBroadcast.media.type === EMediaTypes.RADIO) ||
        (firstBroadcast.media_type &&
          firstBroadcast.media_type.toUpperCase() === 'RADIO')
      ) {
        mediaTypeIcon = (
          <RadioIcon className={classes.iconStyle} style={{ color }} />
        );
      } else if (firstBroadcast.media_type === 'WEB') {
        mediaTypeIcon = (
          <LanguageIcon className={classes.iconStyle} style={{ color }} />
        );
      }
      return mediaTypeIcon;
    };

    const fingerprintBucketList = metadatas.map((item: IMetadatas) => {
      return { fgp_id: item.fingerprint_id, bucket_id: item.bucket_id };
    });

    let filepath: any = this.getFilepath(firstBroadcast); //'RADIO/AD/2023/01/04/16653703.mp3'
    let resultRowComponent;
    let isItemSelected: boolean = this.isSelected(item.id as string);
    if (
      this.state.selected.length === 0 &&
      spotDetails.fingerprint_id === firstBroadcast.fingerprint_id
    ) {
      isItemSelected = true;
    }
    if (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE) {
      let color: any = undefined;
      if (
        (item.state === GROUP_STATUS_FRANCE.VALIDATED ||
          item.state === GROUP_STATUS_FRANCE.NEW_MUSIC_DETECTED) &&
        item.ads &&
        item.ads.music_broadcast &&
        item.ads.music_broadcast.length > 0
      ) {
        color = '#33BA62';
      }
      resultRowComponent = (
        <MD.TableRow
          selected={isItemSelected}
          style={{
            backgroundColor: isItemSelected
              ? theme.palette.background.paper
              : undefined,
          }}
          {...rowProps}
        >
          <TableCell align="center">
            <MD.Grid container direction="row" wrap="nowrap">
              {firstBroadcast.master_filepath && (
                <>
                  <MD.Grid item>
                    {this.canDrawThumbnail() &&
                      this.state.hover &&
                      this.state.selectedItem !== null &&
                      !isNullOrUndefined(
                        this.state.selectedItem.master_video_thumbnail
                      ) &&
                      this.state.selectedItemIndex === index &&
                      this.showThumbnail(
                        this.state.selectedItem.master_video_thumbnail
                      )}
                    <MD.IconButton
                      style={{
                        padding: '6px',
                        visibility: filepath === null ? 'hidden' : undefined,
                      }}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        this.handleShowVideoPlayerClick(
                          filepath,
                          item.id,
                          firstBroadcast
                        );
                      }}
                      onMouseEnter={() =>
                        this.toggleHover(firstBroadcast, index, true)
                      }
                      onMouseLeave={() =>
                        this.toggleHover(firstBroadcast, index, false)
                      }
                    >
                      <PlayCircleFilledIcon
                        className={classes.iconStyle}
                        color="secondary"
                      />
                    </MD.IconButton>
                  </MD.Grid>
                </>
              )}
            </MD.Grid>
          </TableCell>
          {/* <TableCell align='center'>
                  <MD.Grid container justifyContent='space-between'>
                    <MD.Grid item>
                        {this.showMusicIcon(item, ads)}
                    </MD.Grid>
                    <MD.Grid item>
                        {this.isValidatedSpot(item)}
                    </MD.Grid>
                  </MD.Grid>
                </TableCell> */}

          <TableCell>{firstBroadcast.advertiser.name}</TableCell>

          <TableCell>{firstBroadcast.product_label}</TableCell>

          {this.props.search.params.media[0].toLowerCase() !== 'radio' && (
            <TableCell>
              {firstBroadcast.arpp_code === null
                ? ''
                : firstBroadcast.arpp_code}
            </TableCell>
          )}
          <TableCell align="center">{metadatas.length}</TableCell>
          <TableCell align="center">{startDate}</TableCell>

          <TableCell align="center">{firstBroadcast.duration}</TableCell>

          <TableCell>
            {firstBroadcast.media ? firstBroadcast.media.name : ''}
          </TableCell>

          <TableCell align="center">
            {item.has_formulaire ? (
              <StyledLink
                theme={theme}
                to={
                  '/formulaire/' +
                  firstBroadcast.bucket_id +
                  '/' +
                  firstBroadcast.fingerprint_id
                }
                onClick={(event: any) => {
                  event.stopPropagation();
                  this.fetchFormulaire(fingerprintBucketList);
                }}
              >
                <MD.IconButton style={{ padding: '2px' }}>
                  <InsertDriveFileIcon className={classes.detailsIcon} />
                </MD.IconButton>
              </StyledLink>
            ) : null}
            <StyledLink
              theme={theme}
              to={
                '/details/' +
                firstBroadcast.bucket_id +
                '/' +
                firstBroadcast.fingerprint_id
              }
              onClick={(event: any) => {
                event.stopPropagation();
                this.handleDetailsClick(firstBroadcast.fingerprint_id);
              }}
            >
              <MD.IconButton style={{ padding: '2px' }}>
                <MoreIcon className={classes.detailsIcon} />
              </MD.IconButton>
            </StyledLink>
          </TableCell>
        </MD.TableRow>
      );
    } else if (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
      let color: any = undefined;
      if (
        (SPOT_STATUS_GERMANY.VALIDATED === item.state ||
          SPOT_STATUS_GERMANY.MUSIC_RECO_OK === item.state) &&
        item.ads &&
        item.ads.music_broadcast &&
        item.ads.music_broadcast.length > 0
      ) {
        color = '#33BA62';
      }
      resultRowComponent = (
        <React.Fragment key={'fragment-' + item.id}>
          <MD.TableRow
            selected={isItemSelected}
            {...rowProps}
            id={'collapse-icon-' + item.id}
            style={{
              backgroundColor: isItemSelected
                ? theme.palette.background.paper
                : undefined,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              this.handleOpenMetadataDetails(event, item.id as any)
            }
          >
            <TableCell align="center">{mediaTypeIconFct(color)}</TableCell>

            <TableCell align="center">
              <MD.Grid container direction="row" wrap="nowrap">
                <MD.Grid item>
                  <MD.IconButton
                    style={{
                      padding: '6px',
                      visibility: filepath === null ? 'hidden' : undefined,
                    }}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      this.handleShowVideoPlayerClick(
                        filepath,
                        item.id,
                        firstBroadcast
                      );
                    }}
                  >
                    <PlayCircleFilledIcon
                      className={classes.iconStyle}
                      color="secondary"
                    />
                  </MD.IconButton>
                </MD.Grid>
                <MD.Grid item>
                  <MD.IconButton
                    style={{ padding: '6px' }}
                    href={firstBroadcast.master_filepath}
                    download
                    onClick={(event: any) => {
                      event.stopPropagation();
                    }}
                  >
                    <GetAppIcon
                      className={classes.iconStyle}
                      color="secondary"
                    />
                  </MD.IconButton>
                </MD.Grid>
              </MD.Grid>
            </TableCell>
            <TableCell>{firstBroadcast.advertiser.name}</TableCell>
            <TableCell>{firstBroadcast.product_label}</TableCell>
            <TableCell>{ads.nb_films}</TableCell>
            <TableCell align="center">{startDate}</TableCell>
            <TableCell align="center">{firstBroadcast.duration}</TableCell>
            <TableCell>
              <MD.Tooltip title={firstBroadcast.nielsen_id || ''}>
                <span>{firstBroadcast.nielsen_id}</span>
              </MD.Tooltip>
            </TableCell>
            <TableCell align="center">
              {item.has_formulaire ? (
                <StyledLink
                  theme={theme}
                  to={
                    '/formulaire/' +
                    firstBroadcast.bucket_id +
                    '/' +
                    firstBroadcast.fingerprint_id
                  }
                  onClick={(event: any) => {
                    event.stopPropagation();
                    this.fetchFormulaire(fingerprintBucketList);
                  }}
                >
                  <MD.IconButton style={{ padding: '2px' }}>
                    <InsertDriveFileIcon className={classes.detailsIcon} />
                  </MD.IconButton>
                </StyledLink>
              ) : null}
              <StyledLink
                theme={theme}
                to={
                  '/details/' +
                  firstBroadcast.bucket_id +
                  '/' +
                  firstBroadcast.fingerprint_id
                }
                onClick={(event: any) => {
                  event.stopPropagation();
                  this.handleDeDetailsClick(firstBroadcast.fingerprint_id);
                }}
              >
                <MD.IconButton style={{ padding: '2px' }}>
                  <MoreIcon className={classes.detailsIcon} />
                </MD.IconButton>
              </StyledLink>
            </TableCell>
          </MD.TableRow>
          <MD.TableRow>
            <TableCell
              size="small"
              variant="footer"
              style={{
                borderBottom: '0',
                padding: 0,
                backgroundColor: theme.palette.background.paper,
              }}
              colSpan={10}
            >
              <MD.Box id={'collapse-' + item.id}>
                <MD.Grid
                  container
                  justifyContent="space-between"
                  direction="row"
                  style={{ padding: '6px 24px 6px 16px', display: 'none' }}
                >
                  <MD.Grid item>
                    <div>
                      <span style={{ color: theme.palette.text.secondary }}>
                        {i18n._('Title')} :{' '}
                      </span>{' '}
                      {item.ads.metadatas[0].title} <br />
                    </div>
                  </MD.Grid>
                </MD.Grid>
              </MD.Box>
            </TableCell>
          </MD.TableRow>
        </React.Fragment>
      );
    }

    if (shouldScrollTo && this.htmlSelectedRef !== null) {
      if (this.htmlSelectedRef.current !== null) {
        /*this.htmlSelectedRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });*/
      }
    }

    return resultRowComponent;
  };

  private handleOpenMetadataDetails = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    trackId: number
  ) => {
    event.stopPropagation();
    let selectedCopy = [...this.state.selected];
    let iconEl = document.querySelector(`#collapse-icon-${trackId}`);
    let toggleEl = document.querySelector(
      `#collapse-${trackId} div`
    ) as HTMLElement;
    if (iconEl !== null) {
      iconEl.classList.toggle('icon-rotate');
    }
    if (toggleEl !== null) {
      if (toggleEl.style.display === 'none') {
        toggleEl.style.display = 'flex';
        selectedCopy.push(trackId);
      } else {
        toggleEl.style.display = 'none';
        selectedCopy = selectedCopy.filter((x) => x !== trackId);
      }
    }
    this.setState({ selected: selectedCopy });
  };

  private onChangePageResultsTable = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({ currentPage: page });
    this.props.paginateSearch(page + 1, this.state.perPage);
  };

  private onChangeRowsPerPageResultsTable = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value);
    this.setState({ perPage: perPage, currentPage: 0 });
    this.props.paginateSearch(1, perPage);
  };

  private drawResultsTable = () => {
    const { search, i18n, theme } = this.props;
    const { searchResults } = search;
    if (isNullOrUndefined(searchResults)) {
      return <>an error occurred</>;
    }
    const { results, total_count, total_pages, total_spots } = searchResults;

    return (
      <MD.Grid item xs={12}>
        <MD.Paper>
          {/* 100vh - appbar - espace sous app bar - pagination - espace sous table */}
          <MD.TableContainer
            style={{ maxHeight: 'calc(100vh - 50px - 8px - 52px - 8px)' }}
          >
            <MD.Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="small"
              aria-label="search table"
            >
              {this.drawResultsTableHeaders()}
              <MD.TableBody>
                {results.map((item: Result, index: number) => {
                  return this.drawResultsTableRow(item, index);
                })}
              </MD.TableBody>
            </MD.Table>
          </MD.TableContainer>
          <MD.TablePagination
            component="div"
            labelRowsPerPage={
              <MD.Typography variant="caption" component="div" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.text.primary,
                flexDirection: 'row',
                flexFlow: 'row nowrap',
                flexShrink: 0,
              }}>
                {i18n._('Rows per page')}
              </MD.Typography>
            }
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            count={total_count}
            rowsPerPage={search.params.params.page_size}
            page={this.state.currentPage}
            onPageChange={this.onChangePageResultsTable}
            onRowsPerPageChange={this.onChangeRowsPerPageResultsTable}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            labelDisplayedRows={({ from, to, count }) => (
              <MD.Typography
                variant="caption"
                component="div"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: theme.palette.text.primary,
                  flexDirection: 'row',
                  flexFlow: 'row nowrap',
                  flexShrink: 0,
                }}
              >
                {`${i18n._('Showing')} ${from} ${i18n._('to')} ${to} ${i18n._(
                  'of'
                )} ${count} ${i18n._('entries')}`}
              </MD.Typography>
            )}
            ActionsComponent={(subProps: any) => (
              <TablePaginationActions
                {...subProps}
                theme={theme}
                i18n={i18n}
                totalPages={total_pages}
              />
            )}
          />
        </MD.Paper>
      </MD.Grid>
    );
  };

  private fetchFormulaire = (
    fingerprintBucketList: IFingerprintBucketPair[]
  ) => {
    //this.props.fetchFormulaire(fingerprintBucketList);
  };

  private drawResultsTableWrapper = () => {
    if (this.props.search.isLoading) {
      return (
        <MD.Grid container justifyContent="center" alignItems="center">
          <MD.CircularProgress />
        </MD.Grid>
      );
    }
    return this.drawResultsTable();
  };

  private canDrawThumbnail() {
    return (
      this.props.search.params.media.includes(EMediaTypes.TV) &&
      this.props.search.params.country === COUNTRY_OPTIONS.FRANCE
    );
  }
  public render(): React.ReactNode {
    return (
      <>
        {this.state.playerMasterBucketName !== '' &&
          this.state.playerMasterFilePath !== '' && (
            <MD.Dialog
              fullWidth
              open={this.state.isPlayerMasterOpen}
              onClose={() =>
                this.setState({
                  isPlayerMasterOpen: false,
                  playerMasterBucketName: '',
                  playerMasterFilePath: '',
                })
              }
            >
              <MD.DialogTitle>
                {this.props.i18n._('Playing master file')}
              </MD.DialogTitle>
              <MD.DialogContent>
                <PlayerMaster
                  autoplay={false}
                  isVideo={false}
                  bucketName={this.state.playerMasterBucketName}
                  masterFilePath={this.state.playerMasterFilePath}
                />
              </MD.DialogContent>
            </MD.Dialog>
          )}
        {this.drawResultsTableWrapper()}
      </>
    );
  }
}

const mapStateToProps = ({
  i18n,
  search,
  spotDetails,
  user,
}: IApplicationState) => ({
  i18n: i18n.i18n,
  search,
  spotDetails,
  user,
  localeInfos: i18n.localeInfos,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFormulaire: (fingerprint_id: IFingerprintBucketPair[]) =>
    dispatch(fetchFormulaire(fingerprint_id)),
  launchSearch: (searchParams: ISearchParams) =>
    dispatch(launchSearch(searchParams)),
  paginateSearch: (page: number, limit: number) =>
    dispatch(paginateSearch(page, limit)),
  setSpotDetails: (
    details: ISpotRegroupement,
    startDate: string,
    endDate: string
  ) => dispatch(setSpotDetails(details, startDate, endDate)),
  getMediaWeb: () => dispatch(getMediaWeb()),
  selectMusic: (id: string | null) => dispatch(selectMusic(id)),
});

export const DashboardContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(DashboardContentComponent)));
