import * as MD from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Hls, { ErrorData } from 'hls.js';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../Store';
import { I18N } from '../Store/I18n/Types';
import { ILayoutState } from '../Store/Layout/Types';
import { IUserState } from '../Store/User/Types';
import { getTheme } from '../Themes';
import { Logger } from '../Utils/Logger';

const theme = getTheme();

const styles = MD.createStyles({
    tracker: {
        position: 'relative',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
    },
    nextPrev: {
        fontSize: 'larger',
    },
    modalContainer: {
        padding: '0 !important',
        overflow: 'auto',
        width: '80%',
    },
    input: {
        margin: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    playIcon: {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
    },
});

interface IState {
    currentHls: Hls;
    currentXhr: XMLHttpRequest | null;
    hlsErrorData: ErrorData | null;
    hlsUp: boolean;
    img: HTMLImageElement | null;
    imgError: boolean;
    imgErrorOpen: boolean;
    now: Date;
    playerAudio: HTMLAudioElement | null;
    playerVideo: HTMLVideoElement | null;
}
interface IPropsFromState {
    i18n: I18N;
    user: IUserState;
    layout: ILayoutState;
}

interface IProps {
    masterFilePath: string;
    bucketName: string;
    isVideo: boolean;
    autoplay: boolean;
}

interface IPropsFromDispatch { }

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & IPropsFromDispatch & IProps;

export class PlayerComponent extends React.PureComponent<AllProps, IState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = {
            currentHls: new Hls(),
            currentXhr: null,
            hlsErrorData: null,
            hlsUp: false,
            img: null,
            imgError: false,
            imgErrorOpen: true,
            now: new Date(),
            playerAudio: null,
            playerVideo: null,
        };
    }

    public componentDidUpdate(): void {
        const video = this.props.isVideo ? this.state.playerVideo : this.state.playerAudio;
        if (!this.state.hlsUp) {
            if (video !== null) {
                const hls = new Hls({
                    manifestLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: Infinity,
                            maxLoadTimeMs: 20000,
                            timeoutRetry: {
                                maxNumRetry: 0,
                                retryDelayMs: 0,
                                maxRetryDelayMs: 0,
                            },
                            errorRetry: {
                                maxNumRetry: 1,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 8000,
                            },
                        },
                    },
                    playlistLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: 10000,
                            maxLoadTimeMs: 20000,
                            timeoutRetry: {
                                maxNumRetry: 0,
                                retryDelayMs: 0,
                                maxRetryDelayMs: 0,
                            },
                            errorRetry: {
                                maxNumRetry: 0,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 8000,
                            },
                        },
                    },
                    fragLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: 10000,
                            maxLoadTimeMs: 120000,
                            timeoutRetry: {
                                maxNumRetry: 4,
                                retryDelayMs: 0,
                                maxRetryDelayMs: 0,
                            },
                            errorRetry: {
                                maxNumRetry: 6,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 8000,
                            },
                        },
                    },
                    keyLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: 8000,
                            maxLoadTimeMs: 20000,
                            timeoutRetry: {
                                maxNumRetry: 1,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 20000,
                                backoff: 'linear',
                            },
                            errorRetry: {
                                maxNumRetry: 8,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 20000,
                                backoff: 'linear',
                            },
                        },
                    },
                    certLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: 8000,
                            maxLoadTimeMs: 20000,
                            timeoutRetry: null,
                            errorRetry: null,
                        },
                    },
                    steeringManifestLoadPolicy: {
                        default: {
                            maxTimeToFirstByteMs: 10000,
                            maxLoadTimeMs: 20000,
                            timeoutRetry: {
                                maxNumRetry: 0,
                                retryDelayMs: 0,
                                maxRetryDelayMs: 0,
                            },
                            errorRetry: {
                                maxNumRetry: 1,
                                retryDelayMs: 1000,
                                maxRetryDelayMs: 8000,
                            },
                        },
                    },
                    xhrSetup: (xhr: XMLHttpRequest) => {
                        xhr.setRequestHeader('authorization', `Bearer ${this.props.user.token}`);
                        xhr.setRequestHeader('x-yacast-streamer-client', 'secure-player');
                        this.setState({ currentXhr: xhr });
                    },
                });
                const url = `https://streamer.yacast.fr/v0/public/file?bucket=${this.props.bucketName}&file=${this.props.masterFilePath}`;
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.ERROR, (event, data) => {
                    Logger.warn(event, data);
                    this.setState({ hlsErrorData: data });
                    if (this.state.currentXhr !== null) this.state.currentXhr.abort();
                });

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    video.play();
                    this.setState({ hlsErrorData: null });
                });

                hls.config.debug = true;

                this.setState({ currentHls: hls, hlsUp: true });
            }
        }
    }

    public componentDidMount(): void { }

    public componentWillUnmount(): void {
        this.state.currentHls.destroy();
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
        return state;
    }

    public render() {
        return (
            <MD.Grid container spacing={1}>
                <MD.Grid item xs={12}>
                    {this.props.isVideo ? <video
                        ref={player => {
                            this.setState({ playerVideo: player });
                        }}
                        width="100%"
                        height="100%"
                        autoPlay={this.props.autoplay}
                        controls
                    /> : <audio
                        style={{ width: '100%', marginBottom: '-5px' }}
                        ref={player => {
                            this.setState({ playerAudio: player });
                        }}
                        autoPlay={this.props.autoplay}
                        controls
                    />}
                    {this.state.hlsErrorData !== null && this.state.hlsErrorData.fatal && (
                        <Alert
                            onClose={() => {
                                this.setState({ hlsErrorData: null });
                            }}
                            severity={'error'}
                        >
                            {this.props.i18n._('an error occurred')}
                        </Alert>
                    )}
                </MD.Grid>
            </MD.Grid>
        );
    }
}

const mapChunksStateToProps = ({ user, i18n, layout }: IApplicationState) => ({
    i18n: i18n.i18n,
    user,
    layout,
});

export const PlayerMaster = connect(mapChunksStateToProps)(MD.withTheme(MD.withStyles(styles)(PlayerComponent)));
