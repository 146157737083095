import * as MD from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { withTheme } from '@material-ui/styles'
import Draggable from 'react-draggable'
import { YacastPlayer } from '@yacast/react-player'
import '@yacast/react-player/dist/index.css'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton';

// Material components
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import Hls from 'hls.js'

import MuiTablePagination from '@material-ui/core/TablePagination'
import {
  ButtonDensed,
  ButtonDensedNoColor,
  CustomTooltip,
  StyledLink,
} from '../../Themes/StyledElements'

//Store
import { IApplicationState } from '../../Store'
import { I18N, ILocaleInfos } from '../../Store/I18n/Types'
import { AdtrackTheme, getTheme } from '../../Themes'

//Icons
import InfoIcon from '@material-ui/icons/Info'
import AddIcon from '@material-ui/icons/Add'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import EditIcon from '@material-ui/icons/Edit'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import LastPageIcon from '@material-ui/icons/LastPage'
import RadioIcon from '@material-ui/icons/Radio'
import LanguageIcon from '@material-ui/icons/Language'
import GetAppIcon from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import VideocamIcon from '@material-ui/icons/Videocam'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'

import Moment from 'moment'

// Material components
import { IconButton } from '@material-ui/core'
import MuiTableCell from '@material-ui/core/TableCell'

// Date Pickers
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { enGB, fr, de } from 'date-fns/locale'
import {
  ISpotDetailsState,
  IBroadcastPassage,
  IFingerprintBucketPair,
  IReportSpotDetails,
  IBroadcastPassageWeb,
  IIdenticSpotNielsen,
  SPOT_STATUS_GERMANY,
  GROUP_STATUS_FRANCE,
  IPaginationParams,
  IMediasList,
} from '../../Store/SpotDetails/Types'
import {
  BucketMedia,
  COUNTRY_OPTIONS,
  IMediaSupport,
  IMusicBroadcasts,
  ISearchState,
} from '../../Store/Search/Types'
import {
  getSpotDetails,
  getBroadcastsPassages,
  downloadCSV,
  reportSpotProblem,
  getBroadcastWebPassages,
  getBroadcastsPassagesByFgpID,
  getSpotFormulaires,
} from '../../Store/SpotDetails/Actions'
import {
  getFormulairePreview,
  fetchFormulaire,
} from '../../Store/Formulaire/Actions'
import {
  IFormulaireFranceState,
  IFormulaireGemaState,
} from '../../Store/Formulaire/Types'
import { replaceDiacritics } from '../../Utils/String'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'
import {
  hasPermissionExportPassages,
  hasPermissionMediaPassagesToRead,
} from '../../Utils/Rights'
import { IUserState } from '../../Store/User/Types'
import { AudioStreamPlayer } from '../AudioStreamPlayer'
import { RouteComponentProps } from 'react-router-dom'
import { PlayerTimeline } from '../PlayerTimelineComponent'
import { getMediaWeb } from '../../Store/Search/Actions'
import { adjustForTimezone, fromUTC } from '../../Utils/Time'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { ILayoutState } from '../../Store/Layout/Types'
import { TypedStorage } from '../../Utils/TypedStorage'
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from '../../Utils/Various'
import { PaperComponent } from '../HelpFunction'
import { PlayerMaster } from '../PlayerMaster'

const theme = getTheme()

const styles = MD.createStyles({
  containerDivText: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: '16px',
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '0px',
    color: theme.palette.text.primary,
  },
  contentTableRow: {
    borderBottom: '1px solid rgba(81, 81, 81, 1) !important',
    borderCollapse: 'unset',
    borderRadius: '5px',
    marginBottom: '1px',
  },
  datePicker: {
    display: 'flex',
    alignItems: 'flex-end',
    fontStyle: 'italic',
    fontSize: 'x-small',
    color: '#C4C4C4',
    fontFamily: 'sans-serif',
    marginBottom: '6px',
  },
  datePickerLabel: {
    fontSize: '13px',
    fontWeight: 300,
  },
  datePickerInput: {
    '& label + .MuiInput-formControl': {
      marginTop: '7px',
    },
  },
  datePickerInputStart: {
    borderBottom: 'outset',
    borderBottomWidth: '1px',
    marginRight: '10px',
  },
  datePickerInputLeft: {
    marginLeft: '10px',
    borderBottom: 'outset',
    borderBottomWidth: '1px',
  },
  detailsIcon: {
    transform: 'rotate(0.5turn) rotateX(45deg)',
    display: 'block',
    margin: '0 auto',
  },
  openLinkIcon: {
    display: 'block',
    margin: '0 auto',
  },
  iconButton: {
    padding: 10,
  },
  iconStyle: {
    fontSize: '25px',
  },
  inputSearch: {
    flex: 1,
    '&:before': {
      border: 'none',
    },
  },
  inputLabelPropsStyle: {
    top: '-10px',
    fontSize: '12px',
    fontWeight: 300,
  },
  inputPropsStyle: {
    height: '26px',
    padding: '0 31px',
    fontSize: '13px',
    fontWeight: 300,
  },
  inputStyles: {
    fontSize: '12px',
    fontWeight: 300,
  },
  paperOverflow: {
    maxHeight: '81vh',
    overflow: 'auto',
  },
  reduceSvgIconSize: {
    '& svg': {
      transform: 'scale(0.7)',
    },
  },
  rootSearch: {
    padding: '2px 0',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& .MuiInput-root:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
  },
  statusValidated: {
    float: 'right',
    color: theme.palette.primary.main,
  },
  statusDraft: {
    float: 'right',
    color: theme.palette.secondary.main,
  },
  tableContainer: {
    padding: '10px',
    overflow: 'hidden',
    paddingTop: 0,
  },
  textStyle: {
    color: '#C4C4C4',
    fontSize: '13px',
    fontWeight: 200,
  },
  videoPlayer: {
    maxWidth: '100%',
    marginTop: '20px',
    maxHeight: '360px',
    margin: '0 auto',
    display: 'block',
    objectFit: 'cover',
  },
  audioPlayer: {
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
  },
  cellS: {
    fontSize: '12px',
    padding: '0px',
    display: 'flex',
    width: '12%',
    alignItems: 'center',
    fontWeight: 400,
    fontFamily: 'Lato',
    fontStyle: 'normal',
  },
  buttonDensed: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  scrollSpotList: {
    overflow: 'auto',
    height: '415px',
    width: '95%',
  },
})
interface IPropsFromState {
  i18n: I18N
  localeInfos: ILocaleInfos
  search: ISearchState
  spotDetails: ISpotDetailsState
  user: IUserState
  router: RouteComponentProps<{}>
  layout: ILayoutState
}

interface IPropsTheme {
  theme: any
}
const order_params: IPaginationParams = {
  page_size: 100,
  page: 1,
  order_by: [
    {
      field: 'start_date',
      value: 'desc',
    },
  ],
}
interface IPropsFromDispatch {
  downloadCSV: typeof downloadCSV
  fetchFormulaire: typeof fetchFormulaire
  getBroadcastsPassages: typeof getBroadcastsPassages
  getBroadcastsPassagesByFgpID: typeof getBroadcastsPassagesByFgpID
  getBroadcastWebPassages: typeof getBroadcastWebPassages
  getFormulairePreview: typeof getFormulairePreview
  getMediaWeb: typeof getMediaWeb
  getSpotDetails: typeof getSpotDetails
  getSpotFormulaires: typeof getSpotFormulaires
  reportSpotProblem: typeof reportSpotProblem
}

interface DetailsComponentProps {
  regroupingId: string
  fgpId: number
  bucketId: number
}

type AllProps = WithStyles<typeof styles> &
  IPropsFromState &
  DetailsComponentProps &
  IPropsTheme &
  IPropsFromDispatch &
  RouteComponentProps<{}>
// IConnectedReduxProps;

interface IMediaListItem {
  id: number
  label: string
}

interface IState {
  regions: any
  selectedIndex: number
  selectedMaster: any
  selectedId: number
  idPlaying: number
  mbAudioInfos: any[]
  currentTime: any
  duration: any
  progress: any
  playbackStatus: any
  isSeeking: boolean
  isLoading: boolean
  currentPage: number
  endDate: Date | null
  mediaList: any[]
  passages: any[]
  perPage: number
  searchInput: string | null
  selectedMediaFilter: number
  selectedSupportTypeFilter: number
  startDate: Date | null
  openDialogDownloadCSV: boolean
  totalCount: number
  totalPages: number
  openReportDialog: boolean
  senderEmail: string
  emailError: string
  reportComment: string
  broadcastPlaying: any
  openSpotId: number
  spotPlayingPath: string
  videoDialogOpened: boolean
  openYacastPlayer: boolean
  mediaIDtoPlay: number
  currentRowPlaying: number
  mediaNametoPlay: string
  startDatetoPlay: number
  endDatetoPlay: number
  isRegionPlaying: boolean
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

export interface DialogTitleProps extends MD.WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
  i18n: I18N
  layout: any
  theme: any
}
const DialogTitle = MD.withTheme(
  MD.withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, i18n, layout, theme, onClose, ...other } = props
    return (
      <MuiDialogTitle
        disableTypography
        {...other}
        style={{
          cursor: 'move',
          textAlign: 'center',
          color: theme.palette.primary.main,
          padding: theme.spacing(2),
        }}
      >
        <MD.Typography>{children}</MD.Typography>
        {onClose ? (
          <CustomTooltip title={i18n._('Close')}>
            <MD.IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseRoundedIcon />
            </MD.IconButton>
          </CustomTooltip>
        ) : null}
      </MuiDialogTitle>
    )
  })
)

const TablePagination = withStyles({
  toolbar: {
    padding: 0,
  },
  caption: {
    fontSize: '12px',
    fontWeight: 300,
  },
  select: {
    fontSize: '12px',
    fontWeight: 300,
  },
})(MuiTablePagination)

const TableCellDensed = withStyles((theme) => ({
  root: {
    padding: '0',
    fontSize: '13px',
    fontWeight: 300,
    border: 'none',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}))(MuiTableCell)

const TableHeaderCellDensed = withStyles((theme) => ({
  root: {
    padding: '0',
    fontSize: '13px',
    fontWeight: 300,
    border: 'none',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
}))(MuiTableCell)

const PagintaionIconButton = styled(IconButton)`
   {
    padding: 3px;
  }
`

export class DetailsContentComponent extends React.Component<AllProps, IState> {
  private videoNode: React.RefObject<HTMLVideoElement>
  private videoHls: any
  private keyboardHandler: (ev: KeyboardEvent) => void
  private player: any

  public constructor(props: AllProps) {
    super(props)
    this.state = {
      regions: {},
      selectedIndex: -1,
      selectedId: -1,
      idPlaying: -1,
      selectedMaster: null,
      mbAudioInfos: [],
      currentTime: 0,
      openDialogDownloadCSV: false,
      duration: 0,
      progress: 0,
      playbackStatus: 'pause',
      isSeeking: false,
      isLoading: false,
      currentPage: 0,
      endDate: null,
      mediaList:
        this.props.spotDetails.broadcastPassages !== undefined
          ? DetailsContentComponent.extractAllMediasFromBroadcastPassages(
            this.props.spotDetails.broadcastPassages
          )
          : [],
      passages: this.props.spotDetails.broadcastPassages,
      perPage: 100,
      selectedMediaFilter: -1,
      selectedSupportTypeFilter: -1,
      searchInput: null,
      startDate: null,
      totalCount: this.props.spotDetails.broadcastPassagesCount,
      totalPages: 0,
      openReportDialog: false,
      senderEmail: '',
      emailError: '',
      reportComment: '',
      broadcastPlaying: null,
      spotPlayingPath: '',
      openSpotId: -1,
      videoDialogOpened: false,
      openYacastPlayer: false,
      mediaIDtoPlay: -1,
      currentRowPlaying: -1,
      mediaNametoPlay: '',
      startDatetoPlay: -1,
      endDatetoPlay: -1,
      isRegionPlaying: false,
    }
    this.videoNode = React.createRef()
    this.keyboardHandler = this.handleKeyboardEvent.bind(this)
  }

  public componentDidMount() {
    if (this.props.bucketId && this.props.fgpId) {
      this.props.getSpotDetails(this.props.bucketId, this.props.fgpId)
      this.props.getSpotFormulaires([{
        fgp_id: this.props.fgpId,
        bucket_id: this.props.bucketId
      }])


      if (!isNullOrUndefined(this.props.spotDetails.fingerprintBucketList) && this.props.spotDetails.fingerprintBucketList.length > 0 && !isNull(this.state.startDate) && !isNull(this.state.endDate)) {
        this.props.getBroadcastsPassagesByFgpID(this.props.spotDetails.fingerprintBucketList[0].fgp_id, this.props.spotDetails.fingerprintBucketList[0].bucket_id, Moment(this.state.startDate), Moment(this.state.endDate),
          order_params,
          undefined)
      }
    }
    if (this.props.search.supportType.length === 0) {
      this.props.getMediaWeb()
    }
    document.addEventListener('keydown', this.keyboardHandler, true)
  }

  public componentDidUpdate(prevProps: AllProps, prevState: IState) {
    if (this.player && !this.player.paused && this.state.isRegionPlaying) {
      this.player.pause()
    } else if (
      this.player &&
      !this.player.paused &&
      !this.state.isRegionPlaying
    ) {
      const surfer = this.props.spotDetails.surfer
      surfer.pause()
    }

    if (prevProps.spotDetails.fingerprintBucketList !== this.props.spotDetails.fingerprintBucketList) {
      if (!isNullOrUndefined(this.props.spotDetails.fingerprintBucketList) && this.props.spotDetails.fingerprintBucketList.length > 0 && !isNull(this.state.startDate) && !isNull(this.state.endDate)) {
        this.props.getBroadcastsPassagesByFgpID(this.props.spotDetails.fingerprintBucketList[0].fgp_id, this.props.spotDetails.fingerprintBucketList[0].bucket_id, Moment(this.state.startDate), Moment(this.state.endDate),
          order_params,
          undefined)
      }
    }
  }

  private handleKeyboardEvent(event: KeyboardEvent) {
    const element: any = event.target

    if (this.state.openReportDialog && element != null) {
      const isTextInput =
        element.tagName === 'INPUT' || element.tagName === 'TEXTAREA'
      if (
        isTextInput &&
        (event.key === ' ' || event.key === 'v' || event.key === 'V')
      ) {
        event.stopPropagation()
      }
    } else {
      if (event.key === ' ') {
        event.stopPropagation()

        if (this.player && !this.player.paused && this.state.isRegionPlaying) {
          this.player.pause()
        } else if (
          this.player &&
          this.player.paused &&
          this.state.isRegionPlaying
        ) {
          this.player.play()
        }
        this.playPauseRegion(event)
      }
    }
  }

  static getDerivedStateFromProps(props: AllProps, state: IState) {
    let passages =
      props.bucketId === BucketMedia.DE_WEB[0]
        ? props.spotDetails.broadcastPassagesWeb
        : props.spotDetails.broadcastPassages
    //state.mediaList = props.bucketId === BucketMedia.DE_WEB[0] ? DetailsContentComponent.extractAllMediasFromBroadcastWebPassages(props.spotDetails.broadcastPassagesWeb) : DetailsContentComponent.extractAllMediasFromBroadcastPassages(props.spotDetails.broadcastPassages);
    state.passages = passages

    if (
      props.spotDetails.broadcastPassagesWeb !== undefined &&
      props.bucketId === BucketMedia.DE_WEB[0]
    ) {
      state.mediaList =
        DetailsContentComponent.extractAllMediasFromBroadcastWebPassages(
          props.spotDetails.broadcastPassagesWeb
        )
    }
    if (props.spotDetails.medias !== undefined) {
      state.mediaList = props.spotDetails.medias
    }
    const selectedMediaFilter = state.selectedMediaFilter
    let searchInput: any = state.searchInput

    if (selectedMediaFilter > 0) {
      if (props.bucketId === BucketMedia.DE_WEB[0]) {
        passages = state.passages.filter(
          (bp: IBroadcastPassageWeb) => bp.media.id === selectedMediaFilter
        )
      } else {
        passages = state.passages.filter(
          (bp: IBroadcastPassage) => bp.media.id === selectedMediaFilter
        )
      }
    }

    if (searchInput !== null && searchInput !== '') {
      searchInput = searchInput.toLowerCase()
      if (props.bucketId === BucketMedia.DE_WEB[0]) {
        passages = state.passages.filter((bp: IBroadcastPassageWeb) =>
          bp.media.label.toLowerCase().startsWith(searchInput)
        )
      } else {
        passages = state.passages.filter((bp: IBroadcastPassage) =>
          bp.media.name.toLowerCase().startsWith(searchInput)
        )
      }
    }
    if (props.bucketId === BucketMedia.DE_WEB[0]) {
      state.totalCount = props.spotDetails.broadcastPassagesWebCount
    } else {
      state.totalCount = props.spotDetails.broadcastPassagesCount
    }
    const search = props.search
    if (state.startDate === null) {
      if (typeof search.params.start_date === 'string') {
        state.startDate = new Date(search.params.start_date)
      } else {
        state.startDate = search.params.start_date.toDate()
      }
    }
    if (state.endDate === null) {
      if (typeof search.params.end_date === 'string') {
        state.endDate = fromUTC(new Date(search.params.end_date))
      } else {
        state.endDate = fromUTC(search.params.end_date.toDate())
      }
    }
    const details = props.spotDetails.metadatas
    let music_broadcast: any[] = []

    if (
      details.metadatas &&
      details.metadatas.music_broadcast &&
      details.metadatas.music_broadcast.length > 0 &&
      state.mbAudioInfos.length === 0
    ) {
      music_broadcast = details.metadatas.music_broadcast.map((mb) => {
        if (mb.master_filepath && mb.bucket_name) {
          return {
            id: mb.id,
            currentTime: 0,
            duration: 0,
            progress: 0,
            isPlaying: false,
          }
        } else return
      })
      state.mbAudioInfos = music_broadcast.filter((x) => x !== undefined)
    }

    const regions = { ...props.spotDetails.regions }
    if (
      Object.keys(regions).length > 0 &&
      Object.keys(state.regions).length === 0
    ) {
      Object.keys(regions).map((x) => {
        regions[x] = {
          ...regions[x],
          isPlaying: false,
        }
        return regions
      })
    }
    return state
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardHandler, true)
  }

  private drawLoader = () => {
    return (
      <MD.TableRow>
        <MD.TableCell colSpan={3}>
          <MD.Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <MD.CircularProgress color="secondary" />
          </MD.Grid>
        </MD.TableCell>
      </MD.TableRow>
    )
  }

  static extractAllMediasFromBroadcastPassages = (
    broadcastPassages: IBroadcastPassage[]
  ) => {
    let mediaList: any = {}
    let result: any = []

    if (broadcastPassages && broadcastPassages.length === 0) {
      return []
    }
    for (let i = 0; i < broadcastPassages.length; i += 1) {
      if (
        broadcastPassages[i].media &&
        broadcastPassages[i].media.name in mediaList
      ) {
        mediaList[broadcastPassages[i].media.name].counter += 1
      } else if (broadcastPassages[i].media) {
        mediaList[broadcastPassages[i].media.name] = {
          id: broadcastPassages[i].media.id,
          counter: 1,
        }
      }
    }

    Object.keys(mediaList).forEach(function (media_name) {
      result.push({
        id: mediaList[media_name].id,
        label: media_name + '(' + mediaList[media_name].counter + ')',
      } as IMediaListItem)
    })

    return result as IMediaListItem[]
  }

  static extractAllMediasFromBroadcastWebPassages = (
    broadcastPassages: IBroadcastPassageWeb[]
  ) => {
    let mediaList: any = {}
    let result: any = []

    for (let i = 0; i < broadcastPassages.length; i += 1) {
      if (
        broadcastPassages[i].media &&
        broadcastPassages[i].media.label in mediaList
      ) {
        mediaList[broadcastPassages[i].media.label].counter += 1
      } else if (broadcastPassages[i].media) {
        mediaList[broadcastPassages[i].media.label] = {
          id: broadcastPassages[i].media.id,
          counter: 1,
        }
      }
    }

    Object.keys(mediaList).forEach(function (media_name) {
      result.push({
        id: mediaList[media_name].id,
        label: media_name + '(' + mediaList[media_name].counter + ')',
      } as IMediaListItem)
    })

    return result as IMediaListItem[]
  }

  private isVideoFilepath = (filepath: string) => {
    if (filepath.endsWith('mp4')) {
      return true
    }
    return false
  }

  private drawAnnoncerProductTitle = () => {
    const details: any = this.props.spotDetails.metadatas
    let product_label = '-'
    if (details.metadatas) {
      product_label = details.metadatas.product_label
    }
    let title = '-'
    if (details.metadatas) {
      title = details.metadatas.title
    }
    if (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE) {
      return (
        <MD.Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            borderBottom: '1px solid #EEE',
            paddingBottom: '1px',
            marginBottom: '3px',
          }}
        >
          <MD.Grid item style={{ paddingRight: '8px' }}>
            {details.media.type === 'Video' ? (
              <VideocamIcon style={{ fontSize: '24px', fontWeight: 200 }} />
            ) : null}
            {details.media.type === 'Radio' ? (
              <RadioIcon style={{ fontSize: '24px', fontWeight: 200 }} />
            ) : null}
          </MD.Grid>
          <MD.Grid item style={{ paddingRight: '25px' }}>
            <MD.Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'uppercase',
              }}
            >
              {product_label}
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item>
            <MD.Typography style={{ fontSize: '12px', fontWeight: 300 }}>
              {title}
            </MD.Typography>
          </MD.Grid>
        </MD.Grid>
      )
    } else if (
      this.props.search.params.country === COUNTRY_OPTIONS.GERMANY
    ) {
      let advertiser = '-'
      if (details.metadatas && details.metadatas.advertiser) {
        advertiser = details.metadatas.advertiser.name
      }

      return (
        <MD.Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            borderBottom: '1px solid #EEE',
            paddingBottom: '1px',
            marginBottom: '3px',
          }}
        >
          <MD.Grid item style={{ paddingRight: '8px' }}>
            {details.media.type === 'Video' ? (
              <VideocamIcon style={{ fontSize: '24px', fontWeight: 200 }} />
            ) : null}
            {details.media.type === 'Radio' ? (
              <RadioIcon style={{ fontSize: '24px', fontWeight: 200 }} />
            ) : null}
            {details.media.type === 'WEB' ? (
              <LanguageIcon style={{ fontSize: '24px', fontWeight: 200 }} />
            ) : null}
          </MD.Grid>
          <MD.Grid item style={{ paddingRight: '25px' }}>
            <MD.Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'uppercase',
              }}
            >
              {advertiser}
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item style={{ paddingRight: '25px' }}>
            <MD.Typography
              style={{
                fontSize: '16px',
                fontWeight: 400,
                textTransform: 'uppercase',
              }}
            >
              {product_label}
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item>
            <MD.Typography style={{ fontSize: '12px', fontWeight: 300 }}>
              {title}
            </MD.Typography>
          </MD.Grid>
        </MD.Grid>
      )
    }
  }

  private drawProductMetadata = () => {
    const { localeInfos } = this.props
    const details = this.props.spotDetails.metadatas
    let locale: string = 'fr'
    if (localeInfos.momentLocale === 'en-us') {
      locale = 'en-us'
    } else if (localeInfos.momentLocale === 'fr') {
      locale = 'fr'
    } else if (localeInfos.momentLocale === 'de') {
      locale = 'en-us'
    }
    let firstBroadcast: any = null
    if (details.metadatas) {
      firstBroadcast =
        this.props.search.params.country === COUNTRY_OPTIONS.FRANCE
          ? details.metadatas.first_broadcast
          : details.metadatas.start_date
      if (firstBroadcast !== null) {
        Moment.locale(locale)
        firstBroadcast = Moment(firstBroadcast).format('DD MMM YYYY HH:mm')
      }
    }

    let duration: string | number = '-'
    if (details.metadatas) {
      duration = details.metadatas.duration
    }

    let mediaName = '-'

    if (details.media) {
      mediaName = details.media.name
    }
    let advertiser = {
      name: '-',
    }
    if (details.metadatas) {
      advertiser = details.metadatas.advertiser
    }
    return (
      <MD.Table>
        <MD.TableHead>
          <MD.TableRow>
            <TableHeaderCellDensed>
              {this.props.i18n._('Duration')}
            </TableHeaderCellDensed>
            <TableHeaderCellDensed>
              {this.props.i18n._('1st broadcast')}
            </TableHeaderCellDensed>
            <TableHeaderCellDensed>
              {this.props.i18n._('Announcer')}
            </TableHeaderCellDensed>
            {this.props.search.params.country ===
              COUNTRY_OPTIONS.FRANCE ? (
              <TableHeaderCellDensed>
                {this.props.i18n._('Distributor')}
              </TableHeaderCellDensed>
            ) : null}
          </MD.TableRow>
        </MD.TableHead>
        <MD.TableBody>
          <MD.TableRow>
            <TableCellDensed>{duration}</TableCellDensed>
            <TableCellDensed>{firstBroadcast}</TableCellDensed>
            <TableCellDensed>{advertiser.name}</TableCellDensed>

            {this.props.search.params.country ===
              COUNTRY_OPTIONS.FRANCE && (
                <TableCellDensed>{mediaName}</TableCellDensed>
              )}
          </MD.TableRow>
        </MD.TableBody>
      </MD.Table>
    )
  }

  private displayDeclarationStatus = (
    item: IFormulaireFranceState | IFormulaireGemaState
  ) => {
    if (item.validation_state) {
      if (item.validation_state.code > 0) {
        return (
          <MD.Tooltip
            title={item.validation_state ? item.validation_state.message : ''}
          >
            <ErrorOutlineIcon color="error" />
          </MD.Tooltip>
        )
      }
    }
    if (item.is_validated) {
      return <DoneOutlineIcon color="primary" />
    } else {
      return <EditIcon color="primary" />
    }
  }

  private showVideo() {
    const details = this.props.spotDetails.metadatas
    return (
      <video
        id="myVideo"
        style={{
          display: 'none',
          objectFit: 'cover',
        }}
        ref={(node: any) => (this.videoNode = node)}
        playsInline
        className="video-js vjs-default-skin vjs-16-9"
        controls
        preload={details.thumbnail_path !== null ? 'none' : 'metadata'}
        poster={details.thumbnail_path || undefined}
      ></video>
    )
  }

  private exposeMedia = (player: HTMLMediaElement) => {
    this.player = player

    if (this.state.isRegionPlaying) {
      setTimeout(() => {
        if (!this.player.paused) {
          this.setState({
            isRegionPlaying: false,
          })
        }
      }, 1000)
    }
  }
  private drawContentPlayer = () => {
    const details = this.props.spotDetails.metadatas
    const { classes } = this.props

    let mediaName = details.media.name
    let mediaID = details.broadcast ? details.broadcast.media_id : -1
    let timelineDuration = details.duration + 360
    let tokenPlayer: string =
      this.props.user.tokenPlayer || TypedStorage.get('tokenPlayer', '')
    let token: string = tokenPlayer
    if (tokenPlayer === '') {
      token = this.props.user.token
    }

    let startDateJuste: any = details
      ? details.start_date
        ? details.start_date.valueOf()
        : null
      : null
    let endDate: any = details
      ? details.end_date
        ? details.end_date.valueOf()
        : null
      : null
    startDateJuste = new Date(startDateJuste)
    endDate = new Date(endDate)

    const startDate = new Date(startDateJuste.getTime() - 1000)

    if (isNullOrUndefined(details.filepath)) return <>An error occured</>;

    if (
      mediaID != -1 &&
      mediaID != undefined &&
      details.filepath &&
      details.filepath !== '' &&
      this.isVideoFilepath(details.filepath)
    ) {
      return (
        <YacastPlayer
          apiKey={token}
          mediaName={mediaName}
          mediaID={mediaID}
          start={startDate}
          autoplay={false}
          timelineDuration={timelineDuration}
          timelineHaveReadjustedEPG={true}
          timelineHaveMusicEPG={false}
          timelineHaveDeclarativeEPG={false}
          selectionEnd={new Date(endDate)}
          selectionStart={new Date(startDate)}
          initialZoom={0}
          aclToken={token}
          selectionZoom={true}
          exposeMedia={(player) => this.exposeMedia(player)}
        />
      )
    } else {
      const bucket_name = this.props.router.location.pathname.split('/')[2] === '1' ? 'arpp_tv_ad' : 'kantar_fr_radio'
      const filepath = bucket_name === 'arpp_tv_ad' ? details.filepath.replace('/mnt/nas04/arpp_extracts', '').replace('/mnt/nas11/arpp_extracts', '') : '/' + details.filepath

      return (
        <MD.Grid>
          <PlayerMaster
            autoplay={false}
            isVideo={false}
            masterFilePath={filepath}
            bucketName={bucket_name}
          />
        </MD.Grid>
      )
    }
  }

  private drawDescriptionFooter = () => {
    const details = this.props.spotDetails.metadatas
    let agencyName = '-'
    if (details.metadatas) {
      agencyName = details.metadatas.agency_name
    }
    return (
      <MD.Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <MD.Grid item style={{ paddingLeft: '10px' }}>
          {this.props.i18n._('Agency')} : {agencyName}
        </MD.Grid>
        <MD.Grid item>
          <MD.IconButton href={details.filepath} download>
            <GetAppIcon />
          </MD.IconButton>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawProductDetails = () => {
    return (
      <MD.Paper>
        <MD.Grid container direction="column">
          <MD.Grid item>{this.drawAnnoncerProductTitle()}</MD.Grid>
          <MD.Grid item>{this.drawProductMetadata()}</MD.Grid>
          <MD.Grid
            item
            style={{
              maxWidth: '100%',
              width: '100%',
              padding: '20px 20px 0px',
            }}
          >
            {this.drawContentPlayer()}
          </MD.Grid>
          <MD.Grid item>{this.drawDescriptionFooter()}</MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private selectMediaFilter = (event: any) => {
    const selectedMediaFilter = parseInt(event.target.value)
    this.setState({ selectedMediaFilter })
    const fingerprintBucketList = this.props.spotDetails.fingerprintBucketList
    if (this.props.bucketId === BucketMedia.DE_WEB[0]) {
      this.props.getBroadcastWebPassages(
        Moment(this.state.startDate),
        Moment(this.state.endDate),
        this.props.spotDetails.groupId,
        this.state.currentPage + 1,
        this.state.perPage
      )
    } else {
      if (fingerprintBucketList.length > 0) {
        let media_id: number[] | undefined =
          selectedMediaFilter !== -1 ? [selectedMediaFilter] : undefined
        this.props.getBroadcastsPassagesByFgpID(
          fingerprintBucketList[0].fgp_id,
          fingerprintBucketList[0].bucket_id,
          Moment(this.state.startDate),
          Moment(this.state.endDate),
          order_params,
          media_id
        )
      }
    }
  }

  private selectSupportTypeFilter = (event: any) => {
    const selectedSupportTypeFilter = event.target.value
    this.setState({
      selectedSupportTypeFilter: parseInt(selectedSupportTypeFilter),
    })
  }

  private drawMediaFilter = () => {
    const { classes } = this.props
    let mediaList = this.state.mediaList //this.props.spotDetails.medias;
    if (isNullOrUndefinedOrEmptyArray(mediaList)) {
      return null
    } else {
      mediaList = mediaList.sort((a, b) => a.name.localeCompare(b.name))
    }
    return (
      <MD.FormControl style={{ minWidth: '220px', marginBottom: '-14px' }}>
        <MD.InputLabel
          shrink
          htmlFor="age-native-label-placeholder"
          style={{ fontSize: '13px', fontWeight: 300 }}
        >
          {this.props.i18n._('Media')}
        </MD.InputLabel>
        <MD.NativeSelect
          onChange={this.selectMediaFilter}
          classes={{ select: classes.inputStyles }}
          inputProps={{
            name: 'age',
            id: 'age-native-label-placeholder',
          }}
        >
          <option value="-1">
            {this.props.i18n._('Tous les medias')} ({mediaList.length})
          </option>
          {mediaList.map((media: IMediasList) => (
            <option key={media.id} value={media.id}>
              {media.name + '(' + media.count + ')'}
            </option>
          ))}
        </MD.NativeSelect>
        <MD.FormHelperText style={{ fontSize: '10px', fontWeight: 300 }}>
          {this.props.i18n._('Filtrer par média')}
        </MD.FormHelperText>
      </MD.FormControl>
    )
  }

  private drawSupportTypeFilter = () => {
    const { classes } = this.props

    return (
      <MD.FormControl style={{ minWidth: '220px', marginBottom: '-14px' }}>
        <MD.NativeSelect
          onChange={this.selectSupportTypeFilter}
          classes={{ select: classes.inputStyles }}
          inputProps={{
            name: 'support',
            id: 'age-native-label-placeholder',
          }}
        >
          <option value="-1">{this.props.i18n._('Support Type')}</option>
          {this.props.search.supportType.map((media: IMediaSupport) => {
            return (
              <React.Fragment key={media.id}>
                {media.subitems.length > 0 &&
                  media.subitems.map((x) => {
                    return (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    )
                  })}
              </React.Fragment>
            )
          })}
        </MD.NativeSelect>
        <MD.FormHelperText style={{ fontSize: '10px', fontWeight: 300 }}>
          {this.props.i18n._('Filtrer par type')}
        </MD.FormHelperText>
      </MD.FormControl>
    )
  }

  private drawMediaBroadcastTitle = () => {
    const { broadcastPassages } = this.props.spotDetails
    if (broadcastPassages !== undefined) {
      return (
        <MD.Grid
          container
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <MD.Grid item style={{ alignSelf: 'self-start' }}>
            <MD.Typography style={{ fontSize: '12px', fontWeight: 300 }}>
              {this.props.i18n._('Media passages') +
                ' (' +
                broadcastPassages.length +
                ')'}
            </MD.Typography>
          </MD.Grid>
        </MD.Grid>
      )
    } else return null
  }

  private drawMediaBroadcastFilters() {
    return (
      <MD.Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '13px' }}
        direction="row"
      >
        <MD.Grid item> {this.drawDatePickers()}</MD.Grid>
        <MD.Grid item>{this.drawMediaFilter()}</MD.Grid>
        {this.props.bucketId === BucketMedia.DE_WEB[0] && (
          <MD.Grid item>{this.drawSupportTypeFilter()}</MD.Grid>
        )}
      </MD.Grid>
    )
  }

  private onStartDateChange = (date: any) => {
    this.setState({ startDate: date })
    const fingerprintBucketList = this.props.spotDetails.fingerprintBucketList
    if (this.props.bucketId === BucketMedia.DE_WEB[0]) {
      this.props.getBroadcastWebPassages(
        Moment(date),
        Moment(this.state.endDate),
        this.props.spotDetails.groupId,
        this.state.currentPage + 1,
        this.state.perPage
      )
    } else {
      if (fingerprintBucketList.length > 0) {
        let media_id: number[] | undefined =
          this.state.selectedMediaFilter !== -1
            ? [this.state.selectedMediaFilter]
            : undefined
        this.props.getBroadcastsPassagesByFgpID(
          fingerprintBucketList[0].fgp_id,
          fingerprintBucketList[0].bucket_id,
          Moment(date),
          Moment(this.state.endDate),
          order_params,
          media_id
        )
      }
    }
  }

  private onEndDateChange = (date: any) => {
    this.setState({ endDate: date })
    const fingerprintBucketList = this.props.spotDetails.fingerprintBucketList
    let media_id: number[] | undefined =
      this.state.selectedMediaFilter !== -1
        ? [this.state.selectedMediaFilter]
        : undefined
    if (this.props.bucketId === BucketMedia.DE_WEB[0]) {
      this.props.getBroadcastWebPassages(
        Moment(this.state.startDate),
        Moment(date),
        this.props.spotDetails.groupId,
        this.state.currentPage + 1,
        this.state.perPage
      )
    } else {
      this.props.getBroadcastsPassages(fingerprintBucketList, Moment(this.state.startDate), Moment(date));
      if (fingerprintBucketList.length > 0) {
        this.props.getBroadcastsPassagesByFgpID(
          fingerprintBucketList[0].fgp_id,
          fingerprintBucketList[0].bucket_id,
          Moment(this.state.startDate),
          Moment(date),
          order_params,
          media_id
        )
      }
    }
  }

  private drawDatePickers = () => {
    const { classes, localeInfos, search } = this.props
    let startDate = this.state.startDate
    let endDate = this.state.endDate
    if (this.state.startDate === null) {
      if (typeof search.params.start_date === 'string') {
        startDate = new Date(search.params.start_date)
      } else {
        startDate = search.params.start_date.toDate()
      }
    }
    if (this.state.endDate === null) {
      if (typeof search.params.end_date === 'string') {
        endDate = new Date(search.params.end_date)
      } else {
        endDate = search.params.end_date.toDate()
      }
    }
    let format: string = '',
      locale: Locale = fr
    if (localeInfos.momentLocale === 'en-us') {
      format = 'MM/dd/yyyy'
      locale = enGB
    } else if (localeInfos.momentLocale === 'fr') {
      format = 'dd/MM/yyyy'
      locale = fr
    } else if (localeInfos.momentLocale === 'de') {
      format = 'dd/MM/yyyy'
      locale = de
    }
    return (
      <MD.FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <MD.Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <MD.Grid item xs={6}>
              <DatePicker
                style={{
                  borderBottom: 'outset',
                  borderBottomWidth: '1px',
                  marginRight: '10px',
                }}
                autoOk={true}
                disableToolbar
                variant="inline"
                format={format}
                className={classes.datePickerInput}
                id="start-date-picker"
                label={this.props.i18n._('From')}
                value={startDate}
                onChange={this.onStartDateChange.bind(this)}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.datePickerLabel }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    //   this.handleSearch();
                  }
                }}
              />
            </MD.Grid>
            <MD.Grid item xs={6}>
              <MD.Grid item>
                <DatePicker
                  style={{
                    marginLeft: '10px',
                    borderBottom: 'outset',
                    borderBottomWidth: '1px',
                  }}
                  disableToolbar
                  variant="inline"
                  format={format}
                  className={classes.datePickerInput}
                  id="end_date_picker"
                  label={this.props.i18n._('To')}
                  value={endDate}
                  onChange={this.onEndDateChange.bind(this)}
                  //disableFuture={true}
                  autoOk={true}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ className: classes.datePickerLabel }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      //   this.handleSearch();
                    }
                  }}
                />
              </MD.Grid>
            </MD.Grid>
          </MD.Grid>
        </MuiPickersUtilsProvider>
      </MD.FormControl>
    )
  }

  private openDialogDownloadCSV() {
    const { i18n, spotDetails } = this.props
    return (
      <MD.Dialog
        disableBackdropClick
        aria-labelledby="draggable-title"
        onClose={this.handleCloseDownloadCSV.bind(this)}
        open={this.state.openDialogDownloadCSV}
        PaperComponent={PaperComponent}
      >
        <DialogTitle
          i18n={this.props.i18n}
          layout={this.props.layout}
          theme={this.props.theme}
          id="draggable-title"
          onClose={this.handleCloseDownloadCSV.bind(this)}
        >
          Export CSV
        </DialogTitle>
        <MD.DialogContent
          style={{ padding: theme.spacing(2), width: '220px' }}
          dividers
        >
          {spotDetails.file_downloaded === null ? (
            <MD.Typography
              gutterBottom
              className={this.props.classes.containerDivText}
            >
              {i18n._('Loading')}...
            </MD.Typography>
          ) : (
            <MD.Typography
              gutterBottom
              className={this.props.classes.containerDivText}
            >
              {i18n._('téléchargement terminé !')}
            </MD.Typography>
          )}
        </MD.DialogContent>
      </MD.Dialog>
    )
  }

  private handleClickDownloadCSV() {
    this.setState(
      {
        openDialogDownloadCSV: true,
      },
      () => {
        this.props.downloadCSV(
          Moment(this.state.startDate),
          Moment(this.state.endDate)
        )
      }
    )
  }
  private drawMediaBroadcastToolbar = () => {
    return (
      <MD.Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        style={{ marginTop: '-4px', marginBottom: '-6px' }}
      >
        <MD.Grid item>
          <ButtonDensed
            variant="outlined"
            onClick={() => this.handleClickDownloadCSV()}
          >
            <span style={{ color: theme.palette.text.secondary }}>CSV</span>
          </ButtonDensed>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private onQuickSearchFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchInput: event.target.value })
  }

  private drawSearchForm = () => {
    let { classes } = this.props
    return (
      <MD.Box className={classes.rootSearch}>
        <MD.TextField
          fullWidth
          className={classes.inputSearch}
          variant="outlined"
          label={this.props.i18n._('Quick search')}
          value={this.state.searchInput || undefined}
          inputProps={{ 'aria-label': 'search' }}
          onChange={this.onQuickSearchFormChange}
          InputProps={{ classes: { input: classes.inputPropsStyle } }}
          InputLabelProps={{ className: classes.inputLabelPropsStyle }}
        />
        <MD.IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </MD.IconButton>
      </MD.Box>
    )
  }

  public tablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0)
    }

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
      <div style={{ marginLeft: '0px', flexShrink: 0 }}>
        <PagintaionIconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </PagintaionIconButton>
        <PagintaionIconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </PagintaionIconButton>
        <PagintaionIconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </PagintaionIconButton>
        <PagintaionIconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </PagintaionIconButton>
      </div>
    )
  }

  private onChangePageResultsTable = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({ currentPage: page })
    const params: IPaginationParams = {
      page_size: this.state.perPage,
      page: page + 1,
      order_by: [
        {
          field: 'start_date',
          value: 'desc',
        },
      ],
    }
    const fingerprintBucketList = this.props.spotDetails.fingerprintBucketList
    let media_id: number[] | undefined =
      this.state.selectedMediaFilter !== -1
        ? [this.state.selectedMediaFilter]
        : undefined
    if (
      this.props.bucketId !== BucketMedia.DE_WEB[0] &&
      fingerprintBucketList.length > 0
    ) {
      this.props.getBroadcastsPassagesByFgpID(
        fingerprintBucketList[0].fgp_id,
        fingerprintBucketList[0].bucket_id,
        Moment(this.state.startDate),
        Moment(this.state.endDate),
        params,
        media_id
      )
    } else if (this.props.bucketId === BucketMedia.DE_WEB[0]) {
      this.props.getBroadcastWebPassages(
        Moment(this.state.startDate),
        Moment(this.state.endDate),
        this.props.spotDetails.groupId,
        page + 1,
        this.state.perPage
      )
    }
  }

  private onChangeRowsPerPageResultsTable = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value)
    this.setState({ perPage: perPage, currentPage: 0 })
    const params: IPaginationParams = {
      page_size: perPage,
      page: 1,
      order_by: [
        {
          field: 'start_date',
          value: 'desc',
        },
      ],
    }
    const fingerprintBucketList = this.props.spotDetails.fingerprintBucketList
    let media_id: number[] | undefined =
      this.state.selectedMediaFilter !== -1
        ? [this.state.selectedMediaFilter]
        : undefined
    if (
      this.props.bucketId !== BucketMedia.DE_WEB[0] &&
      fingerprintBucketList.length > 0
    ) {
      this.props.getBroadcastsPassagesByFgpID(
        fingerprintBucketList[0].fgp_id,
        fingerprintBucketList[0].bucket_id,
        Moment(this.state.startDate),
        Moment(this.state.endDate),
        params,
        media_id
      )
    } else if (this.props.bucketId === BucketMedia.DE_WEB[0]) {
      this.props.getBroadcastWebPassages(
        Moment(this.state.startDate),
        Moment(this.state.endDate),
        this.props.spotDetails.groupId,
        1,
        perPage
      )
    }
  }

  private drawTablePagination = () => {
    return (
      <table>
        <tbody>
          <tr>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
              labelRowsPerPage={this.props.i18n._('Rows per page')}
              count={this.state.totalCount ? this.state.totalCount : 0}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPage}
              onPageChange={this.onChangePageResultsTable}
              onRowsPerPageChange={this.onChangeRowsPerPageResultsTable}
              ActionsComponent={this.tablePaginationActions}
            />
          </tr>
        </tbody>
      </table>
    )
  }

  private drawMediaBroadcastPaginationFilterToolbar = () => {
    return (
      <MD.Grid container direction="row" justifyContent="space-between">
        <MD.Grid item> {this.drawSearchForm()}</MD.Grid>
        <MD.Grid item> {this.drawTablePagination()} </MD.Grid>
      </MD.Grid>
    )
  }

  private handleVideoPlayerClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    row: any
  ) => {
    event.stopPropagation()
    this.setState({
      openYacastPlayer: true,
      currentRowPlaying: row.id,
      mediaIDtoPlay: row.media.id,
      mediaNametoPlay: row.media.name,
      startDatetoPlay: adjustForTimezone(new Date(row.start_date)).getTime(),
      endDatetoPlay: adjustForTimezone(new Date(row.end_date)).getTime(),
    })
  }

  private drawPlayerButton(bp: any) {
    return (
      <CustomTooltip title={this.props.i18n._('Play Video')}>
        <PlayCircleOutlineIcon
          className={`play-ref-${bp.id}`}
          style={{
            cursor: 'pointer',
            fontSize: '1.2rem',
            marginLeft: '10px',
            color: theme.palette.secondary.main,
          }}
          onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
            this.handleVideoPlayerClick(event, bp)
          }
        />
      </CustomTooltip>
    )
  }
  private drawBroadcastPassageRow(bp: any, index: number) {
    const startDate = bp.start_date || bp.date
    const day = Moment(adjustForTimezone(new Date(startDate))).format(
      'DD MMM YYYY'
    )
    const hour = Moment(adjustForTimezone(new Date(startDate))).format('HH:mm')
    const bucket_id = this.props.bucketId
    let type = bucket_id === BucketMedia.DE_WEB[0] ? bp.media.media_type : ''
    const ad_imp = bucket_id === BucketMedia.DE_WEB[0] ? bp.ad_imp : ''
    type = type === 'WEB' ? 'ONLINE' : type
    return (
      <MD.TableRow key={bp.id || index}>
        <TableCellDensed>
          {bp.media
            ? bucket_id === BucketMedia.DE_WEB[0]
              ? bp.media.label
              : bp.media.name
            : ''}
        </TableCellDensed>
        {bucket_id === BucketMedia.DE_WEB[0] && (
          <TableCellDensed>{type}</TableCellDensed>
        )}
        <TableCellDensed>{day}</TableCellDensed>
        {bucket_id !== BucketMedia.DE_WEB[0] && (
          <TableCellDensed>{hour}</TableCellDensed>
        )}
        {bucket_id === BucketMedia.DE_WEB[0] && (
          <TableCellDensed>{ad_imp}</TableCellDensed>
        )}
        {this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
          bp.media &&
          bp.media.id ? (
          <TableCellDensed>{this.drawPlayerButton(bp)}</TableCellDensed>
        ) : null}
      </MD.TableRow>
    )
  }

  private filterBroadcastPassages = (bps: any[]) => {
    let searchInput: any = this.state.searchInput
    let bucket_id = this.props.bucketId

    let bpsFiltererdByMedia = bps
    if (this.state.selectedMediaFilter > 0) {
      if (bucket_id === BucketMedia.DE_WEB[0]) {
        bpsFiltererdByMedia = bps.filter(
          (bp: IBroadcastPassageWeb) =>
            bp.media_id === this.state.selectedMediaFilter
        )
      } else {
        bpsFiltererdByMedia = bps.filter(
          (bp: IBroadcastPassage) =>
            bp.media.id === this.state.selectedMediaFilter
        )
      }
    }
    if (
      this.state.selectedSupportTypeFilter > 0 &&
      bucket_id === BucketMedia.DE_WEB[0]
    ) {
      bpsFiltererdByMedia = bps.filter(
        (bp: IBroadcastPassageWeb) =>
          bp.media.id === this.state.selectedSupportTypeFilter
      )
    }
    if (searchInput !== null && searchInput !== '') {
      searchInput = searchInput.toLowerCase()
      if (bucket_id === BucketMedia.DE_WEB[0]) {
        bpsFiltererdByMedia = bpsFiltererdByMedia.filter(
          (bp: IBroadcastPassageWeb) => {
            let r = replaceDiacritics(bp.media.label.toLowerCase())
            let s = replaceDiacritics(searchInput)
            s = s.replace(/^\s+|\s+$/gm, '')
            return r.startsWith(s)
          }
        )
      } else {
        bpsFiltererdByMedia = bpsFiltererdByMedia.filter(
          (bp: IBroadcastPassage) => {
            return replaceDiacritics(bp.media.name.toLowerCase()).startsWith(
              replaceDiacritics(searchInput)
            )
          }
        )
      }
    }
    return bpsFiltererdByMedia //.slice(page * perPage, (page + 1) * perPage);
  }

  private drawMediaBroadcastTable = () => {
    const { bucketId } = this.props
    const { broadcastPassages, broadcastPassagesWeb } = this.props.spotDetails

    let broadcastPassagesFilterd =
      bucketId === BucketMedia.DE_WEB[0]
        ? this.filterBroadcastPassages(broadcastPassagesWeb)
        : this.filterBroadcastPassages(broadcastPassages)

    return (
      <MD.Table>
        <MD.TableHead>
          <MD.TableRow>
            <TableHeaderCellDensed>
              {this.props.i18n._('Media')}
            </TableHeaderCellDensed>
            {bucketId === BucketMedia.DE_WEB[0] && (
              <TableHeaderCellDensed>
                {this.props.i18n._('Type')}
              </TableHeaderCellDensed>
            )}
            <TableHeaderCellDensed style={{ textTransform: 'capitalize' }}>
              {this.props.i18n._('date')}
            </TableHeaderCellDensed>
            {bucketId !== BucketMedia.DE_WEB[0] && (
              <TableHeaderCellDensed style={{ textTransform: 'capitalize' }}>
                {this.props.i18n._('hour')}{' '}
              </TableHeaderCellDensed>
            )}
            {bucketId === BucketMedia.DE_WEB[0] && (
              <TableHeaderCellDensed>
                {this.props.i18n._('Ad Imp')}
              </TableHeaderCellDensed>
            )}
            {this.props.search.params.country ===
              COUNTRY_OPTIONS.FRANCE && (
                <TableHeaderCellDensed>
                  {this.props.i18n._('Player')}
                </TableHeaderCellDensed>
              )}
          </MD.TableRow>
        </MD.TableHead>
        <MD.TableBody>
          {this.props.spotDetails.isLoadingBroadcastPassages &&
            this.drawLoader()}
          {broadcastPassagesFilterd !== undefined &&
            broadcastPassagesFilterd.map((bp: any, index: number) =>
              this.drawBroadcastPassageRow(bp, index)
            )}
        </MD.TableBody>
      </MD.Table>
    )
  }

  private drawBroadcastsDetails = () => {
    return (
      <MD.Paper
        style={{ marginLeft: '20px', marginTop: '10px', padding: '8px' }}
      >
        <MD.Grid container direction="column">
          <MD.Grid item> {this.drawMediaBroadcastTitle()} </MD.Grid>
          <MD.Grid item> {this.drawMediaBroadcastFilters()} </MD.Grid>
          <MD.Grid item>
            {' '}
            {hasPermissionExportPassages(this.props.user) &&
              this.drawMediaBroadcastToolbar()}{' '}
          </MD.Grid>
          <MD.Grid item>
            {' '}
            {this.drawMediaBroadcastPaginationFilterToolbar()}{' '}
          </MD.Grid>
          <MD.Grid item> {this.drawMediaBroadcastTable()} </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private playRegion(ev: any, mbID: any) {
    ev.stopPropagation()
    ev.preventDefault()
    this.setState({ broadcastPlaying: mbID })
    const regions = this.props.spotDetails.regions
    let region = regions[mbID]
    if (region && !region.isPlaying) {
      region.isPlaying = true
      this.setState({
        isRegionPlaying: true,
      })
      region.play()
      Object.keys(regions).map((x) => {
        if (x !== mbID) {
          regions[x].isPlaying = false
        }
        return regions
      })
    } else if (regions[mbID] && regions[mbID].isPlaying) {
      regions[mbID].isPlaying = false
      this.setState({
        isRegionPlaying: false,
      })
    }
    return
  }
  private pauseRegion(mbId: any) {
    const regions = this.props.spotDetails.regions
    const region: any = regions[mbId]
    const surfer = this.props.spotDetails.surfer
    if (surfer && region && region.isPlaying) {
      region.isPlaying = false
      this.setState({
        isRegionPlaying: false,
      })
    }
    return surfer.pause()
  }
  private playPauseRegion(ev?: any, mbId?: any) {
    if (ev) {
      ev.stopPropagation()
      ev.preventDefault()
    }
    const regions = this.props.spotDetails.regions
    if (mbId) {
      const region: any = regions[mbId]
      if (region && (this.state.isRegionPlaying || region.isPlaying)) {
        region.isPlaying = false
      } else if (region && (!this.state.isRegionPlaying || !region.isPlaying)) {
        region.isPlaying = true
      }
    }

    this.setState(
      {
        isRegionPlaying: !this.state.isRegionPlaying,
      },
      () => {
        const surfer = this.props.spotDetails.surfer
        return surfer.playPause()
      }
    )
  }
  private getMasterFilename = (master: string, bucket_name: string) => {
    //return "/7d/a5/05/7da5058bae7b4d73b0574c55859efe4e.flac"

    if (master.startsWith('/mnt/nas/yf/DDEX'))
      return master.replace('/mnt/nas/yf/DDEX', '')
    if (master.startsWith('/mnt/isilon2/masters/' + bucket_name))
      return master.replace('/mnt/isilon2/masters/' + bucket_name, '')
    if (master.startsWith('/mnt/nas02/S3/' + bucket_name + '/WAV/Full/'))
      return master.replace(
        '/mnt/nas02/S3/' + bucket_name + '/WAV/Full/',
        '/masters/2020/02/'
      )
    if (master.startsWith('/mnt/nas02/S3/' + bucket_name))
      return master.replace('/mnt/nas02/S3/' + bucket_name, '')
    return master
  }

  private saveParams(id: any, currentTime: any, index: number, duration?: any) {
    let newState = { ...this.state }

    if (newState.selectedMaster && newState.selectedMaster.id === id) {
      newState.selectedMaster.currentTime = currentTime
      newState.selectedIndex = index
      if (duration) {
        newState.selectedMaster.progress =
          (parseFloat(currentTime) / parseFloat(duration)) * 100
      }
    }
    newState.mbAudioInfos = newState.mbAudioInfos.map((x) => {
      if (newState.selectedMaster && newState.selectedMaster.id === x.id) {
        x.currentTime = newState.selectedMaster.currentTime
        if (duration) {
          x.progress =
            (parseFloat(newState.selectedMaster.currentTime) /
              parseFloat(duration)) *
            100
        }
      } else {
        x.currentTime = 0
        x.progress = 0
      }
      return x
    })

    this.setState(newState)
  }

  private setPlayerParams(id: any, duration: any) {
    let newState = { ...this.state }
    if (this.state.selectedMaster !== null) {
      let mb = newState.mbAudioInfos.filter(
        (x) => x.id === this.state.selectedMaster.id
      )[0]
      mb.duration = duration
      this.setState(newState)
    }
  }
  private togglePlayer(mb: any, isPlaying: boolean) {
    let newState = { ...this.state }
    const regions = this.props.spotDetails.regions
    const region: any = regions[mb.id]

    newState.mbAudioInfos.map((x) => {
      if (x) {
        if (x.id === mb.id) {
          x.isPlaying = isPlaying
          if (isPlaying && region && region.isPlaying) {
            this.playPauseRegion(mb.id)
            region.isPlaying = false
          }
        } else {
          x.isPlaying = false
          x.currentTime = 0
        }
      }
      return x
    })
    newState.selectedMaster = newState.mbAudioInfos.filter(
      (x) => x.id === mb.id
    )[0]
    this.setState(newState)
  }
  private drawMusicDetailsRow = (mb: IMusicBroadcasts, index: number) => {
    const duration =
      Math.round(parseFloat(mb.end_time)) -
      Math.round(parseFloat(mb.start_time))
    const title = mb.title !== '' ? mb.title : '-'
    const authors = mb.authors !== '' ? mb.authors : '-'
    const composers = mb.composers !== '' ? mb.composers : '-'
    const regions = this.props.spotDetails.regions
    const region: any = regions[mb.id]
    let filepathduration = 0,
      currentTime = 0,
      isPlaying = false
    if (mb.master_filepath !== null && mb.bucket_name) {
      const selectedMusic = this.state.mbAudioInfos.filter(
        (x) => x.id === mb.id
      )[0]
      if (selectedMusic !== undefined) {
        filepathduration = selectedMusic.duration
        currentTime = selectedMusic.currentTime
        isPlaying = selectedMusic.isPlaying
        if (region && region.isPlaying) {
          isPlaying = false
        }
        if (region && isPlaying) {
          region.isPlaying = false
        }
      }
    }
    return (
      <MD.TableRow key={'music-' + index}>
        <TableCellDensed style={{ textAlign: 'center', padding: '0 2px' }}>
          {title}
        </TableCellDensed>
        <TableCellDensed style={{ textAlign: 'center', padding: '0 2px' }}>
          {authors}
        </TableCellDensed>
        <TableCellDensed style={{ textAlign: 'center', padding: '0 2px' }}>
          {composers}
        </TableCellDensed>
        <TableCellDensed style={{ textAlign: 'center', padding: '0 2px' }}>
          {duration}
        </TableCellDensed>

        <TableCellDensed
          style={{ display: 'block', padding: '0 2px', width: '100%' }}
        >
          <MD.Grid container>
            <MD.Grid item xs={6}>
              {mb.master_filepath !== null && mb.bucket_name && (
                <AudioStreamPlayer
                  id={parseInt(mb.id)}
                  isPlaying={isPlaying}
                  index={index}
                  master={this.getMasterFilename(
                    mb.master_filepath,
                    mb.bucket_name
                  )}
                  bucket_name={mb.bucket_name}
                  duration={filepathduration}
                  saveParams={this.saveParams.bind(this, mb.id)}
                  setPlayerParams={this.setPlayerParams.bind(this, mb.id)}
                  togglePlayer={this.togglePlayer.bind(this, mb)}
                  currentTime={currentTime}
                />
              )}
            </MD.Grid>
            <MD.Grid item xs={6}>
              <CustomTooltip title={this.props.i18n._('Play video')}>
                <MD.IconButton
                  style={{ padding: 0 }}
                  onClick={(ev: any) => this.playRegion(ev, mb.id)}
                >
                  <PlayCircleFilledIcon
                    className={this.props.classes.iconStyle}
                    style={{ color: region ? region.color : undefined }}
                  />
                </MD.IconButton>
              </CustomTooltip>
            </MD.Grid>
          </MD.Grid>
        </TableCellDensed>
      </MD.TableRow>
    )
  }

  private displayMusicBroadcastContent = (
    music_broadcast: IMusicBroadcasts[]
  ) => {
    const details = this.props.spotDetails.metadatas
    if (music_broadcast && music_broadcast.length > 0) {
      return music_broadcast.map((mb: IMusicBroadcasts, index: number) => {
        return this.drawMusicDetailsRow(mb, index)
      })
    } else if (
      (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
        SPOT_STATUS_GERMANY.VALIDATED === details.music_state) ||
      (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
        GROUP_STATUS_FRANCE.VALIDATED.indexOf(details.music_state) > -1) // TODO check retour details.music_state
    ) {
      return (
        <MD.TableRow>
          <TableCellDensed colSpan={4} style={{ textAlign: 'center' }}>
            {this.props.i18n._('No music')}
          </TableCellDensed>
        </MD.TableRow>
      )
    }
  }

  private drawMusicDetailsAlertMessage() {
    const details = this.props.spotDetails.metadatas
    if (
      (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
        details.music_state !== GROUP_STATUS_FRANCE.VALIDATED &&
        details.music_state !== GROUP_STATUS_FRANCE.NEW_MUSIC_DETECTED) ||
      (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
        (details.music_state === SPOT_STATUS_GERMANY.MUSIC_RECO_OK ||
          details.music_state === SPOT_STATUS_GERMANY.CREATED))
    ) {
      return (
        <MD.Box style={{ margin: '10px 0px' }}>
          <MD.Grid container>
            <MD.Grid item>
              <span className={this.props.classes.reduceSvgIconSize}>
                <MD.SvgIcon
                  component={InfoIcon}
                  style={{ color: this.props.theme.palette.text.primary }}
                />
              </span>
            </MD.Grid>
            <MD.Grid item>
              <MD.Typography>
                {this.props.i18n._(
                  "Ce spot n'a pas été traité par nos opérateurs"
                )}
              </MD.Typography>
              <MD.Typography variant="caption" display="block" gutterBottom>
                {this.props.i18n._(
                  'Vous pouvez cependant créer un formulaire de déclaration et le compléter par vous même'
                )}
              </MD.Typography>
            </MD.Grid>
          </MD.Grid>
        </MD.Box>
      )
    }
  }

  private drawMusicDetails = () => {
    const details = this.props.spotDetails.metadatas
    let music_broadcast: IMusicBroadcasts[] = []

    if (details.metadatas) {
      music_broadcast = details.metadatas.music_broadcast
    }
    if (
      this.props.search.params.country === COUNTRY_OPTIONS.FRANCE &&
      GROUP_STATUS_FRANCE.VALIDATED.indexOf(details.music_state) < -1
    ) {
      // TODO check retour details.music_satte
      return
    } else if (
      this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
      SPOT_STATUS_GERMANY.VALIDATED !== details.music_state &&
      music_broadcast.length === 0
    ) {
      return
    } else {
      music_broadcast.sort(
        (a: IMusicBroadcasts, b: IMusicBroadcasts) =>
          parseInt(a.start_time) - parseInt(b.start_time)
      )
      return (
        <>
          <MD.TableContainer style={{ marginTop: '15px' }}>
            <MD.Box style={{ marginBottom: '5px' }}>
              <MD.Typography>{this.props.i18n._('Music')}</MD.Typography>
            </MD.Box>
            <MD.Table>
              <MD.TableHead>
                <MD.TableRow>
                  <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                    {this.props.i18n._('Title')}{' '}
                  </TableHeaderCellDensed>
                  <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                    {this.props.i18n._('Authors')}{' '}
                  </TableHeaderCellDensed>
                  <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                    {this.props.i18n._('Compositors')}{' '}
                  </TableHeaderCellDensed>
                  <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                    {this.props.i18n._('Duration') + ' (sec)'}{' '}
                  </TableHeaderCellDensed>
                  {this.props.spotDetails.regions !== undefined && (
                    <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                      {'REF'}&nbsp;&nbsp;&nbsp; {'|'}&nbsp;&nbsp;&nbsp;{' '}
                      {'MATCH'}{' '}
                    </TableHeaderCellDensed>
                  )}
                </MD.TableRow>
              </MD.TableHead>
              <MD.TableBody>
                {this.displayMusicBroadcastContent(music_broadcast)}
              </MD.TableBody>
            </MD.Table>
          </MD.TableContainer>
        </>
      )
    }
  }

  private handleProgressSeek(event: any, value: any) {
    event.stopPropagation()
    event.preventDefault()
    let newState = { ...this.state }
    const selectedMusic = newState.selectedMaster
    const duration = selectedMusic.duration
    let x: any = value
    if (isNaN(x)) {
      x = 0.0
    }
    this.videoHls = document.getElementById(`audio-${selectedMusic.id}`)
    this.videoHls.currentTime = x
    selectedMusic.currentTime = x
    if (x.toFixed(2) === duration.toFixed(2)) {
      this.videoHls.pause()
    }
    selectedMusic.progress = (parseFloat(x) / parseFloat(duration)) * 100
    this.setState(newState)
  }

  private drawSlider(mb: IMusicBroadcasts, index: any) {
    return (
      <PlayerTimeline
        handleProgressSeek={(e: any, value: any) =>
          this.handleProgressSeek(e, value)
        }
        index={index}
        selectedMusic={mb}
      />
    )
  }

  private handleClose = () => {
    this.setState({
      videoDialogOpened: false,
      spotPlayingPath: '',
      openSpotId: -1,
      openYacastPlayer: false,
    })
  }
  private handleCloseDownloadCSV = () => {
    this.setState({
      openDialogDownloadCSV: false,
    })
  }
  private showVideoPlayer = () => {
    const { classes, theme } = this.props
    let videoSource = this.state.spotPlayingPath
    if (videoSource) {
      return (
        <MD.Dialog
          onClose={this.handleClose.bind(this)}
          open={this.state.videoDialogOpened}
          maxWidth="lg"
          style={{ overflow: 'hidden' }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            theme={theme}
            i18n={this.props.i18n}
            id="draggable-dialog-title"
            onClose={this.handleClose.bind(this)}
            layout={this.props.layout}
          >
            {' '}
          </DialogTitle>
          <MD.DialogContent style={{ padding: '2px 12px' }}>
            <video autoPlay controls className={classes.videoPlayer}>
              <source src={videoSource} />
            </video>
          </MD.DialogContent>
        </MD.Dialog>
      )
    }
    return null
  }

  private playSpot(spot: IIdenticSpotNielsen) {
    this.setState({
      openSpotId: spot.ad_id,
      spotPlayingPath: spot.master_filepath,
      videoDialogOpened: true,
    })
  }

  private drawIdenticSpotDetails = () => {
    const { spotDetails } = this.props
    if (
      spotDetails.identicSpots &&
      spotDetails.identicSpots.length > 0 &&
      this.props.bucketId !== 18
    ) {
      return (
        <MD.TableContainer
          style={{ marginTop: '10px', marginLeft: '20px', padding: '8px' }}
        >
          <MD.Box style={{ marginBottom: '5px' }}>
            <MD.Typography>
              {spotDetails.identicSpots.length === 1
                ? this.props.i18n._('Identifier info')
                : this.props.i18n._('Identifiers list')}
            </MD.Typography>
          </MD.Box>
          <div
            className={
              spotDetails.identicSpots.length > 15
                ? this.props.classes.scrollSpotList
                : undefined
            }
          >
            <MD.Table>
              <MD.TableBody>
                {spotDetails.identicSpots.map((spot: any, index: number) => {
                  let filmid_label = ''
                  let code_arpp: any = ''
                  filmid_label = spot.filmid_label
                  if (
                    spot.hasOwnProperty('clearance_metadata') &&
                    spot.clearance_metadata !== null &&
                    !isNullOrUndefined(spot.clearance_metadata.clearance_decision) &&
                    !isNullOrUndefined(spot.clearance_metadata.clearance_decision
                      .clearance_decision_number)
                  ) {
                    code_arpp =
                      spot.clearance_metadata.clearance_decision
                        .clearance_decision_number
                  }

                  return (
                    <MD.TableRow key={'identic' + index}>
                      <TableCellDensed style={{ textAlign: 'left' }}>
                        {spot.id}
                      </TableCellDensed>
                      <TableCellDensed style={{ textAlign: 'left' }}>
                        {code_arpp}
                      </TableCellDensed>
                      <TableCellDensed style={{ textAlign: 'left' }}>
                        {filmid_label}
                      </TableCellDensed>
                    </MD.TableRow>
                  )
                })}
              </MD.TableBody>
            </MD.Table>
          </div>
        </MD.TableContainer>
      )
    }
    if (
      spotDetails.identicSpotsNielsen &&
      spotDetails.identicSpotsNielsen.length > 0 &&
      this.props.search.params.country === COUNTRY_OPTIONS.GERMANY
    ) {
      return (
        <MD.TableContainer
          style={{ marginTop: '10px', marginLeft: '20px', padding: '8px' }}
        >
          <MD.Box style={{ marginBottom: '5px' }}>
            <MD.Typography>
              {spotDetails.identicSpotsNielsen.length === 1
                ? this.props.i18n._('Identifier info')
                : this.props.i18n._('Identifiers list')}{' '}
              {' : '} {spotDetails.identicSpotsNielsen.length}
            </MD.Typography>
          </MD.Box>
          <div
            className={
              spotDetails.identicSpotsNielsen.length > 15
                ? this.props.classes.scrollSpotList
                : undefined
            }
          >
            <MD.Table>
              <MD.TableBody>
                {spotDetails.identicSpotsNielsen.map(
                  (spot: IIdenticSpotNielsen, index: number) => {
                    return (
                      <MD.TableRow
                        key={'identic' + index}
                        style={{
                          backgroundColor:
                            spot.ad_id === this.state.openSpotId
                              ? this.props.theme.palette.background.paper
                              : undefined,
                        }}
                      >
                        <TableCellDensed style={{ textAlign: 'left' }}>
                          {spot.external_id}
                        </TableCellDensed>
                        <TableCellDensed style={{ textAlign: 'left' }}>
                          {spot.title}
                        </TableCellDensed>
                        <TableCellDensed style={{ textAlign: 'left' }}>
                          <MD.IconButton
                            style={{ padding: '2px' }}
                            onClick={
                              () => this.playSpot(spot) //window.open(spot.master_filepath, '_blank')
                            }
                          >
                            <PlayCircleFilledIcon
                              className={this.props.classes.openLinkIcon}
                            />
                          </MD.IconButton>
                        </TableCellDensed>
                      </MD.TableRow>
                    )
                  }
                )}
              </MD.TableBody>
            </MD.Table>
          </div>
        </MD.TableContainer>
      )
    }
  }

  private getFormulairePreview = (
    regroupingId: string,
    fingerprint_list: IFingerprintBucketPair[]
  ) => {
    if (this.props.bucketId && this.props.fgpId) {
      this.props.getFormulairePreview(
        regroupingId,
        this.props.bucketId,
        this.props.fgpId,
        fingerprint_list
      )
    }
  }

  private drawSavedFormulairesTable = (
    formulaireList: (IFormulaireFranceState | IFormulaireGemaState)[]
  ) => {
    const { classes, theme } = this.props
    return (
      <MD.TableContainer style={{ marginTop: '15px' }}>
        <MD.Box style={{ marginBottom: '5px' }}>
          <MD.Typography>{this.props.i18n._('Formulaires')}</MD.Typography>
        </MD.Box>
        <MD.Table>
          <MD.TableHead>
            <MD.TableRow
              style={{ backgroundColor: theme.palette.background.default }}
            >
              <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                {this.props.i18n._('Status')}
              </TableHeaderCellDensed>
              <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                {this.props.i18n._('Version')}
              </TableHeaderCellDensed>
              <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                {this.props.i18n._('Created at')}
              </TableHeaderCellDensed>
              <TableHeaderCellDensed style={{ textAlign: 'center' }}>
                {this.props.i18n._('Modified at')}
              </TableHeaderCellDensed>
              <TableHeaderCellDensed
                style={{ textAlign: 'center' }}
              ></TableHeaderCellDensed>
            </MD.TableRow>
          </MD.TableHead>
          <MD.TableBody>
            {formulaireList.map(
              (
                item: IFormulaireFranceState | IFormulaireGemaState,
                index: number
              ) => {
                let lastModified: any = item.last_modified
                let createdAt: any = item.created_at

                lastModified = Moment(
                  lastModified[Object.keys(lastModified)[0]]
                )
                createdAt = Moment(createdAt[Object.keys(createdAt)[0]])

                return (
                  <MD.TableRow key={index}>
                    <TableCellDensed style={{ textAlign: 'center' }}>
                      {this.displayDeclarationStatus(item)}
                    </TableCellDensed>
                    <TableCellDensed style={{ textAlign: 'center' }}>
                      {item.version}
                    </TableCellDensed>
                    <TableCellDensed style={{ textAlign: 'center' }}>
                      {Moment(createdAt)
                        .parseZone()
                        .format('DD MMM YYYY HH:mm')}
                    </TableCellDensed>
                    <TableCellDensed style={{ textAlign: 'center' }}>
                      {Moment(lastModified)
                        .parseZone()
                        .format('DD MMM YYYY HH:mm')}
                    </TableCellDensed>
                    <TableCellDensed style={{ textAlign: 'center' }}>
                      <StyledLink
                        to={
                          '/formulaire/' +
                          this.props.bucketId +
                          '/' +
                          this.props.fgpId
                        }
                        onClick={this.props.fetchFormulaire.bind(
                          this,
                          this.props.spotDetails.fingerprintBucketList
                        )}
                      >
                        <MD.IconButton style={{ padding: '2px' }}>
                          <InsertDriveFileIcon
                            color="secondary"
                            className={classes.detailsIcon}
                          />
                        </MD.IconButton>
                      </StyledLink>
                    </TableCellDensed>
                  </MD.TableRow>
                )
              }
            )}
          </MD.TableBody>
        </MD.Table>
      </MD.TableContainer>
    )
  }

  private drawFormButtons = () => {
    const { spotDetails } = this.props

    if (this.props.spotDetails.loadingFormulaireList) {
      return (
        <MD.Grid style={{ marginLeft: '20px', padding: '8px' }}>
          <div>{this.props.i18n._('Loading')}...</div>
        </MD.Grid>
      )
    } else if (
      (this.props.spotDetails.loadingFormulaireList === false || isNullOrUndefined(this.props.spotDetails.loadingFormulaireList)) &&
      spotDetails.formulaireList.length === 0
    ) {
      return (
        <MD.Grid style={{ marginLeft: '20px', padding: '8px' }}>
          <StyledLink
            to={'/formulaire/' + this.props.bucketId + '/' + this.props.fgpId}
            onClick={this.getFormulairePreview.bind(
              this,
              spotDetails.regrouping_id,
              spotDetails.fingerprintBucketList
            )}
          >
            <ButtonDensedNoColor
              variant="contained"
              color="secondary"
              className={this.props.classes.buttonDensed}
            >
              <AddIcon style={{ fontSize: '18px' }} />
              <InsertDriveFileIcon
                style={{ fontSize: '15px', marginRight: '5px' }}
              />
              {this.props.i18n._('Créer un formulaire de déclaration')}
            </ButtonDensedNoColor>
          </StyledLink>
        </MD.Grid>
      )
    } else if (
      this.props.spotDetails.loadingFormulaireList !== null &&
      spotDetails.formulaireList.length > 0
    ) {
      return this.drawSavedFormulairesTable(spotDetails.formulaireList)
    }
  }

  private openReportDialog(ev: any) {
    this.setState({ openReportDialog: true })
  }
  private closeReportDialog() {
    this.setState({
      openReportDialog: false,
      senderEmail: '',
      emailError: '',
      reportComment: '',
    })
  }
  private validateEmail(value: string) {
    let error = ''
    if (value === '') {
      error = this.props.i18n._('This field is required.')
    } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)) {
      error = this.props.i18n._('Email is not valid.')
    }
    this.setState({ emailError: error })
  }
  private formIsValid() {
    const isValid = this.state.emailError === ''
    return isValid
  }
  private handleSenderEmailChange(ev: any) {
    let value = ev.target.value
    this.setState({ senderEmail: value })
  }
  private handleVerifySenderEmail(ev: any) {
    let value = ev.target.value
    this.validateEmail(value)
  }
  private onCommentChange(ev: any) {
    let value = ev.target.value
    this.setState({ reportComment: value })
  }
  private handleReportFormSubmit() {
    this.closeReportDialog()
    if (this.formIsValid()) {
      const details = this.props.spotDetails.metadatas
      let product_label = '-',
        title = '-',
        duration: string | number = '-',
        first_broadcast: Moment.Moment | null | string = null,
        advertiser = '-',
        media_name = '-'
      if (details.metadatas) {
        product_label = details.metadatas.product_label
        title = details.metadatas.title
        duration = details.metadatas.duration
        first_broadcast = details.metadatas.first_broadcast
        advertiser = details.metadatas.advertiser.name
        media_name = details.media.name
      }

      const location = window.location.href

      this.props.reportSpotProblem({
        spot_id: this.props.fgpId,
        email: this.state.senderEmail,
        comment: this.state.reportComment,
        product_label,
        title,
        duration,
        first_broadcast,
        advertiser,
        media_name,
        identic_spots: this.props.spotDetails.identicSpots,
        spot_link: location,
      })
    }
  }
  private drawReportDialog() {
    const { i18n } = this.props
    return (
      <MD.Dialog open={this.state.openReportDialog}>
        <MD.DialogTitle>
          {' '}
          {this.props.i18n._('Report problem with spot')}{' '}
          {`${this.props.fgpId}`}{' '}
        </MD.DialogTitle>
        <MD.DialogContent>
          <MD.DialogContentText>
            {this.props.i18n._(
              'To report a problem with this spot, please enter your email address here and indicate the nature of the problem in the comment field.'
            )}
          </MD.DialogContentText>
          <MD.TextField
            autoFocus
            margin="dense"
            id="email"
            label={this.props.i18n._('Email Address')}
            type="email"
            fullWidth
            variant="outlined"
            onBlur={this.handleVerifySenderEmail.bind(this)}
            onChange={this.handleSenderEmailChange.bind(this)}
            helperText={this.state.emailError}
            error={this.state.emailError !== ''}
          />
          <MD.TextField
            margin="dense"
            label={this.props.i18n._('Comments')}
            value={this.state.reportComment}
            fullWidth
            id="comment"
            type="search"
            multiline
            maxRows={5}
            variant="outlined"
            onChange={this.onCommentChange.bind(this)}
          />
        </MD.DialogContent>
        <MD.DialogActions>
          <MD.Button onClick={this.closeReportDialog.bind(this)}>
            {i18n._('Cancel')}
          </MD.Button>
          <MD.Button
            onClick={this.handleReportFormSubmit.bind(this)}
            disabled={!this.formIsValid()}
          >
            {i18n._('Send')}
          </MD.Button>
        </MD.DialogActions>
      </MD.Dialog>
    )
  }
  private drawReportButtons() {
    return (
      <MD.Grid
        style={{ margin: '0px 20px', padding: '8px', textAlign: 'right' }}
      >
        {this.drawReportDialog()}
        <ButtonDensedNoColor
          variant="contained"
          color="secondary"
          onClick={this.openReportDialog.bind(this)}
          className={this.props.classes.buttonDensed}
        >
          <MD.Typography variant="body2" align="center">
            {this.props.i18n._('Report Spot')}
          </MD.Typography>
        </ButtonDensedNoColor>
      </MD.Grid>
    )
  }
  private drawButtons() {
    return (
      <MD.Grid container spacing={2}>
        <MD.Grid item xs={6}>
          {this.drawFormButtons()}
        </MD.Grid>
        <MD.Grid item xs={6}>
          {this.drawReportButtons()}
        </MD.Grid>
      </MD.Grid>
    )
  }

  private showYacastPlayer(
    tokenPlayer: string,
    mediaID: number,
    mediaName: string,
    startDate: number,
    endDate: number
  ) {
    let duration = (endDate - startDate) / 1000
    let timelineDuration = duration + 6 * 60
    return (
      <MD.Dialog
        onClose={this.handleClose.bind(this)}
        open={this.state.openYacastPlayer}
        maxWidth="lg"
        style={{ overflow: 'hidden' }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          i18n={this.props.i18n}
          id="draggable-dialog-title"
          onClose={this.handleClose.bind(this)}
          layout={this.props.layout}
        >
          {'  '}
        </DialogTitle>
        <MD.DialogContent style={{ width: '700px', padding: '2px 12px' }}>
          <YacastPlayer
            apiKey={tokenPlayer}
            mediaName={mediaName}
            mediaID={mediaID}
            start={new Date(startDate - 1000)}
            autoplay={true}
            timelineDuration={timelineDuration}
            timelineHaveReadjustedEPG={true}
            timelineHaveMusicEPG={false}
            selectionEnd={new Date(endDate)}
            selectionStart={new Date(startDate)}
            initialZoom={0}
            aclToken={this.props.user.token}
          />
        </MD.DialogContent>
      </MD.Dialog>
    )
  }

  public render(): React.ReactNode {
    return this.props.spotDetails.spotDetailsLoading ? (
      <MD.Grid
        container
        direction="row"
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: `calc(100vh - ${theme.Adtrack.dimensions.appBarHeight + 8}px)`,
        }}
        spacing={1}
      >
        <MD.Grid item xs={6}>
          <Skeleton variant="rect" width={'100%'} height={`calc(100vh - ${theme.Adtrack.dimensions.appBarHeight + 16}px)`} animation="wave" />
        </MD.Grid>
        <MD.Grid item xs={6}>
          <Skeleton variant="rect" width={'100%'} height={`calc(100vh - ${theme.Adtrack.dimensions.appBarHeight + 16}px)`} animation="wave" />
        </MD.Grid>
      </MD.Grid>
    ) : (
      <>
        <MD.Grid
          container
          direction="row"
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: `calc(100vh - ${theme.Adtrack.dimensions.appBarHeight + 8}px)`,
          }}
        >
          <MD.Grid item xs={6}>
            {this.state.openDialogDownloadCSV && this.openDialogDownloadCSV()}
            {this.showVideo()}
            {this.drawProductDetails()}
            {this.drawMusicDetailsAlertMessage()}
            {this.drawMusicDetails()}
            {this.state.selectedMaster !== null &&
              this.drawSlider(
                this.state.selectedMaster,
                this.state.selectedIndex
              )}
          </MD.Grid>
          <MD.Grid item xs={6}>
            {this.drawButtons()}
            {this.drawIdenticSpotDetails()}
            {hasPermissionMediaPassagesToRead(this.props.user)
              ? this.drawBroadcastsDetails()
              : null}
          </MD.Grid>
        </MD.Grid>
        <div style={{ width: '100%' }}>
          {this.state.openYacastPlayer &&
            this.showYacastPlayer(
              this.props.user.tokenPlayer,
              this.state.mediaIDtoPlay,
              this.state.mediaNametoPlay,
              this.state.startDatetoPlay,
              this.state.endDatetoPlay
            )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (
  { i18n, search, spotDetails, user, layout }: IApplicationState,
  ownProps: RouteComponentProps<{}>
) => ({
  i18n: i18n.i18n,
  localeInfos: i18n.localeInfos,
  router: ownProps,
  search,
  spotDetails,
  user,
  layout,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSpotFormulaires: (fgpBucketPair: IFingerprintBucketPair[]) => dispatch(getSpotFormulaires(fgpBucketPair)),
  getBroadcastsPassages: (
    fgpBucketPair: IFingerprintBucketPair[],
    startDate: Moment.Moment,
    endDate: Moment.Moment
  ) => dispatch(getBroadcastsPassages(fgpBucketPair, startDate, endDate)),
  getBroadcastsPassagesByFgpID: (
    fgpid: number,
    bucket_id: number,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
    params: IPaginationParams,
    media_id?: number[]
  ) =>
    dispatch(
      getBroadcastsPassagesByFgpID(
        fgpid,
        bucket_id,
        startDate,
        endDate,
        params,
        media_id
      )
    ),
  getBroadcastWebPassages: (
    startDate: Moment.Moment,
    endDate: Moment.Moment,
    group_id: number | undefined,
    page: number,
    limit: number
  ) =>
    dispatch(
      getBroadcastWebPassages(startDate, endDate, group_id, page, limit)
    ),
  getFormulairePreview: (
    regroupingId: string,
    bucketId: number,
    spotId: number,
    fingerprint_list: IFingerprintBucketPair[]
  ) =>
    dispatch(
      getFormulairePreview(regroupingId, bucketId, spotId, fingerprint_list)
    ),
  getSpotDetails: (bucketId: number, spotId: number) =>
    dispatch(getSpotDetails(bucketId, spotId)),
  downloadCSV: (startDate: Moment.Moment, endDate: Moment.Moment) =>
    dispatch(downloadCSV(startDate, endDate)),
  fetchFormulaire: (fingerprint_list: IFingerprintBucketPair[]) =>
    dispatch(fetchFormulaire(fingerprint_list)),
  reportSpotProblem: (params: IReportSpotDetails) =>
    dispatch(reportSpotProblem(params)),
  getMediaWeb: () => dispatch(getMediaWeb()),
})

export const DetailsContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(DetailsContentComponent)))
