import * as MD from '@material-ui/core';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enGB, fr, de } from "date-fns/locale";
import Moment from "moment";
import { Collapse, SvgIcon, WithTheme } from '@material-ui/core';

import { IApplicationState, IConnectedReduxProps } from '../../Store';

import { ILayoutState } from '../../Store/Layout/Types';

import { getTheme, PaletteMode } from '../../Themes';

import { I18N, ILocaleInfos } from '../../Store/I18n/Types';
import { ButtonDensed, FormControlLabelSmall, SmallCheckbox } from '../../Themes/StyledElements';

// ICONS 
import ClearIcon from '@material-ui/icons/Clear';
import HistoryIcon from '@material-ui/icons/History';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import LanguageIcon from '@material-ui/icons/Language';
import { RadioIcon } from '../../Themes/svg/radio-icon';
import { TvIcon } from '../../Themes/svg/tv-icon';

import { ISearchState, EMediaTypes, ISearchParams, IOrderByParams, IMusicStateTypes, IMediaSupport, COUNTRY_OPTIONS, BucketMedia, IAdType, IAdtypes, CInitialSearchParams } from '../../Store/Search/Types';
import { launchSearch } from '../../Store/Search/Actions';
import { RouterState } from 'connected-react-router';
import { InfoIcon } from '../../Themes/svg/info_icon';
import { WithStyles } from '@material-ui/styles/withStyles';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from '../../Utils/Various';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Calendar } from '../Calendar';

// tslint:disable: no-var-requires
const Jed = require('jed');
// tslint:enable: no-var-requires

interface IPropsFromState {
  i18n: I18N;
  layout: ILayoutState;
  localeInfos: ILocaleInfos;
  search: ISearchState;
  router: RouterState;
}

interface IState {
  endDate: Moment.Moment | null | string;
  firstBroadcast: boolean;
  childMediaId: number[] | undefined;
  mediaTypeTV: boolean;
  mediaTypeWeb: boolean;
  mediaTypeRadio: boolean;
  orderBy: IOrderByParams[];
  searchInput: string;
  searchAdDataInput: string;
  searchCodeInput: string;
  searchMusicDataInput: string;
  searchMusicState: IMusicStateTypes[] | undefined;
  adType: any;
  isAllChecked: boolean;
  startDate: Moment.Moment | null | string;
  nonValidatedSpots: boolean | undefined;
  items: any[];
  initialSupportId: number[] | null;
  initialSupportGroupId: number[];
  selectedSupportIds: number[];
  selectedSupportGroupIds: number[];
  openSelectSupport: any;
}

interface IPropsFromDispatch {
  launchSearch: typeof launchSearch;
}

type AllProps =
  WithTheme &
  WithStyles<typeof styles> &
  IPropsFromState &
  IPropsFromDispatch &
  RouteComponentProps<{}> &
  IConnectedReduxProps;

const styles = (theme: any) => MD.createStyles({
  nested: {
    paddingLeft: '30px'
  },
  datePicker: {
    display: 'flex',
    alignItems: 'flex-end',
    fontStyle: 'italic',
    fontSize: 'x-small',
    color: theme.palette.text.secondary,
    fontFamily: 'sans-serif',
    marginBottom: '6px'
  },
  datePickerInput: {
    border: 'none',
    marginRight: '10px',
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  datePickerLabel: {
    fontSize: '13px',
    fontWeight: 100,
    color: theme.palette.text.secondary,
    textTransform: 'capitalize'
  },
  datePickerInputSmallFont: {
    fontSize: '12px',
    "& input": {
      textAlign: 'center',
      padding: '8px 4px',
      color: theme.palette.text.secondary,
    }
  },
  drawer: {
    '&.hidden': {
      width: '0px',
    },
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.Adtrack.dimensions.drawerWidth,
    paddingTop: '14px',
    paddingLeft: '15px',
    paddingRight: '15px',
    backgroundColor: theme.palette.background.default
  },
  formControlLabel: {
    fontSize: '15px',
    fontWeight: 400,
    opacity: '70%'
  },
  formControlIcon: {
    fontSize: 'large',
    fontWeight: 100,
    opacity: '70%'
  },
  iconButton: {
    padding: 10,
  },
  inputSearch: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "&:before": {
      border: 'none'
    },
  },
  lowerCase: { textTransform: 'lowercase' },
  inputLabelPropsStyle: {
    top: '-14px',
    fontSize: '12px',
  },
  inputPropsRoot: {
    marginTop: '7px !important'
  },
  intputLabelFont: {
    fontSize: "11px",
    color: theme.palette.text.secondary,
    opacity: 0.9
  },
  inputPropsStyle: {
    height: '18px',
    padding: '0 14px',
    fontSize: '12px',
  },
  launchSearch: {
    marginTop: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  opacity70: {
    opacity: '70%'
  },
  reduceSvgIconSize: {
    "& svg": {
      transform: 'scale(0.7)',
    }
  },
  rootSearch: {
    alignItems: 'center',
    display: 'flex',
    padding: '2px 0',
    "& .MuiInput-root:before": {
      border: 'none'
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: 'none'
    },
    "& .MuiInput-underline:after": {
      border: 'none'
    },
  },
  tab: {
    minWidth: '45%',
    fontSize: 'inherit',
    paddingTop: '0px',
    paddingBottom: '0px',
    minHeight: '50px',
    color: theme.palette.text.primary
  },
});

export class DrawerComponent extends React.Component<AllProps, IState> {

  public constructor(props: AllProps) {
    super(props);

    let musicStates = undefined;

    if (this.props.search.params.music_states) {
      musicStates = this.props.search.params.music_states.map((item: any) => IMusicStateTypes[item as any]) as any;
    }

    this.state = {
      endDate: this.props.search.params.end_date,
      firstBroadcast: this.props.search.params.first_broadcast,
      childMediaId: this.props.search.params.child_media_id,// if type = web => change values
      mediaTypeRadio: this.props.search.params.media.includes(EMediaTypes.RADIO),
      mediaTypeTV: this.props.search.params.media.includes(EMediaTypes.TV),
      mediaTypeWeb: this.props.search.params.media.includes(EMediaTypes.Online),
      orderBy: this.props.search.params.params.order_by,
      searchInput: this.props.search.params.query,
      searchAdDataInput: this.props.search.params.search_ad_data,
      searchCodeInput: this.props.search.params.search_code,
      searchMusicDataInput: this.props.search.params.search_music_data,
      searchMusicState: musicStates,
      adType: null,
      isAllChecked: true,
      startDate: this.props.search.params.start_date,
      nonValidatedSpots: this.props.search.params.non_validated_spots,
      items: [],
      initialSupportId: null,
      selectedSupportIds: [],
      selectedSupportGroupIds: [0, 1, 2],
      initialSupportGroupId: [0, 1, 2],
      openSelectSupport: { 0: false, 1: false, 2: false }
    }
  }

  static getDerivedStateFromProps(props: AllProps, state: IState) {
    if (props.search.params.music_states !== undefined && state.searchMusicState === undefined) {
      state.searchMusicState = props.search.params.music_states
    }
    if (state.mediaTypeWeb && props.search.params.country === COUNTRY_OPTIONS.FRANCE) {
      state.mediaTypeWeb = false;
      state.mediaTypeTV = true;
      state.mediaTypeRadio = false;
    }
    if (props.search.supportType.length > 0 && state.initialSupportId === null) {
      state.initialSupportId = props.search.initialMediaWebId;
      state.selectedSupportIds = props.search.initialMediaWebId;
    }
    if (state.adType === null && state.mediaTypeTV && props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
      if (!isNullOrUndefined(props.search.params.ad_type)) {
        state.adType = props.search.params.ad_type
        state.isAllChecked = props.search.params.ad_type.length === 5 || props.search.params.ad_type.length === 0;
      } else {
        state.adType = undefined
      }
    }
    return state;
  }

  private handleSearch = () => {

  }

  private onStartDateChange = (date: any) => {
    this.setState({ startDate: date });
  }

  private onEndDateChange = (date: any) => {
    this.setState({ endDate: date });
  }

  private saveDates(startDate: any, endDate: any) {
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
    if (this.state.startDate && Moment(this.state.startDate).year() > 2018) {
      this.setState({
        firstBroadcast: true
      })
    }
  }

  private drawDatePickerForm() {
    return (
      <MD.FormControl style={{ marginTop: '20px' }}>
        <Calendar
          saveDates={this.saveDates.bind(this)}
        />
      </MD.FormControl>
    )
  }
  private drawDatePickerForm0 = () => {
    const { classes, localeInfos } = this.props;
    let minStartDate = new Date();

    if (this.state.startDate !== null && typeof this.state.startDate !== 'string') {
      minStartDate.setTime(this.state.startDate.valueOf() /*+ 86400000*/);
    }
    let format: string = '', locale: Locale = fr;
    if (localeInfos.momentLocale === "en-us") {
      format = "MM/dd/yyyy";
      locale = enGB;
    } else if (localeInfos.momentLocale === "fr") {
      format = "dd/MM/yyyy";
      locale = fr
    } else if (localeInfos.momentLocale === "de") {
      format = "dd/MM/yyyy";
      locale = de
    }

    return (
      <MD.FormControl style={{ marginTop: '20px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <MD.Grid container justifyContent='center' alignItems='center' direction='row'>
            <MD.Grid direction="row" container justifyContent='space-around' style={{ fontSize: '13px' }}>
              <MD.Grid style={{ textTransform: 'capitalize', display: 'flex', marginTop: 8 }}>
                <MD.FormLabel>{this.props.i18n._('from')}:</MD.FormLabel>
              </MD.Grid>
              <div style={{ width: '35%' }}>
                <DatePicker
                  className={classes.datePickerInput}
                  autoOk={true}
                  //disableToolbar
                  variant="inline"
                  views={["month", "year"]}
                  openTo={"year"}
                  format={format}
                  id="date-picker-dialog"
                  value={this.state.startDate}
                  onChange={this.onStartDateChange.bind(this)}
                  InputProps={{ disableUnderline: true, className: classes.datePickerInputSmallFont }}
                  InputLabelProps={{ className: classes.datePickerLabel }}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                />
              </div>

              <MD.Grid style={{ display: 'flex', marginTop: 8 }}>
                <MD.FormLabel>{this.props.i18n._('to')}:</MD.FormLabel>
              </MD.Grid>
              <div style={{ width: '35%' }}>
                <DatePicker
                  className={classes.datePickerInput}
                  //disableToolbar
                  views={["month", "year"]}
                  openTo={"year"}
                  variant='inline'
                  format={format}
                  id='date-picker-dialog'
                  value={this.state.endDate}
                  onChange={this.onEndDateChange.bind(this)}
                  disableFuture={true}
                  autoOk={true}
                  InputProps={{ disableUnderline: true, className: classes.datePickerInputSmallFont }}
                  InputLabelProps={{ className: classes.datePickerLabel }}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                /></div>
            </MD.Grid>
          </MD.Grid>
        </MuiPickersUtilsProvider>
      </MD.FormControl>
    );
  }

  private handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.stopPropagation();
    let value = event.target.value;
    if (value.startsWith("\t")) {
      value = value.split("\t")[1]
    }
    this.setState({ [event.target.name]: value as any } as Pick<IState, keyof IState>)
  }


  private handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.checked as any } as Pick<IState, keyof IState>)
  }
  private onSearchInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onLaunchSearchClick()
    }
  }

  private handleChanelsPanel(id: number, openSelect: any) {
    let openSelectCopy = { ...openSelect }
    openSelectCopy[id] = !openSelectCopy[id]
    this.setState({ openSelectSupport: openSelectCopy })
  }

  private checkPanels(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number, selectedIds: number[], selectedPanelsIds: number[]) {
    event.stopPropagation()
    let panels: number[] = [...selectedPanelsIds];
    let selectedIdsCopy = [...selectedIds]
    let items = { ...this.props.search.supportType }
    if (panels.includes(id)) {
      panels = panels.filter(e => e !== id)
      if (items !== null) {
        items[id].subitems.length > 0 && items[id].subitems.map((x: any) => {
          selectedIdsCopy = selectedIdsCopy.filter(e => e !== x.id)
          return selectedIdsCopy
        })
      }
    } else {
      panels.push(id);
      if (items !== null) {
        items[id].subitems.length > 0 && items[id].subitems.map((x: any) => {
          if (!selectedIdsCopy.some(selectedId => selectedId === x.id)) {
            selectedIdsCopy.push(x.id)
          }
          return selectedIdsCopy
        })
      }
    }
    this.setState({
      selectedSupportIds: selectedIdsCopy,
      selectedSupportGroupIds: panels
    })
  }

  private handleChannels(id: number, panelID: number, selectedIds: number[], selectedPanelsIds: number[]) {
    let selectedPanelsIdsCopy = [...selectedPanelsIds]
    let selectedIdsCopy = [...selectedIds]
    selectedPanelsIdsCopy = selectedPanelsIdsCopy.filter(e => e !== panelID)
    if (selectedIdsCopy.includes(id)) {
      selectedIdsCopy = selectedIdsCopy.filter(e => e !== id)
    } else {
      selectedIdsCopy.push(id)
    }
    this.setState({
      selectedSupportIds: selectedIdsCopy,
      selectedSupportGroupIds: selectedPanelsIdsCopy
    })
  }

  private buildMedias(list: any, allMedias: any, selectedIds: number[], selectedPanelsIds: number[], openSelect: any) {
    const { classes, theme, search } = this.props;

    let items: IMediaSupport[] = [...list.items];
    const checked = ((itemId: number, sitemId: number) => {
      return selectedIds.indexOf(sitemId) > -1 || selectedPanelsIds.indexOf(itemId) > -1
    })
    const selectedNames = ((id: number) => {
      let selectedMedias: string[] = [];
      if (!isNullOrUndefinedOrEmptyArray(items)) {
        items[id].subitems.length > 0 && items[id].subitems.map((x: any) => {
          selectedIds.map(y => {
            if (typeof (y) === 'number' && y === x.id) {
              selectedMedias.push(x.name)
            }
            return selectedMedias
          })
          return selectedMedias
        })
      }
      return selectedMedias
    })
    if (!isNullOrUndefinedOrEmptyArray(items)) {
      return (
        <MD.List
          key={list.id}
        >
          <div>
            {items.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <MD.ListItem
                    button
                    onClick={() => this.handleChanelsPanel(item.id, openSelect)
                    }
                    style={{ padding: '0px', height: '25px' }}
                  >
                    <SmallCheckbox onClick={(e) => this.checkPanels(e, item.id, selectedIds, selectedPanelsIds)
                    }
                      checked={selectedPanelsIds.indexOf(item.id) > -1}
                    />
                    <MD.Tooltip title={selectedNames(index).join(',')}>
                      <MD.ListItemText
                        primaryTypographyProps={{ style: { fontSize: '12px' } }}
                        primary={
                          <>
                            {item.name === 'Panel Web' ? this.props.i18n._('Panel site web') : item.name}
                            {selectedNames(index).length > 0 &&
                              <span style={{ paddingLeft: '10px', fontSize: "12px" }}>
                                ({selectedNames(index).length})
                              </span>
                            }
                          </>}
                      />
                    </MD.Tooltip>
                    {item.subitems.length > 0 &&
                      (openSelect[item.id] ? (
                        <ExpandLess style={{ fontSize: '25px', marginLeft: 20, color: selectedPanelsIds.indexOf(item.id) > -1 ? theme.palette.secondary.main : 'inherit' }} />
                      ) : (
                        <ExpandMore style={{ fontSize: '25px', marginLeft: 20, color: selectedPanelsIds.indexOf(item.id) > -1 ? theme.palette.secondary.main : 'inherit' }} />
                      ))}
                  </MD.ListItem>
                  <Collapse
                    key={item.id}
                    component="li"
                    in={openSelect[item.id]}
                    timeout="auto"
                    unmountOnExit
                    className={classes.nested}
                  >
                    <MD.List disablePadding>
                      {item.subitems.map(
                        (sitem: any) => {
                          return (
                            <MD.ListItem
                              button
                              key={
                                sitem.id
                              }
                              onClick={this.handleChannels.bind(
                                this,
                                sitem.id,
                                item.id,
                                selectedIds, selectedPanelsIds
                              )
                              }
                              style={{ padding: '0px', height: '25px' }}
                            >
                              <SmallCheckbox
                                checked={
                                  checked(item.id, sitem.id)
                                } />
                              <MD.ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    fontSize: '12px',
                                  }
                                }}
                                key={
                                  sitem.id
                                }
                                primary={
                                  sitem.name === 'Panel Web' ? this.props.i18n._('Panel site web') : sitem.name
                                }
                              />
                            </MD.ListItem>
                          );
                        }
                      )}
                    </MD.List>
                  </Collapse>{" "}
                </React.Fragment>
              );
            })
            }
          </div>
        </MD.List>
      );
    } else {
      return (
        null
      );
    }
  }
  private drawSelectSupport() {
    let { classes } = this.props;
    let newState = { ...this.state };
    return (
      <MD.FormControl style={{ marginTop: '20px' }}>
        <MD.FormLabel component="legend" style={{ fontSize: "11px" }}>{this.props.i18n._("Support")}</MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }} className={classes.rootSearch} component="form" elevation={5}>
          {this.buildMedias({ id: 1, title: 'Support', items: this.props.search.supportType },
            this.props.search.supportType, newState.selectedSupportIds, newState.selectedSupportGroupIds, newState.openSelectSupport)
          }
        </MD.Paper>
      </MD.FormControl>
    )
  }

  private drawSearchAdDataForm = () => {
    let { classes } = this.props;
    return (
      <MD.FormControl style={{ marginTop: '20px' }}>
        <MD.FormLabel component="legend" style={{ fontSize: "11px" }}>{this.props.i18n._("Search in advertising data")}</MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }} className={classes.rootSearch} component="form" elevation={5}>
          <MD.TextField
            name={'searchAdDataInput'}
            className={classes.inputSearch}
            label={this.props.i18n._("Advertiser") + "/" + this.props.i18n._("Product")}
            value={this.state.searchAdDataInput}
            inputProps={{ 'aria-label': 'search ad data' }}
            onChange={this.handleSearchInputChange}
            InputProps={{ classes: { input: classes.inputPropsStyle, formControl: classes.inputPropsRoot } }}
            InputLabelProps={{ className: classes.inputLabelPropsStyle }}
            onKeyDown={this.onSearchInputKeyDown}
          />
          <MD.IconButton style={{ padding: '5px' }} onClick={() => { this.setState({ searchAdDataInput: "" }) }}>
            <ClearIcon style={{ fontSize: '18px' }} />
          </MD.IconButton>
        </MD.Paper>
      </MD.FormControl>
    )
  }

  private drawSearchCodeForm = () => {
    let { classes } = this.props;
    return (
      <MD.FormControl style={{ marginTop: '20px' }} >
        <MD.FormLabel component="legend" style={{ fontSize: "11px" }}>{this.props.i18n._("Search by code id")}</MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }} className={classes.rootSearch} component="form" elevation={5}>
          <MD.TextField
            name={'searchCodeInput'}
            className={classes.inputSearch}
            label={this.props.i18n._("Code de pub")}
            value={this.state.searchCodeInput}
            inputProps={{ 'aria-label': 'search code id' }}
            onChange={this.handleSearchInputChange}
            InputProps={{ classes: { input: classes.inputPropsStyle, formControl: classes.inputPropsRoot } }}
            InputLabelProps={{ className: classes.inputLabelPropsStyle }}
            onKeyDown={this.onSearchInputKeyDown}
          />
          <MD.IconButton style={{ padding: '5px' }} onClick={() => { this.setState({ searchCodeInput: "" }) }}>
            <ClearIcon style={{ fontSize: '18px' }} />
          </MD.IconButton>
        </MD.Paper>
      </MD.FormControl>
    )
  }

  private drawSearchMusicDataForm = () => {
    let { classes } = this.props;
    return (
      <MD.FormControl style={{ marginTop: '20px' }}>
        <MD.FormLabel component="legend" style={{ fontSize: "11px" }}>{this.props.i18n._("Search in music data")}</MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }} className={classes.rootSearch} component="form" elevation={5}>
          <MD.TextField
            name={'searchMusicDataInput'}
            className={classes.inputSearch}
            label={this.props.i18n._("Author") + "/" + this.props.i18n._("Composer") + "/" + this.props.i18n._("Title") + "/" + this.props.i18n._("ISRC")}
            value={this.state.searchMusicDataInput}
            inputProps={{ 'aria-label': 'search music data' }}
            onChange={this.handleSearchInputChange}
            InputProps={{ classes: { input: classes.inputPropsStyle, formControl: classes.inputPropsRoot } }}
            InputLabelProps={{ className: classes.inputLabelPropsStyle }}
            onKeyDown={this.onSearchInputKeyDown}
          />
          <MD.IconButton style={{ padding: '5px' }} onClick={() => { this.setState({ searchMusicDataInput: "" }) }}>
            <ClearIcon style={{ fontSize: '18px' }} />
          </MD.IconButton>
        </MD.Paper>
      </MD.FormControl>
    )
  }


  private handleMediaTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'tv') {
      this.setState({ mediaTypeTV: true, mediaTypeRadio: false, mediaTypeWeb: false })
    } else if (event.target.name === 'radio') {
      this.setState({ mediaTypeTV: false, mediaTypeRadio: true, mediaTypeWeb: false })
    } else if (event.target.name === 'web') {
      this.setState({ mediaTypeRadio: false, mediaTypeTV: false, mediaTypeWeb: true })
    }
  }

  private handleFirstBroadcastChange = (event: React.ChangeEvent<HTMLInputElement>, newvalue: string) => {
    if (newvalue === 'firstbroadcast') {
      if (!this.state.firstBroadcast) {
        this.setState({ firstBroadcast: true })
      }
    } else if (newvalue === 'mediaplan') {
      if (this.state.firstBroadcast) {
        this.setState({ firstBroadcast: false })
      }
    }
  }

  private drawRadioTVSelect = () => {
    const { classes, layout } = this.props;
    const theme = getTheme(layout.theme);
    let maxWidth = this.props.search.params.country === COUNTRY_OPTIONS.GERMANY ? "30%" : "50%";
    let tvBorder = { borderBottom: '2px solid', borderBottomColor: '#EEEEEE46', maxWidth }
    let radioBorder = { borderBottom: '2px solid', borderBottomColor: '#EEEEEE46', maxWidth }
    let webBorder = { borderBottom: '2px solid', borderBottomColor: '#EEEEEE46', maxWidth }

    if (this.state.mediaTypeTV) {
      tvBorder = { borderBottom: '2px solid ', borderBottomColor: theme.palette.primary.main, maxWidth }
    }

    else if (this.state.mediaTypeRadio) {
      radioBorder = { borderBottom: '2px solid ', borderBottomColor: theme.palette.primary.main, maxWidth }
    }

    else if (this.state.mediaTypeWeb) {
      webBorder = { borderBottom: '2px solid ', borderBottomColor: theme.palette.primary.main, maxWidth }
    }

    let iconDesactivatedColor = theme.palette.secondary.light + '46';
    if (layout.theme === PaletteMode.DARK) {
      iconDesactivatedColor = theme.palette.secondary.dark + '46';
    }

    return (
      <MD.Grid container justifyContent='space-between' style={{ marginTop: '20px' }} direction='row'>
        <MD.Grid item xs={6} style={tvBorder}>
          <MD.FormControlLabel
            style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}
            classes={{ label: classes.formControlLabel }}
            control={
              <MD.Checkbox
                checked={this.state.mediaTypeTV}
                onChange={this.handleMediaTypeChange}
                icon={<SvgIcon component={TvIcon} className={classes.formControlIcon} htmlColor={iconDesactivatedColor} />}
                checkedIcon={<SvgIcon component={TvIcon} className={classes.formControlIcon} htmlColor={theme.palette.primary.main} />}
                name="tv" />
            }
            label="TV"
          />
        </MD.Grid>
        <MD.Grid item xs={6} style={radioBorder}>
          <MD.FormControlLabel
            style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}
            classes={{ label: classes.formControlLabel }}
            control={
              <MD.Checkbox
                checked={this.state.mediaTypeRadio}
                onChange={this.handleMediaTypeChange}
                icon={<SvgIcon component={RadioIcon} className={classes.formControlIcon} htmlColor={iconDesactivatedColor} />}
                checkedIcon={<SvgIcon component={RadioIcon} className={classes.formControlIcon} htmlColor={theme.palette.primary.main} />}
                name="radio" />
            }
            label={this.props.i18n._("RADIOS")}
          />
        </MD.Grid>
        {this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
          <MD.Grid item xs={6} style={webBorder}>
            <MD.FormControlLabel
              style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column' }}
              classes={{ label: classes.formControlLabel }}
              control={
                <MD.Checkbox
                  checked={this.state.mediaTypeWeb}
                  onChange={this.handleMediaTypeChange}
                  icon={<SvgIcon component={LanguageIcon} className={classes.formControlIcon} style={{ color: iconDesactivatedColor, fontSize: 'inherit', marginTop: '-5px' }} />}
                  checkedIcon={<SvgIcon component={LanguageIcon} className={classes.formControlIcon} style={{ color: theme.palette.primary.main, fontSize: 'inherit', marginTop: '-5px' }} />}
                  name="web" />
              }
              label="ONLINE"
            />
          </MD.Grid>
        }
      </MD.Grid>
    );
  }

  private formatCode(code: string) {
    let sub_code1 = code.substring(0, 4);
    let sub_code2 = code.substring(4, 6);
    let sub_code3 = code.substring(6, code.length);
    let testOK1 = sub_code1.length === 4 && parseInt(sub_code1) >= 2000 && parseInt(sub_code1) <= 3000;
    let testOK2 = sub_code2.length === 2 && parseInt(sub_code2) >= 1 && parseInt(sub_code2) <= 12;
    let testOK3 = sub_code3.length === 4
    if (testOK1 && testOK2 && testOK3) {
      code = code.substring(0, 4) + "." + code.substring(4, 6) + "." + code.substring(6, code.length);
    }
    return code
  }

  private onLaunchSearchClick = () => {
    let types: any[] = [];
    let bucket_id: any[] = [];
    if (this.state.mediaTypeRadio) {
      types = [EMediaTypes.RADIO]
      if (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE) {
        bucket_id = BucketMedia.FR_RADIO
      } else if (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
        bucket_id = BucketMedia.DE_RADIO
      }
    }
    if (this.state.mediaTypeTV) {
      types = [EMediaTypes.TV]
      if (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE) {
        bucket_id = BucketMedia.FR_TV
      } else if (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
        bucket_id = BucketMedia.DE_TV
      }
    }
    if (this.state.mediaTypeWeb) {
      types = [EMediaTypes.Online];
      if (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
        bucket_id = BucketMedia.DE_WEB
      }
    }
    let code = this.state.searchCodeInput;
    code = code.replace('\t', '');
    code = code.replace(/\s/g, '')
    if (this.props.search.params.country === COUNTRY_OPTIONS.FRANCE && code !== "" && code.indexOf('.') < 0) {
      code = this.formatCode(code)
    }
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    startDate = Moment(startDate);
    startDate = startDate.format("YYYY-MM-DD") + "T00:00:00";

    endDate = Moment(endDate);
    endDate = endDate.format("YYYY-MM-DD") + "T00:00:00";

    let launchRequest: ISearchParams = {
      start_date: startDate as string,
      end_date: endDate as string,
      params: {
        page_size: this.props.search.params.params.page_size,
        page: 1,
        order_by: this.props.search.params.params.order_by
      },
      query: '',
      search_ad_data: this.state.searchAdDataInput,
      search_code: code,
      search_music_data: this.state.searchMusicDataInput,
      first_broadcast: this.state.firstBroadcast,
      media: types,
      country: this.props.search.params.country,
      bucket_id: bucket_id,
      music_states: [IMusicStateTypes.IDENTIFIED, IMusicStateTypes.NON_IDENTIFIED, IMusicStateTypes.NO_MUSIC],
      ad_type: this.state.adType
    }

    if (types.includes(EMediaTypes.Online)) {
      launchRequest.child_media_id = this.state.selectedSupportIds
    }
    //if (this.props.search.params.country === COUNTRY_OPTIONS.GERMANY) {
    if (this.state.searchMusicState) {
      const musicStates = this.state.searchMusicState.map((item: any) => IMusicStateTypes[item as any]) as any;
      launchRequest.music_states = musicStates
    }
    launchRequest.non_validated_spots = this.state.nonValidatedSpots;
    //}
    const locationPathName = this.props.router.location.pathname;

    if (locationPathName !== '/') {
      this.props.history.push('/');
    }

    this.props.launchSearch(launchRequest);
  }

  private drawFirstBroadcastMediaPlanSelect = () => {
    const { classes, layout } = this.props;
    const theme = getTheme(layout.theme);

    let mediaPlanOrFirstBroadcast = "mediaplan";
    if (this.state.firstBroadcast) {
      mediaPlanOrFirstBroadcast = "firstbroadcast"
    }

    return (
      <MD.Tabs
        style={{ marginTop: '20px' }}
        value={mediaPlanOrFirstBroadcast}
        onChange={this.handleFirstBroadcastChange.bind(this) as any}
        variant="standard"
        textColor="primary"
        indicatorColor="primary"
        aria-label="icon label tabs example"
      >
        <MD.Tab
          className={classes.tab}
          icon={
            <SettingsInputAntennaIcon style={{ marginBottom: 'auto', opacity: '70%' }} />}
          value="firstbroadcast"
          label={<span style={{ color: theme.palette.text.primary, opacity: '70%' }}>{this.props.i18n._('1st broadcast')}</span>}
        />
        {this.state.startDate && Moment(this.state.startDate).year() > 2018 &&
          <MD.Tab
            className={classes.tab}
            icon={<HistoryIcon style={{ marginBottom: 'auto', opacity: '70%' }} />}
            value="mediaplan"
            label={<span style={{ color: theme.palette.text.primary, opacity: '70%' }}>{this.props.i18n._('Diffusions')}</span>}
          />
        }
      </MD.Tabs>
    );
  }

  private handleMusicStateChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const field = event.target.name;

    let searchMusicState = this.state.searchMusicState ? [...this.state.searchMusicState] : [];
    if (value) {
      searchMusicState.push(field as any);
    } else {
      let index = searchMusicState.indexOf(field as any);
      searchMusicState.splice(index, 1);
    }
    this.setState({
      searchMusicState: [...searchMusicState]
    });
  }
  private handleAdTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    const field = event.target.value;
    let newState = { ...this.state };
    let adTypeState: any = this.state.isAllChecked ? [IAdType.NORMAL, IAdType.SPONSORING, IAdType.TRAILER, IAdType.ECRAN_PUBLICATAIRE, IAdType.SC] : [...this.state.adType];
    let isAllChecked: boolean = newState.isAllChecked;
    if (value) {
      if (field === 'TOUS') {
        adTypeState = undefined;
        isAllChecked = true;
      } else {
        isAllChecked = false;
        if (field === "EP") {
          ["N", "EP", "SC"].map((x: any) => {
            adTypeState.push(x)
            return adTypeState
          })
        } else {
          adTypeState.push(field as any);
        }
      }
    } else {
      if (field === 'TOUS') {
        adTypeState = [];
        isAllChecked = false;
      } else {
        isAllChecked = false;
        adTypeState = adTypeState.filter((x: any) => x !== "TOUS")
        if (field === "EP") {
          let adTypeStateCopy: any = []
          adTypeState.map((x: any) => {
            if (x !== "N" && x !== "EP" && x !== "SC") {
              adTypeStateCopy.push(x)
            }
            return adTypeStateCopy
          })
          adTypeState = adTypeStateCopy
        } else {
          let index = adTypeState.indexOf(field as any);
          adTypeState.splice(index, 1);
        }
      }
    }
    if (adTypeState && adTypeState.length > 0) {
      this.setState({
        adType: [...adTypeState],
        isAllChecked
      })
    } else {
      this.setState({
        adType: [],
        isAllChecked
      })
    }
  }

  private drawSearchMusicStateDropdown = () => {
    //if (this.props.search.params.country !== COUNTRY_OPTIONS.GERMANY) return null;

    const theme = getTheme(this.props.layout.theme);
    let statusList = Object.keys(IMusicStateTypes).filter((key: any) => !isNaN(Number(IMusicStateTypes[key])));

    return (
      <MD.FormControl>
        <MD.FormLabel component="legend" style={{ fontSize: "11px", marginTop: '20px' }}>{this.props.i18n._("Advertising Spots Display Options")}</MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }}>
          {statusList.map((value: any) => {
            const checked = this.state.searchMusicState !== undefined && this.state.searchMusicState.indexOf(value) > -1;
            return (<MD.MenuItem key={value} value={value} style={{ padding: 0 }}>
              <SmallCheckbox
                onChange={this.handleMusicStateChange}
                name={value}
                checked={checked}
              />
              <MD.ListItemText
                primaryTypographyProps={{ style: { fontSize: '12px', color: checked ? theme.palette.secondary.main : 'inherit' } }}
                primary={this.drawMusicStateOptionLabel(value)}
              />
            </MD.MenuItem>)
          })}
        </MD.Paper>
      </MD.FormControl>
    );
  }

  private drawMusicStateOptionLabel = (value: string) => {
    if (value === "IDENTIFIED") {
      return (<MD.Box style={{ display: 'flex', justifyContent: 'flexStart', flexDirection: 'row', alignItems: 'center' }}>
        {this.props.i18n._("identified")}
        <MusicNoteIcon style={{ fontSize: '23px', color: "#008000" }} />
      </MD.Box>)
    }

    if (value === "NON_IDENTIFIED") {
      return (<MD.Box style={{ display: 'flex', justifyContent: 'flexStart', flexDirection: 'row' }}>
        {this.props.i18n._('non identified')}
        <MusicNoteIcon style={{ fontSize: '23px', color: "#cc0c0c" }} />
      </MD.Box>)
    }
    else return <MD.Box>{this.props.i18n._('no music')}</MD.Box>
  }

  private drawInvalideSpotsCheckbox = () => {
    const { classes } = this.props;
    //if (this.props.search.params.country !== COUNTRY_OPTIONS.GERMANY) return null;

    return (
      <MD.Tooltip placement='top' title={<p>{this.props.i18n.gettext("Display spots that are not validated by operator")}</p>}>
        <MD.FormControl component="fieldset" style={{ marginTop: '10px', paddingLeft: 10 }}>
          <MD.FormGroup>
            <FormControlLabelSmall
              control={
                <SmallCheckbox
                  checked={this.state.nonValidatedSpots || false}
                  onChange={this.handleCheckboxChange}
                  inputProps={{ 'aria-label': 'primary checkbox', 'name': 'nonValidatedSpots' }}
                />}
              label={<MD.Box style={{ display: 'flex', justifyContent: 'flexStart', alignItems: 'center', flexDirection: 'row' }}>
                {this.props.i18n._("Non validated spots")}
                <span className={classes.reduceSvgIconSize} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flexStart', flexDirection: 'row' }} >
                  <SvgIcon component={InfoIcon} htmlColor={this.props.theme.palette.text.primary} />
                </span>
              </MD.Box>
              }
              style={{ fontSize: "12px" }}
            />
          </MD.FormGroup>
        </MD.FormControl>
      </MD.Tooltip>
    );
  }

  private translate(name: string) {
    switch (name) {
      case 'TOUS':
        return this.props.i18n._('TOUS');
      case 'EP':
        return this.props.i18n._('EP');
      default:
        return name
    }
  }
  private drawAdTypeCheckbox() {
    const theme = getTheme(this.props.layout.theme);
    return (
      <MD.FormControl>
        <MD.FormLabel component="legend" style={{ fontSize: "11px", marginTop: '20px' }}>
          {this.props.i18n._("Advertising Spots Types")}
        </MD.FormLabel>
        <MD.Paper style={{ marginTop: '10px' }}>
          {this.state.adType !== null && IAdtypes.map((item: any) => {
            const checked = this.state.isAllChecked || this.state.adType.length === 5 || this.state.adType.indexOf(item.value) > -1;
            return (<MD.MenuItem key={item.value} value={item.value} style={{ padding: 0 }}>
              <SmallCheckbox
                onChange={this.handleAdTypeChange}
                name={item.name}
                value={item.value}
                checked={checked}
              />
              <MD.ListItemText
                primaryTypographyProps={{ style: { fontSize: '12px', color: checked ? theme.palette.secondary.main : 'inherit' } }}
                primary={this.translate(item.name)?.toUpperCase()}
              />
            </MD.MenuItem>)
          })}
        </MD.Paper>
      </MD.FormControl>
    );
  }
  public render(): React.ReactNode {
    const { classes } = this.props;
    // if (!isNullOrUndefined(this.props.layout.drawerContent)) {
    return (
      <MD.Drawer
        anchor='left'
        classes={{
          paper: classes.drawerPaper,
        }}

        open={this.props.layout.showDrawer}
        variant='persistent'>
        {this.drawDatePickerForm()}
        {this.drawRadioTVSelect()}
        {this.drawFirstBroadcastMediaPlanSelect()}
        {!this.state.firstBroadcast && this.state.mediaTypeWeb && this.props.search.params.country === COUNTRY_OPTIONS.GERMANY && this.drawSelectSupport()}
        {this.drawSearchAdDataForm()}
        {this.drawSearchCodeForm()}
        {this.drawSearchMusicDataForm()}
        {/* {this.drawSearchMusicStateDropdown()}
                  {this.drawInvalideSpotsCheckbox()} */}
        {this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
          this.state.mediaTypeTV &&
          this.drawAdTypeCheckbox()}
        <ButtonDensed
          style={{ marginTop: '30px' }}
          className={classes.launchSearch}
          color='secondary'
          variant='contained'
          onClick={this.onLaunchSearchClick}
        >
          {this.props.i18n._('Launch search')}
        </ButtonDensed>
      </MD.Drawer>
    );
    // }
  }
}

const mapStateToProps = ({ i18n, layout, search, router }: IApplicationState) => ({
  i18n: i18n.i18n,
  localeInfos: i18n.localeInfos,
  layout,
  search,
  router
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  launchSearch: (searchParams: ISearchParams) => dispatch(launchSearch(searchParams)),
});

export const Drawer =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(DrawerComponent)));
