
import React, { Component } from 'react';
import * as MD from '@material-ui/core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Hls from 'hls.js';

// ICONS
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import { IApplicationState } from '../Store';
import { CustomTooltip } from '../Themes/StyledElements';
import { I18N } from '../Store/I18n/Types';
import { Config } from '../config';

const styles = MD.createStyles({
});

export interface IPropsFromState {
    i18n: I18N;
    token: string;
}
export interface IPropsFromDispatch {
}
export interface IPlayerProps {
    id: number;
    index: number;
    bucket_name: string;
    master: string;
    duration: any;
    currentTime: any;
    isPlaying: boolean;
    saveParams: (currentTime: any, index: number, duration?: any) => void;
    setPlayerParams: (duration: any) => void;
    togglePlayer: (isPlaying: boolean) => void;
}
export interface IAudioStreamPlayerState {
    isPlaying: boolean;
    currentTime: any;
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IPlayerProps;


export class AudioStreamPlayerComponent extends Component<AllProps, IAudioStreamPlayerState> {
    private hls: any;
    private audio: any;

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            isPlaying: props.isPlaying,
            currentTime: props.currentTime
        }
    }


    componentDidMount() {
        this.audio = document.getElementById(`audio-${this.props.id}`);
        if (this.audio) {
            this.audio.onloadedmetadata = () => {
                this.props.setPlayerParams(this.audio.duration)
            }
        }
        this.hls = new Hls({
            xhrSetup: xhr => {
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.props.token);
            },
        });

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (this.state.isPlaying && this.props.isPlaying) {
                this.audio.play();
            } else {
                this.audio.pause();
            }
        });

        this.hls.on(Hls.Events.ERROR, (event: any, data: any) => {
            switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                    // hls.startLoad();
                    break;

                case Hls.ErrorTypes.MEDIA_ERROR:
                    // hls.recoverMediaError();
                    break;

                default:
                    // hls.destroy();
                    break;
            }
        });
    }

    public loadPlayer = (bucket: string, file_name: string) => {
        this.hls.attachMedia(this.audio);
        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            const url = `${Config.StreamerUrl}?bucket=${bucket}&file=${file_name}`;
            this.hls.loadSource(url);
            this.props.saveParams(this.audio.currentTime, this.props.index, undefined);
            this.setState({ currentTime: this.audio.currentTime });
            this.hls.on(Hls.Events.FRAG_LOADING, setInterval((e: any) => {
                this.props.saveParams(this.audio.currentTime, this.props.index, this.audio.duration);
                if (this.audio.currentTime.toFixed(2) === this.audio.duration.toFixed(2)) {
                    this.audio.pause();
                    this.setState({ isPlaying: false })
                }
                if (!this.props.isPlaying) {
                    this.audio.pause();
                } else {
                    this.audio.play();
                }
            }, 1000));
        })
    }

    private togglePlayer = () => {
        if (this.audio && !this.audio.paused) {
            this.audio.pause();
            this.setState({ isPlaying: false, currentTime: this.audio.currentTime })
            this.props.togglePlayer(false)
            return;
        }
        if (this.state.currentTime === 0) {
            this.loadPlayer(this.props.bucket_name, this.props.master);
        }
        if (this.audio) {
            this.setState({ isPlaying: true, currentTime: this.audio.currentTime })
            this.props.saveParams(this.audio.currentTime, this.props.index, undefined)

            this.props.togglePlayer(true)
        }

    }

    public render() {
        return (
            <MD.IconButton
                style={{ padding: 0, marginLeft: 5 }}
                onClick={this.togglePlayer}>
                {this.props.isPlaying ?
                    <CustomTooltip title={this.props.i18n._("Pause master")}>
                        <PauseCircleOutlineOutlinedIcon style={{ cursor: 'pointer' }} />
                    </CustomTooltip> :
                    <CustomTooltip title={this.props.i18n._("Play master")}>
                        <PlayCircleOutlineOutlinedIcon style={{ cursor: 'pointer' }} />
                    </CustomTooltip>
                }
                <audio hidden id={`audio-${this.props.id}`} preload='auto'></audio>
            </MD.IconButton>
        )
    }
}

const mapStateToProps = ({ user, i18n }: IApplicationState) => ({
    i18n: i18n.i18n,
    token: user.token,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export const AudioStreamPlayer =
    connect(mapStateToProps, mapDispatchToProps)(
        MD.withStyles(styles)(AudioStreamPlayerComponent),
    );
