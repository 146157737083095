import * as effects from 'redux-saga/effects'

import { Backend } from '../../Backend/Backend'
import {
  BucketMedia,
  COUNTRY_OPTIONS,
  EMediaTypes,
  ISearchActionTypes,
  ISearchParams,
} from './Types'
import {
  getMediaWeb,
  getMediaWebError,
  getMediaWebSuccess,
  launchSearch,
  launchSearchError,
  launchSearchSuccess,
  paginateSearch,
  paginateSearchSuccess,
  setCountry,
} from './Actions'
import { getFormulaireList } from '../Formulaire/Actions'

function* handleGetMediaWeb(action: ReturnType<typeof getMediaWeb>) {
  const backend = Backend.getInstance()
  try {
    const res = yield effects.apply(backend, backend.getMediaWeb, [])
    if (res.status_code === 200 && res.results) {
      yield effects.put(getMediaWebSuccess(res.results))
    }
  } catch (err) {
    yield effects.put(getMediaWebError(err))

  }
}

function* handleLaunchSearch(action: ReturnType<typeof launchSearch>) {
  const backend = Backend.getInstance()
  try {
    const results = yield effects.apply(backend, backend.launchSearch, [
      action.payload,
    ])
    if (
      results.status_code >= 400 ||
      results.status_code === '400' ||
      results.status_code === '401'
    ) {
      yield effects.put(launchSearchError())
      alert(results.message)
    } else {
      yield effects.put(launchSearchSuccess(results))
    }
  } catch (err) {
    yield effects.put(launchSearchError())

    alert(err)
  }
}

function* handleSetCountry(action: ReturnType<typeof setCountry>) {
  let route = yield effects.select((state) => state.router.location.pathname)
  if (route === '/declarations') {
    let limit = yield effects.select((state) => state.formulaireList.limit)
    yield effects.put(getFormulaireList(1, limit))
  } else if (route === '/') {
    let params: ISearchParams = yield effects.select(
      (state) => state.search.params
    )
    let country = action.payload
    params.country = country
    if (country === COUNTRY_OPTIONS.GERMANY) {
      if (params.media.includes(EMediaTypes.TV)) {
        params.bucket_id = BucketMedia.DE_TV
      } else if (params.media.includes(EMediaTypes.RADIO)) {
        params.bucket_id = BucketMedia.DE_RADIO
      }
    } else if (country === COUNTRY_OPTIONS.FRANCE) {
      if (
        params.media.includes(EMediaTypes.TV) ||
        params.media.includes(EMediaTypes.Online)
      ) {
        params.media = [EMediaTypes.TV]
        params.bucket_id = BucketMedia.FR_TV
      } else if (params.media.includes(EMediaTypes.RADIO)) {
        params.bucket_id = BucketMedia.FR_RADIO
      }
    }
    yield effects.put(launchSearch(params))
  }
}

function* handlePaginateSearch(action: ReturnType<typeof paginateSearch>) {
  try {
    const backend = Backend.getInstance()
    let params = yield effects.select((state) => state.search.params)

    const results = yield effects.apply(backend, backend.launchSearch, [params])
    yield effects.put(paginateSearchSuccess(results))
  } catch (err) {

  }
}

function* watchSearch() {
  yield effects.takeEvery(ISearchActionTypes.LAUNCH_SEARCH, handleLaunchSearch)
  yield effects.takeEvery(ISearchActionTypes.SET_COUNTRY, handleSetCountry)
  yield effects.takeEvery(
    ISearchActionTypes.PAGINATE_SEARCH,
    handlePaginateSearch
  )
  yield effects.takeEvery(ISearchActionTypes.GET_MEDIA_WEB, handleGetMediaWeb)
}

export function* SearchSaga() {
  yield effects.all([effects.fork(watchSearch)])
}
