import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import FlagIconFactory from 'react-flag-icon-css';
import { withTheme } from '@material-ui/styles';

import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../Store';
import { Locales } from '../Store/I18n/Constants';
import { I18N, ILocaleInfos } from '../Store/I18n/Types';
import { Config } from '../config';
import { TypedStorage } from '../Utils/TypedStorage';
import { setCountry } from '../Store/Search/Actions';
import { AdtrackLogoIcon } from '../Themes/svg/adtrack-logo';
import { YacastLogoIcon } from '../Themes/svg/yacast-logo';

import { ILayoutState } from '../Store/Layout/Types';
import { IUserState } from '../Store/User/Types';

//ACTIONS
import { setLocale } from '../Store/I18n/Actions';
import {
    hideDrawer,
    showDrawer,
    toggleTheme
} from '../Store/Layout/Actions';
import { logout } from '../Store/User/Actions';

// ICONS
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

import { PaletteMode } from '../Themes';
import { COUNTRY_OPTIONS, ISearchState } from '../Store/Search/Types';
import { withStyles, WithStyles } from '@material-ui/styles';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface IOwnState {
    localeMenuAnchor: HTMLElement | null;
    localeMenuOpen: boolean;
    userMenuAnchor: HTMLElement | null;
    userMenuOpen: boolean;
    productMenuAnchor: HTMLElement | null;
    productMenuOpen: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    search: ISearchState;
    router: RouteComponentProps<{}>;
    user: IUserState;
    width: string;
}

interface IPropsFromDispatch {
    hideDrawer: typeof hideDrawer;
    logout: typeof logout;
    setLocale: typeof setLocale;
    showDrawer: typeof showDrawer;
    toggleTheme: typeof toggleTheme;
    setCountry: typeof setCountry;
}
interface IPropsTheme {
    theme: any;
}

type AllProps =
    WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IPropsTheme &
    IConnectedReduxProps;


const initialState = {
    localeMenuAnchor: null,
    localeMenuOpen: false,
    userMenuAnchor: null,
    userMenuOpen: false,
    productMenuAnchor: null,
    productMenuOpen: false,
}

const styles = (theme: any) => MD.createStyles({
    appBar: {
        left: theme.Adtrack.dimensions.drawerWidth,
        backgroundColor: theme.palette.background.default,
        height: theme.Adtrack.dimensions.appBarHeight,
        position: 'fixed',
        width: "unset",
        zIndex: theme.zIndex.drawer - 1,
        "& >.MuiToolbar-regular": {
            minHeight: '50px'
        }
    },
    appBarFull: {
        left: 0,
        backgroundColor: theme.palette.background.default,
        height: theme.Adtrack.dimensions.appBarHeight,
        position: 'fixed',
        zIndex: theme.zIndex.drawer - 1,
        "& >.MuiToolbar-regular": {
            minHeight: '50px'
        }
    },
    barTitle: {
        color: 'inherit',
    },
    button: {
    },
    hamburger: {
        marginLeft: -12,
        marginRight: '-25px',
    },
    language: {
        fontSize: '14px',
        color: theme.palette.text.primary
    },
    logo: {
        width: '100%',
    },
    menuFlags: {
        marginRight: theme.spacing.toString(),
    },
    menuItems: {
        display: 'block',
        flexGrow: 1,
        "> span": {
            fontSize: '16px',
        }
    },
    menus: {
        display: 'inline-flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
    },
    myDeclarationsText: {
        fontSize: '12px'
    },
    logoStyle: {
        fontSize: 'x-large',
        textTransform: 'capitalize',
        marginLeft: '5px',
        marginRight: '15px',
        padding: '13px 24px',
        fontWeight: 500
    },
    profileStyle: {
        padding: 0,
        color: theme.palette.text.primary,
    },
    selectCountry: {
        display: 'block',
        textAlign: 'end',
        '& select': {
            textTransform: 'capitalize'
        }
    },
    tabItem: {
        '&:hover': {
            color: theme.palette.text.secondary,
        },
        color: theme.palette.text.primary,
        opacity: 0.7,
        transition: theme.transitions.create('color'),
        fontSize: '13px',
        minHeight: '38px'
    },
    tabItemIcon: {
        fontSize: '19px'
    },
    tabs: {
        marginLeft: 0,
        width: '100%',
        minHeight: '38px'
    },
    tabsIndicator: {
        background: 'none'
    },
    profileIcon: {
        fontSize: '19px'
    },
    searchButton: {
        paddingTop: '0px',
        paddingBottom: '0px',
        marginLeft: '10px'
    },
    switchTheme: {
        width: 0,
        //left: 103,
        top: 30,
        borderRadius: 7
    },
});

export class AppBarComponent extends React.Component<AllProps, IOwnState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    private setCountry = (event: any) => {
        this.props.setCountry(event.target.value);
    }

    public render(): React.ReactNode {
        const { classes, theme } = this.props;
        let currentPath = '/';
        const pathParts = this.props.router.location.pathname.split('/');
        let logoColor = '#FFFFFF';
        if (theme.palette.type === PaletteMode.LIGHT) {
            logoColor = theme.palette.text.primary;
        }

        switch ((pathParts.length > 1) ? pathParts[1] : '') {
            case '':
            case 'search':
                currentPath = "/";
                break;
            case 'declarations':
                currentPath = `/${pathParts[1]}`;
                break;
            case 'details':
                currentPath = "/";
                break;
            default:
                currentPath = "none";
        }
        this.props.user.userInfo.localisation = this.props.user.userInfo.localisation?.filter((x: any) => x !== "Array") as any
        return (
            <MD.AppBar
                className={this.props.layout.showDrawer ? classes.appBar : classes.appBarFull}
            >
                <MD.Toolbar>
                    {/* Hamburger */}
                    <MD.IconButton
                        className={classes.hamburger}
                        onClick={this.toggleDrawer.bind(this)}
                    >
                        <MD.Icon color='primary'>
                            <MoreVertIcon />
                        </MD.Icon>
                    </MD.IconButton>
                    {/* /Hamburger */}
                    {/* Logo */}

                    <MD.Box
                        // aria-owns={open ? 'menu-locale' : undefined}
                        className={classes.logoStyle}
                        aria-haspopup='true'
                        color='secondary'
                        onClick={this.handleProductMenuOpen.bind(this) as any}
                    // size='small'
                    >
                        <SvgIcon component={AdtrackLogoIcon} htmlColor={logoColor} />
                        {/* <img src={} alt="AdTrack" /> */}
                    </MD.Box>

                    {/* Yacast Products menu */}
                    {/* <MD.Menu
                        anchorEl={this.state.productMenuAnchor}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        className={classes.menus}
                        id='menu-product'
                        onClose={this.handleMenuClose.bind(this) as any}
                        open={this.state.productMenuOpen}
                    >
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onProductChange.bind(this, 'mytrack')}
                            variant='text'
                        >
                            <span style={{color: theme.palette.secondary.main}}>My</span>Track
                        </MD.Button>
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onProductChange.bind(this, 'progtrack')}
                            variant='text'
                        >
                            <span style={{color: theme.palette.secondary.main}}>Prog</span>Track
                        </MD.Button>
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onProductChange.bind(this, 'righttrack')}
                            variant='text'
                        >
                            <span style={{color: theme.palette.secondary.main}}>Right</span>Track
                        </MD.Button>
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onProductChange.bind(this, 'speechtrack')}
                            variant='text'
                        >
                            <span style={{color: theme.palette.secondary.main}}>Speech</span>Track
                        </MD.Button>

                    </MD.Menu> */}
                    {/* /Locale menu */}

                    {/* /Logo */}
                    {/* Top links */}
                    <MD.Tabs
                        className={classes.tabs}
                        onChange={this.onMenuTabChange.bind(this) as any}
                        indicatorColor="primary"
                        TabIndicatorProps={{ className: classes.tabsIndicator }}
                        textColor="primary"
                        value={currentPath}
                    >
                        <MD.Tab
                            className={classes.tabItem}
                            label={
                                (this.props.width === 'xs') ?
                                    <SearchIcon className={classes.tabItemIcon} /> :
                                    (<MD.Grid alignItems="center" container>
                                        {this.props.i18n._('Search')}
                                    </MD.Grid>)
                            }
                            value={'/'}
                        />
                        <MD.Tab
                            className={classes.tabItem}
                            label={
                                (this.props.width === 'xs') ?
                                    <AssignmentIcon className={classes.tabItemIcon} /> :
                                    (<MD.Grid alignItems="center" container>
                                        {this.props.i18n._('My declarations')}
                                    </MD.Grid>)
                            }
                            value={'/declarations'}
                        />
                        <MD.Tab
                            className={classes.tabItem}
                            label={
                                (this.props.width === 'xs') ?
                                    <AssignmentIcon className={classes.tabItemIcon} /> :
                                    (<MD.Grid alignItems="center" container>
                                        {this.props.i18n._('Catalog Manager')}
                                    </MD.Grid>)
                            }
                            onClick={() => window.open('https://catalog-manager-stag.yacast.fr/', "_blank")}
                        />
                        <MD.Tab
                            className={classes.tabItem}
                            style={{ display: 'none' }}
                            value={'none'}
                        />

                    </MD.Tabs>
                    {/* /Product logo */}
                    <MD.Grid style={{ paddingRight: 10, display: 'inline-flex' }} >
                        <MD.Grid item xs={6} style={{ textAlign: 'right', paddingBottom: "20px" }}>
                            <SvgIcon component={YacastLogoIcon} htmlColor={logoColor} />
                        </MD.Grid>
                        {this.props.search.params.country === COUNTRY_OPTIONS.GERMANY &&
                            <MD.Grid item xs={6} style={{ marginTop: "20px", marginLeft: '50px' }}>
                                <img style={{ height: '15px' }} src={this.props.layout.theme === PaletteMode.LIGHT ? "/Nielsen-Logo.png" : "/Nielsen-Logo-White.png"} alt="" />
                                {/* <SvgIcon component={NielsenLogoIcon} htmlColor={logoColor}/> */}
                            </MD.Grid>
                        }
                    </MD.Grid>
                    {/* /Top links */}
                    <div id="user-menu" style={{ display: 'inline-flex', paddingLeft: 10 }}>
                        <MD.FormControl
                            className={classes.selectCountry}
                        >
                            <MD.Select
                                value={this.props.search.params.country}
                                name="country"
                                onChange={this.setCountry}
                                inputProps={{ 'aria-label': 'country' }}
                            >
                                {this.props.user.userInfo.localisation?.includes('FR') &&
                                    <MD.MenuItem
                                        value={this.props.user.userInfo.localisation?.includes('FR') ? COUNTRY_OPTIONS.FRANCE : ''}>
                                        {this.props.i18n._(COUNTRY_OPTIONS.FRANCE).toUpperCase()}
                                    </MD.MenuItem>
                                }
                                {this.props.user.userInfo.localisation?.includes('DE') &&
                                    <MD.MenuItem
                                        value={this.props.user.userInfo.localisation?.includes('DE') ? COUNTRY_OPTIONS.GERMANY : ''}>
                                        {this.props.i18n._(COUNTRY_OPTIONS.GERMANY).toUpperCase()}
                                    </MD.MenuItem>
                                }
                            </MD.Select>
                        </MD.FormControl>
                        {/* User icon */}
                        <MD.IconButton
                            aria-haspopup='true'
                            className={classes.profileStyle}
                            onClick={this.handleUserMenuOpen.bind(this) as any}
                        >
                            <AccountCircleIcon />
                        </MD.IconButton>
                        {/* /User icon */}
                        {/* Locale icon */}
                        <MD.IconButton
                            // aria-owns={open ? 'menu-locale' : undefined}
                            className={classes.language}
                            aria-haspopup='true'
                            onClick={this.handleLocaleMenuOpen.bind(this) as any}
                            size='small'
                        >
                            <FlagIcon code={this.props.localeInfos.iconName} />
                        </MD.IconButton>
                        {/* /Locale icon */}
                        {/* User menu */}
                        <MD.Menu
                            anchorEl={this.state.userMenuAnchor}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                            className={classes.menus}
                            id='menu-user'
                            transformOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            onClose={this.handleMenuClose.bind(this) as any}
                            open={this.state.userMenuOpen}
                        >
                            <MD.Button
                                className={classes.menuItems}
                                component='a'
                                title={this.props.i18n._('My account')}
                                variant='text'
                            >
                                {this.props.user.username}
                            </MD.Button>

                            <MD.Button
                                className={classes.menuItems}
                                component='a'
                                onClick={this.handleLogout.bind(this) as any}
                                title={this.props.i18n._('Log out my session')}
                                variant='text'
                            >
                                {this.props.i18n._('Logout')}
                            </MD.Button>
                            <MD.Button
                                className={classes.menuItems}
                                component='a'
                                onClick={this.handleUpdatePwd.bind(this) as any}
                                title={this.props.i18n._('Update my password')}
                                variant='text'
                            >
                                {this.props.i18n._('Update my password')}
                            </MD.Button>
                        </MD.Menu>
                        {/* /User menu */}
                        {/* Locale menu */}
                        <MD.Menu
                            anchorEl={this.state.localeMenuAnchor}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                            className={classes.menus}
                            id='menu-locale'
                            transformOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            onClose={this.handleMenuClose.bind(this) as any}
                            open={this.state.localeMenuOpen}
                        >
                            <MD.Button
                                className={classes.menuItems}
                                onClick={this.onLocaleChange.bind(this, Locales.French)}
                                variant='text'
                            >
                                <span style={{ paddingRight: 8 }}>FR</span>
                                <span style={{ textTransform: 'lowercase', paddingRight: 8 }}>{this.props.i18n._("Switch to French")}</span>
                                <FlagIcon code={'fr'} />
                            </MD.Button>
                            <MD.Button
                                className={classes.menuItems}
                                onClick={this.onLocaleChange.bind(this, Locales.English)}
                                variant='text'
                            >
                                <span style={{ paddingRight: 8 }}>GB</span>
                                <span style={{ textTransform: 'lowercase', paddingRight: 8 }}>{this.props.i18n._('Switch to English')}</span>
                                <FlagIcon code={'gb'} />
                            </MD.Button>
                            <MD.Button
                                className={classes.menuItems}
                                onClick={this.onLocaleChange.bind(this, Locales.Germain)}
                                variant='text'
                            >
                                <span style={{ paddingRight: 8 }}>DE</span>
                                <span style={{ textTransform: 'lowercase', paddingRight: 8 }}>{this.props.i18n._("Switch to German")}</span>
                                <FlagIcon code={'de'} />
                            </MD.Button>
                        </MD.Menu>
                        {this.buildSwitchButton()}
                        {/* /Locale menu */}
                    </div>
                </MD.Toolbar>
            </MD.AppBar>
        );
    }


    private buildSwitchButton() {
        const { classes, theme, layout } = this.props;

        return (
            <MD.IconButton
                className={classes.switchTheme + classes.searchButton}
                style={{ margin: 0, padding: 0 }}
                onClick={this.props.toggleTheme}
            >
                {layout.theme === PaletteMode.DARK ?
                    <ToggleOnIcon style={{ fill: theme.palette.secondary.main, fontSize: 'xxx-large' }} /> :
                    <ToggleOffIcon style={{ fontSize: 'xxx-large' }} />
                }
            </MD.IconButton>
        )
    }

    private handleUserMenuOpen(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({ userMenuAnchor: ev.target as HTMLElement, userMenuOpen: true });
    }

    private handleLocaleMenuOpen(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({ localeMenuAnchor: ev.target as HTMLElement, localeMenuOpen: true });
    }


    private handleMenuClose(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({ ...initialState });
    }

    private handleProductMenuOpen(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({ productMenuAnchor: ev.target as HTMLElement, productMenuOpen: true });
    }

    private onProductChange(product: string) {
        this.setState({
            ...initialState
        });
        if (product === 'mytrack') {
            window.open("http://192.168.12.102/", "_self")
        } else if (product === 'progtrack') {
            window.open("https://progtrack.yacast.fr/", "_self")
        } else if (product === 'righttrack') {
            window.open("https://righttrack.yacast.fr/", "_self")
        } else if (product === 'adtrack') {
            window.open("https://adtrack.yacast.fr/", "_self")
        } else if (product === 'speechtrack') {
            window.open("https://speechtrack.yacast.fr/", "_self")
        }
    }

    private handleLogout(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.logout();
    }

    private handleUpdatePwd(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        let token = TypedStorage.get('userToken', '');
        let user_id = this.props.user.userInfo.id;
        window.open(`${Config.UsersUrl}/new_password/?token=${token}&id=${user_id}`)
    }

    private onMenuTabChange(ev: MouseEvent, value: string) {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.target === null) {
            return;
        }
        this.props.history.push(value);
    }

    private toggleDrawer() {
        if (this.props.layout.showDrawer) {
            this.props.hideDrawer();
            return;
        }
        this.props.showDrawer();
    }

    private onLocaleChange(locale: Locales) {
        this.setState({
            ...initialState
        });
        this.props.setLocale(locale);
    }
}

const mapStateToProps = ({ i18n, layout, user, search }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    layout,
    localeInfos: i18n.localeInfos,
    router: ownProps,
    search,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideDrawer: () => dispatch(hideDrawer()),
    logout: () => dispatch(logout()),
    setLocale: (locale: Locales) => dispatch(setLocale(locale)),
    showDrawer: () => dispatch(showDrawer()),
    toggleTheme: () => dispatch(toggleTheme()),
    setCountry: (country: COUNTRY_OPTIONS) => dispatch(setCountry(country)),
});

export const AppBar =
    connect(mapStateToProps, mapDispatchToProps)(
        withTheme((withStyles(styles)(AppBarComponent)))
    );
