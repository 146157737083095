import * as MD from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../Store';
import { ILayoutState } from '../Store/Layout/Types';

import { AppBar } from './AppBar';
import { NotificationBar } from './NotificationBarComponent';
import { Drawer } from './Sidebar/Drawer';


interface IPropsFromState {
  layout: ILayoutState;
  ui: any;
  updateUI: (name: string, value: any) => void;
}

type AllProps =
  WithStyles<typeof styles> &
  IPropsFromState &
  RouteComponentProps<{}> &
  IConnectedReduxProps;

const styles = (theme: any) => MD.createStyles({
  container: {
    height: '100vh',
  },
  content: {
    '&.noDrawer': {
      paddingLeft: theme.spacing(1),
    },
    flexGrow: 1,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.Adtrack.dimensions.drawerWidth + theme.spacing(1),
    paddingTop: theme.Adtrack.dimensions.appBarHeight + theme.spacing(1),
    transition: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    maxHeight: '100vh',
  },
  toolbar: theme.mixins.toolbar,
  listGridContainer: {
    margin: '15px'
  },
  listItemContainer: {
    border: '1px solid #cccccc',
    padding: '5px',
    borderRadius: '5px',
  }
});

interface IOwnState {
  isDisconnected: boolean;
}


export class LayoutComponent extends React.Component<AllProps, IOwnState> {
  public constructor(props: AllProps) {
    super(props);
    this.state = {
      isDisconnected: false,
    };
  }

  public componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }


  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      if (this.state.isDisconnected) {
        return this.setState({ isDisconnected: false });
      }
      return;
    }
    if (!this.state.isDisconnected) {
      return this.setState({ isDisconnected: true });
    }
  }

  private showNoticeIfDisconnected = () => {
    if (this.state.isDisconnected) {
      return (
        <div style={{ background: "red", zIndex: 10000, position: "absolute", width: "100%", textAlign: "center", opacity: "0.5" }}>
          Pas de réseaux
        </div>)
    }
  }
  public render(): React.ReactNode {
    const { classes } = this.props;

    return (
      <MD.Grid className={classes.container} id='fffff' container>
        {this.showNoticeIfDisconnected()}
        <Route component={AppBar} />
        <Route component={Drawer} />
        <MD.Grid
          container
          className={classes.content + (
            (this.props.layout.showDrawer) ?
              '' :
              ' noDrawer'
          )}
          direction='row'
        >
          {this.props.layout.pageContent}
          <NotificationBar />
        </MD.Grid>
      </MD.Grid>
    );
  }
}

const mapStateToProps = ({ layout }: IApplicationState) => ({
  layout,
});

export const Layout =
  connect(mapStateToProps)(
    withStyles(styles)(LayoutComponent),
  );
