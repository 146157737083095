import * as MD from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Moment from 'moment'
import { RouterState } from 'connected-react-router'
import { Route } from 'react-router-dom'

import { getTheme } from '../../Themes'
import { I18N } from '../../Store/I18n/Types'
import { IApplicationState } from '../../Store'

//Icons
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddIcon from '@material-ui/icons/Add'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import EditIcon from '@material-ui/icons/Edit'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import InfoIcon from '@material-ui/icons/Info'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SaveIcon from '@material-ui/icons/Save'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'

//Router
import {
  DensedTextField,
  DarkRadio,
  FormControlLabelSmall,
  DarkCheckbox,
  FormControlLabelTopLeftPlacement,
  FormControlLabelMedium,
  DensedTextFieldDark,
  TextFieldGema,
  TableHeaderCellGema,
  TableCellGema,
  NativeSelectGema,
  TableRowGema,
  TextFieldGemaNoBackground,
} from '../../Themes/StyledElements'
import {
  IFormulaireState,
  IMusicInfo,
  IRightHolders,
  IRole,
  CRightHolders,
  CMusicInfoInitial,
  CSocietyInfoInitial,
  CMusicInfoGemaInitial,
  IMusicInfoGema,
  CMusicGemaType,
  IFormulaireFranceState,
  IFormulaireGemaState,
  initialMusicInfoGema,
  IMedia,
} from '../../Store/Formulaire/Types'
import {
  saveFormulaire,
  fetchFormulaire,
  getFormulairePreview,
} from '../../Store/Formulaire/Actions'
import { IFingerprintBucketPair } from '../../Store/SpotDetails/Types'
import { COUNTRY_OPTIONS, ISearchState } from '../../Store/Search/Types'
import withStyles, { WithStyles } from '@material-ui/styles/withStyles'
import { withTheme } from '@material-ui/styles'
import { IUserState } from '../../Store/User/Types'
import {
  isNullOrUndefinedOrEmptyString
} from '../../Utils/Various'

const theme = getTheme()

const styles = MD.createStyles({
  titleGema: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#ea0b0b',
    marginTop: '10px',
  },
  subTitleGema: {
    color: '#ea0b0b',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  formHelperText: {
    color: 'black',
  },
  changeRightHolders: {
    fontSize: '30px',
    color: '#757575',
  },
  darkTextField: {
    border: '1px solid #ced4da',
    marginTop: '5px',
    '& input': {
      color: '#000',
    },
  },
  declarationPaper: {
    backgroundColor: '#ffffff',
    color: '#343a40',
    fontFamily: 'Quattrocento Sans, sans-serif !important',
    padding: '40px',
    width: '100%',
    maxWidth: '1000px',
    marginTop: '45px',
  },
  darkBorderTextField: {
    border: '1px solid #ced4da',
    color: '#000',
    '& input': {
      color: '#000',
    },
  },
  darkHelperText: {
    '& p': {
      color: '#868e96',
      marginLeft: '0',
    },
  },
  deposantBox: {
    border: '1px solid #e9ecef!important',
    padding: '5px',
  },
  headerSubtitle: {
    fontSize: '13px',
    marginRight: '7px',
  },
  headerTitle: {
    borderBottom: '2px solid #CCC',
    fontSize: '23px',
    fontFamily: 'Quattrocento Sans, sans-serif !important',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  infoNotice: {
    marginTop: '35px',
  },
  label: {
    fontSize: '14px',
    color: '#212529',
  },
  noticeIcon: {
    fontSize: '13px',
    marginRight: '6px',
  },
  productPaper: {
    marginTop: '10px',
    padding: '15px 10px',
    backgroundColor: '#868e96',
  },
  rightHoldersSection: {
    marginTop: 0,
    paddingTop: '15px',
  },
  sacemAddressBox: {
    paddingLeft: '60px',
  },
  sacemLogo: {
    width: '130px',
    height: '66px',
  },
  gemaLogo: {
    height: '66px',
  },
  sectionHeader: {
    backgroundColor: '#868e96',
    color: '#f8f9fa',
    fontWeight: 'bold',
    marginBottom: '-23px',
    marginTop: '20px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '10px',
    zIndex: 200,
    position: 'relative',
  },
  sectionHelperText: {
    marginTop: '8px',
    fontSize: '10px',
  },
  sectionHelperTextIndex: {
    fontSize: '8px',
    marginTop: '-4px',
    display: 'inine-block',
    top: '-3px',
    position: 'relative',
  },
  smallFont: {
    fontSize: '13px !important',
  },
  spotPaper: {
    marginTop: '20px',
    padding: '15px 10px',
    backgroundColor: '#f8f9fa',
    color: '#212529',
    border: '1px solid #eee',
  },
  statusValidated: {
    float: 'right',
    color: '#28a745!important',
  },
  statusDraft: {
    float: 'right',
    color: theme.Adtrack.colors.accent,
  },
  textarea: {
    '& p': {
      color: '#868e96',
      marginLeft: '0',
    },
    '& .MuiInputBase-root': {
      paddingLeft: 0,
    },
  },
  labelInside: {
    fontSize: '9px',
    fontWeight: 800,
    marginBottom: '-20px',
    marginLeft: '-10px',
    fontFamily: 'sans-serif',
    marginRight: '20px',
  },
  labelCheckbox: {
    fontSize: '15px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: 'black',
  },
  subLabelCheckbox: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: 'black',
  },
})

interface IPropsFromState {
  i18n: I18N
  formulaire: IFormulaireState
  search: ISearchState
  user: IUserState
  router: RouterState
}

interface IPropsFromDispatch {
  fetchFormulaire: typeof fetchFormulaire
  saveFormulaire: typeof saveFormulaire
  getFormulairePreview: typeof getFormulairePreview
}

interface FormulaireComponentProps {
  fingerprintBucketList: IFingerprintBucketPair[]
  fgpId: number
  bucketId: number
}
interface IPropsTheme {
  theme: any
}

type AllProps = WithStyles<typeof styles> &
  IPropsFromState &
  IPropsTheme &
  FormulaireComponentProps &
  IPropsFromDispatch

interface IState {
  formulaire: IFormulaireState
  is_editing: boolean
  warningMessage: string | null
  openDialog: boolean
  openConfirmDownload: boolean
  dialogTitle: string
  dialogContent: string
  sendFormulaire: boolean
  value: string
  identity_confirmed: boolean
}

export class FormulaireComponent extends React.Component<AllProps, IState> {
  public constructor(props: AllProps) {
    super(props)
    this.state = {
      formulaire: {
        ...props.formulaire,
        // bucket_id: this.props.bucketId
      },
      is_editing: false,
      warningMessage: null,
      openDialog: false,
      openConfirmDownload: false,
      dialogTitle: '',
      dialogContent: '',
      sendFormulaire: false,
      value: '',
      identity_confirmed: false,
    }
  }

  public componentDidMount() {
    let fingerprintBucketList: IFingerprintBucketPair[] = []
    if (this.props.fingerprintBucketList) {
      fingerprintBucketList = this.props.fingerprintBucketList
    } else if (this.props.bucketId && this.props.fgpId) {
      fingerprintBucketList.push({
        bucket_id: this.props.bucketId,
        fgp_id: this.props.fgpId,
      })
    }
    this.props.fetchFormulaire(fingerprintBucketList)
    //this.props.getFormulairePreview("", this.props.bucketId, this.props.fgpId, fingerprintBucketList);
  }

  static getDerivedStateFromProps(props: AllProps, state: IState) {
    const country = props.search.params.country as COUNTRY_OPTIONS
    if (!state.is_editing) {
      state.formulaire = props.formulaire
      if (
        country === COUNTRY_OPTIONS.FRANCE &&
        !state.formulaire[COUNTRY_OPTIONS.FRANCE].hasOwnProperty('society')
      ) {
        state.formulaire[COUNTRY_OPTIONS.FRANCE].society = {
          ...CSocietyInfoInitial,
        }
      }
    }

    if (
      country === COUNTRY_OPTIONS.FRANCE &&
      !state.formulaire[country].master_filepath &&
      state.formulaire[country].master_filepath === ''
    ) {
      state.warningMessage = props.i18n._('Attention! Master filepath is empty')
    } else {
      state.warningMessage = null
    }

    return state
  }

  private onMusicGemaChange = (
    key: number,
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
    >,
    opt: any = null
  ) => {
    event.preventDefault()
    event.stopPropagation()
    let value: any
    if (!opt) {
      value = event.target.value
    } else {
      value = opt
    }
    const field = event.target.name
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire: IFormulaireState = { ...this.state.formulaire }

      ; (formulaire[country].music[key] as any)[field] = value

    this.setState({
      is_editing: true,
      formulaire,
    })
  }

  private onCheckBoxFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const field = event.target.name
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire: IFormulaireState = { ...this.state.formulaire }

      ; (formulaire[country] as any)[field] = value

    this.setState({
      is_editing: true,
      formulaire,
    })
  }

  private onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    const field = event.target.name
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire: IFormulaireState = { ...this.state.formulaire }
    if (field === 'media') {
      const media: IMedia = {
        ...(this.state.formulaire[country] as any).media,
        type: value,
      }
        ; (formulaire[country] as any)['media'] = media
    }
    if (field === 'diffuser') {
      const media = {
        ...(this.state.formulaire[country] as any).media,
        name: value,
      }
        ; (formulaire[country] as any)['diffuder'] = media.name
    } else {
      ; (formulaire[country] as any)[field] = value
    }

    this.setState({
      is_editing: true,
      formulaire: formulaire,
    })
  }

  private onMusicInfoChange = (
    musicIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    const field = event.target.name
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire = { ...this.state.formulaire }
    let music = [...formulaire[country].music]
    music[musicIndex] = { ...music[musicIndex], [field]: value }

    formulaire[country].music = music as any

    this.setState({
      is_editing: true,
      formulaire: formulaire,
    })
  }

  private onRightHolderChange = (
    musicIndex: number,
    rhIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    const field = event.target.name
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire = { ...this.state.formulaire }
    let music = [...formulaire[country].music] as IMusicInfo[]
    music[musicIndex] = {
      ...formulaire[country].music[musicIndex],
    } as IMusicInfo
    music[musicIndex].right_holders = [
      ...(formulaire[country].music[musicIndex] as IMusicInfo).right_holders,
    ]
    music[musicIndex].right_holders[rhIndex] = {
      ...music[musicIndex].right_holders[rhIndex],
      [field]: value,
    }

    formulaire[country].music = music

    this.setState({
      is_editing: true,
      formulaire: formulaire,
    })
  }

  private onRightHolderRoleChange = (
    musicIndex: number,
    rhIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const checked = event.target.checked
    const field = event.target.name as IRole
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    let formulaire = { ...this.state.formulaire }
    let music = [...formulaire[country].music] as IMusicInfo[]
    music[musicIndex] = {
      ...formulaire[country].music[musicIndex],
    } as IMusicInfo
    music[musicIndex].right_holders = [
      ...(formulaire[country].music[musicIndex] as IMusicInfo).right_holders,
    ]
    music[musicIndex].right_holders[rhIndex] = {
      ...(formulaire[country].music[musicIndex] as IMusicInfo).right_holders[
      rhIndex
      ],
    }
    music[musicIndex].right_holders[rhIndex].roles = [
      ...(formulaire[country].music[musicIndex] as IMusicInfo).right_holders[
        rhIndex
      ].roles,
    ]
    if (checked) {
      music[musicIndex].right_holders[rhIndex].roles.push(field)
    } else {
      const index =
        music[musicIndex].right_holders[rhIndex].roles.indexOf(field)
      music[musicIndex].right_holders[rhIndex].roles.splice(index, 1)
    }

    formulaire[country].music = music

    this.setState({
      is_editing: true,
      formulaire: formulaire,
    })
  }

  private drawHeaderGema = () => {
    const { classes } = this.props
    return (
      <MD.Grid container justifyContent="flex-end" direction="row">
        <MD.Grid item xs={2}>
          <img
            style={{ float: 'right' }}
            className={classes.gemaLogo}
            src="/logo-gema.png"
            alt=""
          />
        </MD.Grid>
      </MD.Grid>
    )
  }
  private onSubHeaderChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault()
    event.stopPropagation()
    const value = event.target.value
    this.setState({ value })
  }
  private drawSubHeaderGema = () => {
    return (
      <MD.Grid
        container
        justifyContent="flex-end"
        direction="row"
        style={{ marginTop: '30px' }}
      >
        <MD.Grid item style={{ width: '300px' }}>
          <TextFieldGema
            value={
              this.state.formulaire[COUNTRY_OPTIONS.GERMANY].gema_av_number
            }
            name="gema_av_number"
            fullWidth
            variant="outlined"
            onChange={this.onFieldChange.bind(this)}
            InputProps={{
              startAdornment: (
                <MD.InputAdornment position="start">
                  <span className={this.props.classes.labelInside}>
                    {' '}
                    {this.props.i18n._('GEMA-AV-Nummer')}{' '}
                  </span>
                </MD.InputAdornment>
              ),
            }}
          />
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawHeaderTitleGema = () => {
    return (
      <MD.Grid container direction="row">
        <MD.Grid item>
          <MD.Typography
            variant="subtitle1"
            className={this.props.classes.titleGema}
          >
            {this.props.i18n._(
              'AUDIOVISUELLE WERBESPOTS - WERBETRENNER UND TRAILER'
            )}
          </MD.Typography>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawHeaderSubTitleGema = () => {
    return (
      <MD.Grid container direction="row">
        <MD.Grid item>
          <MD.Typography
            variant="subtitle2"
            className={this.props.classes.subTitleGema}
          >
            {this.props.i18n._('ANGABEN ZU PRODUKTION')}
          </MD.Typography>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawProductionSectionGema = () => {
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid container direction="row" item xs={12}>
          <MD.Grid item xs={6} style={{ paddingRight: 5 }}>
            <TextFieldGema
              value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].spot_title}
              label={this.props.i18n._('Originalspottitel')}
              name="spot_title"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={6} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].spot_id}
              label={this.props.i18n._('ID')}
              name="uniq_id"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid
          container
          direction="row"
          item
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={6} style={{ paddingRight: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].spot_alt_title
              }
              label={this.props.i18n._('Alternativspottitel')}
              name="spot_alt_title"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={6} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].product_name
              }
              label={this.props.i18n._(
                'Produkt/mit dem Trailer beworbene Produktion'
              )}
              name="product_name"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid item style={{ marginTop: 5 }}>
          <TextFieldGema
            value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].announcer}
            label={this.props.i18n._('Werbeproduzent (einschl. Anschrift)')}
            name="announcer"
            fullWidth
            variant="outlined"
            onChange={this.onFieldChange}
            InputLabelProps={{ shrink: false }}
          />
        </MD.Grid>
        <MD.Grid item style={{ marginTop: 5 }}>
          <TextFieldGema
            value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].agency}
            label={this.props.i18n._('Werbeagentur (einschl. Anschrift)')}
            name="agency"
            fullWidth
            variant="outlined"
            onChange={this.onFieldChange}
            InputLabelProps={{ shrink: false }}
          />
        </MD.Grid>
        <MD.Grid
          container
          direction="row"
          item
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={3} style={{ paddingRight: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].product_year
              }
              label={this.props.i18n._('Produktionsjahr')}
              name="product_year"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].product_country
              }
              label={this.props.i18n._('Produktionsland')}
              name="product_country"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].duration}
              label={this.props.i18n._('Werbespotlänge (in Sek)')}
              name="product_duration"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].music_duration
              }
              label={this.props.i18n._('Gesamtmusiksekunden')}
              name="music_duration"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginTop: 10, marginBottom: '-12px' }}
        >
          <MD.Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <MD.Typography className={this.props.classes.labelCheckbox}>
              {this.props.i18n._('GEMA Rechnung bei Onlinewerbung an')}:
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item xs={2} style={{ paddingLeft: 15 }}>
            <MD.FormControlLabel
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  name="facture_online"
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .facture_online
                  }
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Werbeproduzent
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={2}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .facture_production
                  }
                  onChange={this.onCheckBoxFieldChange}
                  name="facture_production"
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Werbeagentur
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={2}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .facture_third_party
                  }
                  onChange={this.onCheckBoxFieldChange}
                  name="facture_third_party"
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Dritte
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid item style={{ marginTop: 5 }}>
          <TextFieldGema
            value={
              this.state.formulaire[COUNTRY_OPTIONS.GERMANY].address_facture
            }
            label={this.props.i18n._(
              'Bei Dritten: Name und Anschrift des Rechnungsempfängers'
            )}
            name="address_facture"
            fullWidth
            variant="outlined"
            onChange={this.onFieldChange}
            InputLabelProps={{ shrink: false }}
          />
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawUtilisationSectionGema = () => {
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid item>
          <MD.Typography
            variant="subtitle2"
            className={this.props.classes.labelCheckbox}
          >
            {this.props.i18n._('Verwendung')}
          </MD.Typography>
        </MD.Grid>
        <MD.Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={2} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .media_type_cinema
                  }
                  name="media_type_cinema"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Kino
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={2} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].media_type_tv
                  }
                  name="media_type_tv"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  TV
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={2} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .media_type_radio
                  }
                  name="media_type_radio"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Radio
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid item container direction="row" xs={12}>
          <MD.Grid item xs={4} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .diffusion_online
                  }
                  name="diffusion_online"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Online als Werbung geschaltet')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={5} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={this.state.formulaire[COUNTRY_OPTIONS.GERMANY].website_url}
              label={this.props.i18n._('Website (URL)')}
              name="website_url"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].broadcast_date
              }
              label={this.props.i18n._('von (Datum)          bis (Datum)')}
              name="broadcast_date"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid item container direction="row" xs={12}>
          <MD.Grid item xs={4} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    !this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .diffusion_online
                  }
                  name="diffusion_online"
                  onChange={this.onCheckBoxFieldChange}
                  // onChange={(event: any, value) => this.onCheckBoxFieldChange(event, !value)}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Online nicht als Werbung geschaltet')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawTypeSectionGema = () => {
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid item>
          <MD.Typography
            variant="subtitle2"
            className={this.props.classes.labelCheckbox}
          >
            {this.props.i18n._('Art')}
          </MD.Typography>
        </MD.Grid>
        <MD.Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={3} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].type_1
                  }
                  name="type_1"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Ad Break')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].type_2
                  }
                  name="type_2"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Sponsoring')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].type_3
                  }
                  name="type_3"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Trailer')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={10} style={{ paddingLeft: 15 }}>
            <MD.Typography
              variant="subtitle2"
              className={this.props.classes.subLabelCheckbox}
            >
              {this.props.i18n._(
                'Handelt es sich bei der mit dem Trailer beworbenen Produktion um eine Fernseheigen - oder Fernsehauftragsproduktion für eigene Sendezwecke und Übernahmesendungen?'
              )}
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item xs={1} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].is_property
                  }
                  name="is_property"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Ja')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    !this.state.formulaire[COUNTRY_OPTIONS.GERMANY].is_property
                  }
                  name="is_property"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  {this.props.i18n._('Nein')}
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawDeclarationSectionGema = () => {
    return (
      <MD.Grid item container direction="row" xs={12} style={{ marginTop: 15 }}>
        <MD.Grid item xs={6} style={{ paddingRight: 15 }}>
          <MD.Typography className={this.props.classes.subLabelCheckbox}>
            {this.props.i18n._('Erklärung des Auftraggebers')}
          </MD.Typography>
          <MD.Typography style={{ fontSize: 12, color: 'black' }}>
            {this.props.i18n._(
              'Ich versichere, dass die Angaben zum oben aufgeführten Werbespot insbesondere bezüglich Titelangaben, Spotlänge und Musikdauer in vollem Umfang der Wahrheit entsprechen. Ich versichere, dass die Angaben zum Musikinhalt vollständig sind.'
            )}
          </MD.Typography>
        </MD.Grid>
        <MD.Grid item xs={6} style={{ paddingLeft: 15 }}>
          <MD.Typography className={this.props.classes.subLabelCheckbox}>
            {this.props.i18n._('Erklärung des Komponisten/Verlages')}
          </MD.Typography>
          <MD.Typography style={{ fontSize: 12, color: 'black' }}>
            {this.props.i18n._(
              'Ich versichere, dass alle Angaben auf diesem Anmeldebogen nach bestem Wissen und Gewissen gemacht wurden. Ich bin/Wir sind damit einverstanden, dass von dieser Erklärung auch gegenüber Behörden und Gerichten Gebrauch gemacht wird. Mir/Uns ist bekannt, dass die Abgabe einer falschen Versicherung strafrechtliche und vereinsrechtliche Folgen haben kann.'
            )}
          </MD.Typography>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawMusicSectionGema = () => {
    let musicInfo: IMusicInfoGema[] = this.state.formulaire[
      COUNTRY_OPTIONS.GERMANY
    ].music as IMusicInfoGema[]
    if (musicInfo.length === 0) {
      musicInfo = initialMusicInfoGema
    }
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid item>
          <MD.Typography
            variant="subtitle2"
            className={this.props.classes.subTitleGema}
          >
            {this.props.i18n._('ANGABEN ZUM MUSIKINHALT')}
          </MD.Typography>
        </MD.Grid>
        <MD.Grid>
          <MD.TableContainer style={{ marginTop: 4 }}>
            <MD.Table
              style={{ border: '1px solid', height: 150 }}
              aria-label="simple table"
            >
              <MD.TableHead>
                <TableRowGema style={{ height: 15 }}>
                  <TableHeaderCellGema></TableHeaderCellGema>
                  <TableHeaderCellGema style={{ width: '80px' }}>
                    {this.props.i18n._('GEMA Werknummer')}
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="left">
                    {this.props.i18n._('Werktitel')}
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="left">
                    {this.props.i18n._('Komponist')}
                    <MD.Typography
                      style={{ textTransform: 'lowercase', fontSize: 11 }}
                    >
                      {this.props.i18n._('(GEMA-Mitglieds-Nr.)')}
                    </MD.Typography>
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="left">
                    {this.props.i18n._('Textdichter')}
                    <MD.Typography
                      style={{ textTransform: 'lowercase', fontSize: 11 }}
                    >
                      {this.props.i18n._('(GEMA-Mitglieds-Nr.)')}
                    </MD.Typography>
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="left">
                    {this.props.i18n._('Bearbeiter')}
                    <MD.Typography
                      style={{ textTransform: 'lowercase', fontSize: 11 }}
                    >
                      {this.props.i18n._('(GEMA-Mitglieds-Nr.)')}
                    </MD.Typography>
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="left">
                    {this.props.i18n._('Verlag')}
                    <MD.Typography
                      style={{ textTransform: 'lowercase', fontSize: 11 }}
                    >
                      {this.props.i18n._('(GEMA-Mitglieds-Nr.)')}
                    </MD.Typography>
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="center" style={{ width: '50px' }}>
                    {this.props.i18n._('Sek')}
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="center">
                    {this.props.i18n._('AK')}
                  </TableHeaderCellGema>
                  <TableHeaderCellGema align="center"></TableHeaderCellGema>
                </TableRowGema>
              </MD.TableHead>
              <MD.TableBody style={{ height: 150 }}>
                {(musicInfo as IMusicInfoGema[]).map(
                  (value: IMusicInfoGema, key: number) => {
                    let music = value as any
                    return (
                      <TableRowGema
                        key={`tableRowGema_${key}`}
                        style={{ height: 15 }}
                      >
                        <TableCellGema component="th" scope="row">
                          {/* {this.props.i18n._(value)} */}
                          <NativeSelectGema
                            disableUnderline={true}
                            value={musicInfo[key].music_gema_type}
                            name="music_gema_type"
                            className={this.props.classes.subLabelCheckbox}
                            onChange={this.onMusicGemaChange.bind(this, key)}
                            inputProps={{ 'aria-label': 'music_gema_type' }}
                          >
                            <option
                              value={CMusicGemaType.WERK}
                              style={{
                                backgroundColor:
                                  this.props.theme.palette.secondary.dark,
                              }}
                            >
                              &nbsp;&nbsp;
                              {this.props.i18n._(CMusicGemaType.WERK)}
                            </option>
                            <option
                              value={CMusicGemaType.JINGLE}
                              style={{
                                backgroundColor:
                                  this.props.theme.palette.secondary.dark,
                              }}
                            >
                              &nbsp;&nbsp;
                              {this.props.i18n._(CMusicGemaType.JINGLE)}
                            </option>
                          </NativeSelectGema>
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            value={music.id === 0 ? '' : music.id}
                            name="id"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            multiline
                            value={music.title}
                            name="title"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            multiline
                            value={music.composers}
                            name="composers"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            multiline
                            value={music.authors}
                            name="authors"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            multiline
                            value={music.arrangers}
                            name="arrangers"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            multiline
                            value={music.editor}
                            name="editor"
                            onChange={this.onMusicGemaChange.bind(this, key)}
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <TextFieldGema
                            InputProps={{ disableUnderline: true }}
                            value={music.sek}
                            name="sek"
                            onChange={(event: any, opt: boolean) =>
                              this.onMusicGemaChange(key, event, opt)
                            }
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <DarkCheckbox
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ fontSize: '13px' }}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon style={{ fontSize: '13px' }} />
                            }
                            checked={music.ak}
                            name="ak"
                            onChange={(event: any, opt: boolean) =>
                              this.onMusicGemaChange(key, event, opt)
                            }
                            color="secondary"
                          />
                        </TableCellGema>
                        <TableCellGema>
                          <MD.Tooltip title={this.props.i18n._('Delete')}>
                            <MD.IconButton
                              aria-label="delete"
                              onClick={this.removeMusicFromDeclarationGema.bind(
                                this,
                                key
                              )}
                            >
                              <DeleteOutlineIcon
                                style={{
                                  fontSize: 15,
                                  color: theme.palette.primary.main,
                                }}
                              />
                            </MD.IconButton>
                          </MD.Tooltip>
                        </TableCellGema>
                      </TableRowGema>
                    )
                  }
                )}
              </MD.TableBody>
            </MD.Table>
          </MD.TableContainer>
        </MD.Grid>
        <MD.Grid>
          <MD.Button onClick={this.addMusicToDeclarationGema}>
            <PlaylistAddIcon style={{ color: 'black' }} />
            <MD.Typography
              variant="subtitle2"
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'black',
              }}
            >
              {this.props.i18n._('Add')}
            </MD.Typography>
          </MD.Button>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private removeMusicFromDeclarationGema = (index: number) => {
    let formulaire = { ...this.state.formulaire }
    let music_item = formulaire[COUNTRY_OPTIONS.GERMANY].music[index]
    if (
      music_item.end_time &&
      music_item.start_time !== undefined &&
      music_item.start_time !== null
    ) {
      let music_duration = formulaire[COUNTRY_OPTIONS.GERMANY].music_duration
      music_duration -= music_item.end_time - music_item.start_time
      music_duration = parseFloat(
        ('' + music_duration.toFixed(2)).replace(/0+$/, '')
      )
      formulaire[COUNTRY_OPTIONS.GERMANY].music_duration = music_duration
    }
    ; (formulaire[COUNTRY_OPTIONS.GERMANY].music as IMusicInfoGema[]).splice(
      index,
      1
    )
    this.setState({
      formulaire: formulaire,
    })
  }

  private addMusicToDeclarationGema = () => {
    let formulaire = { ...this.state.formulaire }
      ; (formulaire[COUNTRY_OPTIONS.GERMANY].music as IMusicInfoGema[]).push(
        CMusicInfoGemaInitial
      )
    this.setState({ formulaire: formulaire })
  }

  private drawSignatureSectionGema = () => {
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid
          container
          direction="row"
          item
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={6} style={{ paddingRight: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                  .mandat_localization_date
              }
              label={this.props.i18n._('Ort/Datum')}
              name="mandat_localization_date"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                  .editor_member_number
              }
              label={this.props.i18n._('Mitgliedsnummer')}
              name="editor_member_number"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 5 }}>
            <TextFieldGema
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                  .editor_localization_date
              }
              label={this.props.i18n._('Ort/Datum')}
              name="editor_localization_date"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
        <MD.Grid
          container
          direction="row"
          item
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={6} style={{ paddingRight: 5 }}>
            <TextFieldGemaNoBackground
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].mandat_signature
              }
              label={this.props.i18n._(
                'Unterschrift des Auftraggebers (Werbeproduzent/Werbeagentur), Firmenstempel'
              )}
              name="mandat_signature"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
          <MD.Grid item xs={6} style={{ paddingLeft: 5 }}>
            <TextFieldGemaNoBackground
              value={
                this.state.formulaire[COUNTRY_OPTIONS.GERMANY].editor_signature
              }
              label={this.props.i18n._(
                'Unterschrift des Komponisten oder Verlegers'
              )}
              name="editor_signature"
              fullWidth
              variant="outlined"
              onChange={this.onFieldChange}
              InputLabelProps={{ shrink: false }}
            />
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawRegionSectionGema = () => {
    return (
      <MD.Grid container direction="column" style={{ marginTop: 15 }}>
        <MD.Grid item>
          <MD.Typography
            variant="subtitle2"
            className={this.props.classes.labelCheckbox}
          >
            {this.props.i18n._('Verwertungsgebiete')}
          </MD.Typography>
        </MD.Grid>
        <MD.Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginTop: 5 }}
        >
          <MD.Grid item xs={3} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .region_germany
                  }
                  name="region_germany"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Deutschland
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={4} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                      .region_germain_speaking
                  }
                  name="region_germain_speaking"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Deutschland/Österreich/Schweiz
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={2} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].region_europe
                  }
                  name="region_europe"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  Europa
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
          <MD.Grid item xs={3} style={{ paddingLeft: 15 }}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '13px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '13px' }} />}
                  checked={
                    this.state.formulaire[COUNTRY_OPTIONS.GERMANY].region_other
                  }
                  name="region_other"
                  onChange={this.onCheckBoxFieldChange}
                  color="secondary"
                />
              }
              label={
                <MD.Typography className={this.props.classes.subLabelCheckbox}>
                  sonstige Länder (bitte angeben)
                </MD.Typography>
              }
              labelPlacement={'end'}
              style={{ marginLeft: 0, width: '100%', float: 'right' }}
            />
          </MD.Grid>
        </MD.Grid>

        <MD.Grid item style={{ marginTop: 3 }} xs={12}>
          <TextFieldGema
            value={
              this.state.formulaire[COUNTRY_OPTIONS.GERMANY]
                .region_other_comment
            }
            label={this.props.i18n._('sonstige Länder')}
            name="region_other_comment"
            fullWidth
            variant="outlined"
            onChange={this.onFieldChange}
            InputLabelProps={{ shrink: false }}
          />
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawFooterGema = () => {
    return (
      <MD.Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        <MD.Grid item>
          <MD.Typography style={{ fontSize: 12 }}>
            {this.props.i18n._('MUSIK IST UNS WAS WERT')} |{' '}
            <span style={{ fontWeight: 'bold', color: '#ea0b0b' }}>
              www.gema.de
            </span>
          </MD.Typography>
        </MD.Grid>
        <MD.Grid item>
          <MD.Typography style={{ fontSize: 12 }}>
            {this.props.i18n._('Anmeldung Werbespots')} |{' '}
            {this.props.i18n._('Stand ')} 8.10.2020 |{' '}
            <span style={{ fontWeight: 'bold', color: '#ea0b0b' }}>
              {' '}
              Seite 1 // 1{' '}
            </span>
          </MD.Typography>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawHeader = () => {
    const { classes } = this.props
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    return (
      <MD.Grid container>
        <MD.Grid item xs={2}>
          <img className={classes.sacemLogo} src="/logo-sacem.png" alt="" />
        </MD.Grid>
        <MD.Grid item style={{ textAlign: 'center' }} xs={10}>
          <MD.Typography className={classes.headerTitle}>
            {this.props.i18n._('FICHE TECHNIQUE PUBLICITAIRE')}
            {this.state.formulaire[country].is_validated ? (
              <DoneOutlineIcon className={classes.statusValidated} />
            ) : (
              <EditIcon className={classes.statusDraft} />
            )}
          </MD.Typography>
          <MD.Grid container justifyContent="flex-end">
            {/* <MD.Typography className={classes.headerSubtitle}>IDENTIFIANT : {this.state.formulaire.identifier}</MD.Typography> */}
            <MD.Typography className={classes.headerSubtitle}>
              {this.props.i18n._('VERSION')} :{' '}
              {this.state.formulaire[country].version}
            </MD.Typography>
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawAddress = () => {
    const { classes } = this.props
    return (
      <MD.Grid container>
        <MD.Grid item className={classes.deposantBox} xs={6}>
          {this.props.i18n._('Déposant')} :
        </MD.Grid>
        <MD.Grid item className={classes.sacemAddressBox} xs={6}>
          <MD.Grid>SACEM</MD.Grid>
          <MD.Grid>DDGR – SA Audiovisuel – Equipe Publicité</MD.Grid>
          <MD.Grid> 225, avenue Charles de Gaulle</MD.Grid>
          <MD.Grid>92528 Neuilly sur Seine Cedex</MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawInfoNotice = (text: string) => {
    const { classes } = this.props
    return (
      <MD.Grid className={classes.infoNotice}>
        <InfoIcon className={classes.noticeIcon} />
        {text}
      </MD.Grid>
    )
  }

  //Only France
  private drawProductInfo = () => {
    const { classes } = this.props
    return (
      <MD.Paper className={classes.productPaper}>
        <MD.Grid container direction="column">
          <MD.Grid item container>
            <MD.Grid item xs={2} className={classes.label}>
              Marque*
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].marque}
                name="marque"
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
                style={{ color: '#f8f9fa' }}
              />
            </MD.Grid>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Nom du produit')}*
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={
                  this.state.formulaire[COUNTRY_OPTIONS.FRANCE].product_name
                }
                name="product_name"
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
                style={{ color: '#f8f9fa' }}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Nature du produit')}
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={
                  this.state.formulaire[COUNTRY_OPTIONS.FRANCE].product_nature
                }
                name="product_nature"
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
                style={{ color: '#f8f9fa' }}
              />
            </MD.Grid>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Annonceur/Agence')}
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].agency}
                name="agency"
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
                style={{ color: '#f8f9fa' }}
              />
            </MD.Grid>
          </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  // Only France
  private drawSpotInfo = () => {
    const { classes } = this.props
    return (
      <MD.Paper className={classes.spotPaper}>
        <MD.Grid container direction="column">
          <MD.Grid item container>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Titre du spot')}*
            </MD.Grid>
            <MD.Grid item xs={6} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].spot_title}
                name="spot_title"
                className={classes.darkBorderTextField}
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
              />
            </MD.Grid>
            <MD.Grid item xs={1} className={classes.label}>
              {this.props.i18n._('Durée* (en sec.)')}
            </MD.Grid>
            <MD.Grid item xs={3} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].duration}
                name="duration"
                fullWidth
                variant="outlined"
                className={classes.darkBorderTextField}
                onChange={this.onFieldChange}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Codes ARPP')}(1)
            </MD.Grid>
            <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].codes_arpp}
                name="codes_arpp"
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                helperText="ex : 2018061901"
                variant="outlined"
                onChange={this.onFieldChange}
                FormHelperTextProps={{ className: classes.formHelperText }}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Codes PubID')}(1)
            </MD.Grid>
            <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={
                  this.state.formulaire[COUNTRY_OPTIONS.FRANCE].codes_pub_id
                }
                name="codes_pub_id"
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                helperText="ex : FR_AGEN_ANNO_PROD_0012_030_F"
                variant="outlined"
                onChange={this.onFieldChange}
                FormHelperTextProps={{ className: classes.formHelperText }}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Lien du spot')}(1)
            </MD.Grid>
            <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
              <MD.Tooltip title={this.props.i18n._('Open spot page')}>
                <MD.Link
                  href={`/details/${this.state.formulaire[COUNTRY_OPTIONS.FRANCE].bucket_id
                    }/${this.state.formulaire[COUNTRY_OPTIONS.FRANCE].spot_id}`}
                  target="_blank"
                >
                  <LaunchRoundedIcon color="primary" />
                </MD.Link>
              </MD.Tooltip>
            </MD.Grid>
          </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private drawSectionHelperText = (index: number, text: string) => {
    const { classes } = this.props
    return (
      <MD.Grid className={classes.sectionHelperText}>
        <span className={classes.sectionHelperTextIndex}>{index})</span> {text}
      </MD.Grid>
    )
  }

  // Only France
  private drawMediaInfo = () => {
    const { classes } = this.props
    let diffusionDte: any = null
    //const country = this.props.search.params.country as COUNTRY_OPTIONS;
    if (
      this.state.formulaire[COUNTRY_OPTIONS.FRANCE].diffusion_date &&
      this.state.formulaire[COUNTRY_OPTIONS.FRANCE].diffusion_date !== ''
    ) {
      diffusionDte = Moment(
        this.state.formulaire[COUNTRY_OPTIONS.FRANCE].diffusion_date
      ).format('YYYY-MM-DDTHH:mm')
    }
    let media = this.state.formulaire[COUNTRY_OPTIONS.FRANCE].media as any
    let media_type = ''
    if (media && typeof media !== 'string') {
      media = media.name
      media_type =
        this.state.formulaire[COUNTRY_OPTIONS.FRANCE].media.type === 'Video'
          ? 'TV'
          : this.state.formulaire[COUNTRY_OPTIONS.FRANCE].media.type
    }
    return (
      <MD.Paper className={classes.spotPaper}>
        <MD.Grid container direction="column">
          <MD.Grid item container>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Média')}*
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].media.type}
                name="media"
                className={classes.darkBorderTextField}
                fullWidth
                variant="outlined"
                onChange={this.onFieldChange}
              />
            </MD.Grid>
            <MD.Grid
              item
              xs={2}
              style={{ textAlign: 'right', paddingRight: '25px' }}
              className={classes.label}
            >
              Diffuseur*
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={this.state.formulaire[COUNTRY_OPTIONS.FRANCE].media.name}
                name="diffuser"
                fullWidth
                variant="outlined"
                className={classes.darkBorderTextField}
                onChange={this.onFieldChange}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              {this.props.i18n._('Date / Période de diffusion')}*
            </MD.Grid>
            <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
              <DensedTextField
                value={diffusionDte || ''}
                name="diffusion_date"
                className={classes.darkBorderTextField}
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                type="datetime-local"
                variant="outlined"
                onChange={this.onFieldChange}
              />
            </MD.Grid>
          </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private drawFirstRightHolderSection = (
    rightHolder: IRightHolders,
    musicIndex: number,
    rhIndex: number
  ) => {
    const { classes } = this.props
    return (
      <MD.Grid
        key={`gridContainer_FirstRightHolder_${musicIndex}_${rhIndex}`}
        className={classes.rightHoldersSection}
        container
        direction="column"
      >
        <MD.Grid item container>
          <MD.Grid item xs={7}>
            <FormControlLabelTopLeftPlacement
              control={
                <DensedTextFieldDark
                  value={rightHolder.name}
                  name="name"
                  fullWidth
                  variant="outlined"
                  className={classes.darkTextField}
                  onChange={this.onRightHolderChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                />
              }
              label={this.props.i18n._(
                "Ayant(s) droit de l'œuvre (Nom et Prénom)"
              )}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                  checked={rightHolder.roles.includes(IRole.ARTIST)}
                  name={IRole.ARTIST}
                  onChange={this.onRightHolderRoleChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                  color="secondary"
                />
              }
              label={this.props.i18n._('Artiste')}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                  checked={rightHolder.roles.includes(IRole.AUTHOR)}
                  name={IRole.AUTHOR}
                  onChange={this.onRightHolderRoleChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                  color="secondary"
                />
              }
              label={this.props.i18n._('Auteur')}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                  checked={rightHolder.roles.includes(IRole.COMPOSER)}
                  onChange={this.onRightHolderRoleChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                  name={IRole.COMPOSER}
                  color="secondary"
                />
              }
              label={this.props.i18n._('Compo.')}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                  checked={rightHolder.roles.includes(IRole.ARRANGER)}
                  onChange={this.onRightHolderRoleChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                  name={IRole.ARRANGER}
                  color="secondary"
                />
              }
              label={this.props.i18n._('Arrangeur')}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
          <MD.Grid item xs={1}>
            <FormControlLabelMedium
              control={
                <DarkCheckbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
                  checked={rightHolder.roles.includes(IRole.EDITOR)}
                  onChange={this.onRightHolderRoleChange.bind(
                    this,
                    musicIndex,
                    rhIndex
                  )}
                  name={IRole.EDITOR}
                  color="secondary"
                />
              }
              label={this.props.i18n._('Éditeur')}
              labelPlacement={'top'}
              style={{ marginLeft: 0, width: '100%' }}
            />
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawAdditionalRightHolderSection = (
    rightHolder: IRightHolders,
    musicIndex: number,
    rhIndex: number
  ) => {
    const { classes } = this.props
    return (
      <MD.Grid
        container
        direction="column"
        key={`gridContainer_AdditionalRightHolder_${musicIndex}_${musicIndex}`}
      >
        <MD.Grid item container>
          <MD.Grid item xs={7}>
            <DensedTextFieldDark
              value={rightHolder.name}
              name="name"
              fullWidth
              variant="outlined"
              className={classes.darkTextField}
              onChange={this.onRightHolderChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ textAlign: 'center' }}>
            <DarkCheckbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
              checked={rightHolder.roles.includes(IRole.ARTIST)}
              onChange={this.onRightHolderRoleChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
              name={IRole.ARTIST}
              color="secondary"
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ textAlign: 'center' }}>
            <DarkCheckbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
              checked={rightHolder.roles.includes(IRole.AUTHOR)}
              onChange={this.onRightHolderRoleChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
              name={IRole.AUTHOR}
              color="secondary"
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ textAlign: 'center' }}>
            <DarkCheckbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
              checked={rightHolder.roles.includes(IRole.COMPOSER)}
              onChange={this.onRightHolderRoleChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
              name={IRole.COMPOSER}
              color="secondary"
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ textAlign: 'center' }}>
            <DarkCheckbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
              checked={rightHolder.roles.includes(IRole.ARRANGER)}
              onChange={this.onRightHolderRoleChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
              name={IRole.ARRANGER}
              color="secondary"
            />
          </MD.Grid>
          <MD.Grid item xs={1} style={{ textAlign: 'center' }}>
            <DarkCheckbox
              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '14px' }} />}
              checkedIcon={<CheckBoxIcon style={{ fontSize: '14px' }} />}
              checked={rightHolder.roles.includes(IRole.EDITOR)}
              onChange={this.onRightHolderRoleChange.bind(
                this,
                musicIndex,
                rhIndex
              )}
              name={IRole.EDITOR}
              color="secondary"
            />
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  private drawRightHoldersSection = (
    rightHolders: IRightHolders[],
    index: number
  ) => {
    const { classes } = this.props

    const result = rightHolders.map(
      (rightHoler: IRightHolders, rhIndex: number) => {
        if (index === 0) {
          return this.drawFirstRightHolderSection(rightHoler, index, rhIndex)
        } else {
          return this.drawAdditionalRightHolderSection(
            rightHoler,
            index,
            rhIndex
          )
        }
      }
    )

    return (
      <MD.Grid
        className={classes.rightHoldersSection}
        container
        direction="column"
      >
        {result}
        <MD.Grid item container style={{ paddingTop: '5px' }}>
          <MD.Grid>
            <MD.IconButton
              style={{ padding: 0 }}
              onClick={this.addRightHolder.bind(this, index)}
            >
              <AddBoxIcon className={classes.changeRightHolders} />
            </MD.IconButton>
          </MD.Grid>
          <MD.Grid>
            <MD.IconButton
              style={{ padding: 0 }}
              onClick={this.removeRightHolder.bind(this, index)}
            >
              <IndeterminateCheckBoxIcon
                className={classes.changeRightHolders}
              />
            </MD.IconButton>
          </MD.Grid>
          <MD.Grid className={classes.smallFont} style={{ paddingLeft: '8px' }}>
            {this.props.i18n._('Maximum 10 ayants droit ')}
            <br />(
            {this.props.i18n._(
              "Ceci ne constitue pas une déclaration d'œuvre qui doit être faite par ailleurs"
            )}
            )
          </MD.Grid>
        </MD.Grid>
      </MD.Grid>
    )
  }

  // Only France
  private addRightHolder = (index: number) => {
    let formulaire = this.state.formulaire
      ; (
        formulaire[COUNTRY_OPTIONS.FRANCE].music[index] as IMusicInfo
      ).right_holders.push(CRightHolders)
    this.setState({ formulaire: formulaire })
  }

  // Only France
  private removeRightHolder = (index: number) => {
    let formulaire = this.state.formulaire
      ; (
        formulaire[COUNTRY_OPTIONS.FRANCE].music[index] as IMusicInfo
      ).right_holders.pop()
    this.setState({ formulaire: formulaire })
  }

  // Only France
  private drawMusicInfo = (music: IMusicInfo, index: number) => {
    const { classes } = this.props
    return (
      <MD.Paper key={`paper_MusicInfo_${index}`} className={classes.spotPaper}>
        <MD.Grid container direction="column">
          <MD.Grid item container>
            <MD.Grid item xs={2} className={classes.label}>
              Titre de l'Œuvre*
            </MD.Grid>
            <MD.Grid item xs={5} style={{ paddingRight: '20px' }}>
              <DensedTextFieldDark
                value={music.title}
                name="title"
                className={classes.darkBorderTextField}
                fullWidth
                variant="outlined"
                onChange={this.onMusicInfoChange.bind(this, index)}
              />
            </MD.Grid>
            <MD.Grid
              item
              xs={3}
              style={{ textAlign: 'right', paddingRight: '25px' }}
              className={classes.label}
            >
              {this.props.i18n._("Durée d'utilisation dans le spot (en sec.)")}*
            </MD.Grid>
            <MD.Grid item xs={2} style={{ paddingRight: '20px' }}>
              <DensedTextFieldDark
                value={music.usage_duration}
                name="usage_duration"
                fullWidth
                variant="outlined"
                className={classes.darkBorderTextField}
                onChange={this.onMusicInfoChange.bind(this, index)}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2}>
              {' '}
            </MD.Grid>
            <MD.Grid item xs={10}>
              <MD.RadioGroup
                style={{ flexDirection: 'row' }}
                value={music.type}
                name="type"
                onChange={this.onMusicInfoChange.bind(this, index)}
              >
                <FormControlLabelSmall
                  value="existing"
                  control={
                    <DarkRadio
                      icon={
                        <RadioButtonUncheckedIcon
                          style={{ fontSize: '14px' }}
                        />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon style={{ fontSize: '14px' }} />
                      }
                    />
                  }
                  label={this.props.i18n._('Œuvre préexistante')}
                  className={classes.smallFont}
                />
                <FormControlLabelSmall
                  value="new"
                  control={
                    <DarkRadio
                      icon={
                        <RadioButtonUncheckedIcon
                          style={{ fontSize: '14px' }}
                        />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon style={{ fontSize: '14px' }} />
                      }
                    />
                  }
                  label={this.props.i18n._('Œuvre nouvelle')}
                />
                <FormControlLabelSmall
                  value="signature"
                  control={
                    <DarkRadio
                      icon={
                        <RadioButtonUncheckedIcon
                          style={{ fontSize: '14px' }}
                        />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon style={{ fontSize: '14px' }} />
                      }
                    />
                  }
                  label={this.props.i18n._('Signature musicale')}
                />
              </MD.RadioGroup>
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              Code ISWC
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextFieldDark
                value={music.code_iswc || ''}
                name="code_iswc"
                className={classes.darkHelperText}
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                variant="outlined"
                placeholder={this.props.i18n._('ex : T-003.058.876-1')}
                onChange={this.onMusicInfoChange.bind(this, index)}
              />
            </MD.Grid>
            <MD.Grid
              item
              xs={2}
              style={{ textAlign: 'right', paddingRight: '25px' }}
              className={classes.label}
            >
              Code ISRC
            </MD.Grid>
            <MD.Grid item xs={4} style={{ paddingRight: '20px' }}>
              <DensedTextFieldDark
                value={music.code_isrc || ''}
                name="code_isrc"
                className={classes.darkHelperText}
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                variant="outlined"
                placeholder={this.props.i18n._('ex : FR-AB5-07-12345')}
                onChange={this.onMusicInfoChange.bind(this, index)}
              />
            </MD.Grid>
          </MD.Grid>
          <MD.Grid item container style={{ marginTop: '15px' }}>
            <MD.Grid item xs={2} className={classes.label}>
              Code Œuvre
            </MD.Grid>
            <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
              <DensedTextFieldDark
                value={music.code}
                name="code"
                className={classes.darkHelperText}
                inputProps={{ className: classes.darkBorderTextField }}
                fullWidth
                variant="outlined"
                placeholder={this.props.i18n._('ex: COCV ou IDE12')}
                onChange={this.onMusicInfoChange.bind(this, index)}
              />
            </MD.Grid>
          </MD.Grid>
          {music.right_holders &&
            music.right_holders.length > 0 &&
            this.drawRightHoldersSection(music.right_holders, index)}
          <MD.Grid item container direction="row" justifyContent="flex-end">
            <MD.Grid item>
              <MD.IconButton
                style={{ padding: 0 }}
                onClick={this.handleMusicDeleteFromListFrance.bind(this, index)}
              >
                <DeleteIcon style={{ color: '#aaa' }} />
              </MD.IconButton>
            </MD.Grid>
          </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private handleMusicDeleteFromListFrance = (index: number) => {
    let formulaire = { ...this.state.formulaire }
      ; (formulaire[COUNTRY_OPTIONS.FRANCE].music as IMusicInfo[]).splice(index, 1)
    this.setState({
      formulaire: formulaire,
    })
  }

  // Only France
  private drawListingsMusic = () => {
    const { classes } = this.props
    const music = this.state.formulaire[COUNTRY_OPTIONS.FRANCE].music
    const result = (music as IMusicInfo[]).map(
      (music: IMusicInfo, index: number) => {
        return this.drawMusicInfo(music, index)
      }
    )

    return (
      <MD.Grid>
        <MD.Grid className={classes.sectionHeader}>
          {this.props.i18n._('Listing des Œuvres :')}
        </MD.Grid>
        {result}
      </MD.Grid>
    )
  }

  // Only France
  private addMusic = () => {
    let formulaire = this.state.formulaire
      ; (formulaire[COUNTRY_OPTIONS.FRANCE].music as IMusicInfo[]).push(
        CMusicInfoInitial
      )
    this.setState({ formulaire: formulaire })
  }

  private drawAddMusicButton = () => {
    return (
      <MD.Grid>
        <MD.Button
          color="secondary"
          style={{ width: '100%', marginTop: '15px' }}
          variant="outlined"
          onClick={this.addMusic}
        >
          <AddIcon />
          <MD.Typography>
            {this.props.i18n._('Ajouter une oeuvre')}
          </MD.Typography>
        </MD.Button>
      </MD.Grid>
    )
  }

  // Only France
  private drawEnterpriseInformation = () => {
    const { classes } = this.props
    let today: Date | string = new Date()
    if (
      this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society &&
      this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.date &&
      this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.date !== ''
    ) {
      today = this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.date
    } else {
      today = today.toISOString().substring(0, 10)
    }

    return (
      <MD.Paper className={classes.spotPaper}>
        <MD.Grid item container style={{ marginBottom: '15px' }}>
          {this.props.i18n._('Informations sociétaire')}
        </MD.Grid>
        <MD.Grid container>
          <MD.Grid container item xs={6} direction="column">
            <MD.Grid item container style={{ marginTop: '10px' }}>
              <MD.Grid item xs={2} className={classes.label}>
                {this.props.i18n._('ID')}
              </MD.Grid>
              <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
                <DensedTextField
                  value={
                    this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.id
                  }
                  name="society.id"
                  disabled
                  className={classes.darkBorderTextField}
                  fullWidth
                  variant="outlined"
                />
              </MD.Grid>
            </MD.Grid>
            <MD.Grid item container style={{ marginTop: '10px' }}>
              <MD.Grid item xs={2} className={classes.label}>
                {this.props.i18n._('Nom')}
              </MD.Grid>
              <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
                <DensedTextField
                  value={
                    this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society
                      .surname
                  }
                  name="society.surname"
                  className={classes.darkBorderTextField}
                  fullWidth
                  variant="outlined"
                />
              </MD.Grid>
            </MD.Grid>
            <MD.Grid item container style={{ marginTop: '10px' }}>
              <MD.Grid item xs={2} className={classes.label}>
                {this.props.i18n._('Prénom')}
              </MD.Grid>
              <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
                <DensedTextField
                  value={
                    this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.name
                  }
                  name="society.name"
                  className={classes.darkBorderTextField}
                  fullWidth
                  variant="outlined"
                />
              </MD.Grid>
            </MD.Grid>
            <MD.Grid item container style={{ marginTop: '10px' }}>
              <MD.Grid item xs={2} className={classes.label}>
                {this.props.i18n._('Email')}
              </MD.Grid>
              <MD.Grid item xs={10} style={{ paddingRight: '20px' }}>
                <DensedTextField
                  value={
                    this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society.email
                  }
                  name="society.email"
                  disabled
                  className={classes.darkBorderTextField}
                  fullWidth
                  variant="outlined"
                />
              </MD.Grid>
            </MD.Grid>
          </MD.Grid>
          <MD.Grid container item xs={6} direction="column">
            <MD.Grid item container style={{ marginTop: '10px' }}>
              <MD.Grid item xs={2} className={classes.label}>
                {this.props.i18n._('Date')}
              </MD.Grid>
              <MD.Grid item xs={10} style={{ paddingRight: '15px' }}>
                <DensedTextField
                  name="society.date"
                  value={today}
                  disabled
                  fullWidth
                  variant="outlined"
                  className={classes.darkBorderTextField}
                />
              </MD.Grid>
            </MD.Grid>
            <MD.Grid item container>
              <MD.Grid
                item
                xs={2}
                style={{ marginTop: '15px' }}
                className={classes.label}
              >
                {this.props.i18n._('Signature')}
              </MD.Grid>
              <MD.Grid item xs={10}>
                <DensedTextField
                  value={
                    this.state.formulaire[COUNTRY_OPTIONS.FRANCE].society
                      .signature
                  }
                  name="society.signature"
                  className={classes.textarea}
                  inputProps={{ className: classes.darkBorderTextField }}
                  fullWidth
                  rows={5}
                  multiline
                  variant="outlined"
                />
              </MD.Grid>
            </MD.Grid>
          </MD.Grid>
        </MD.Grid>
      </MD.Paper>
    )
  }

  private userFormIdentity() {
    const userInfo = this.props.user.userInfo
    return (
      <>
        <b>Procédure de signature électronique</b>
        <br />
        <br />
        En cliquant sur confirmer, vous déclenchez la procédure de signature
        électronique via Docusign.
        <br />
        Vous allez recevoir un mail de la part de Docusign vous invitant à
        signer le document avec ces informations :
        <br />
        <br />
        {this.props.i18n._('First name')} : {userInfo.firstname}
        <br />
        {this.props.i18n._('Last name')} : {userInfo.name}
        <br />
        {this.props.i18n._('Email')} : {userInfo.email}
        <br />
        {this.props.i18n._('Société')} : {userInfo.parent_info.name}
        <br />
        <br />
        Une fois rendu sur Docusign et terminé de signer, votre document vous
        sera envoyé par mail et vous pourrez revenir sur Adtrack pour l'envoyer à
        la Sacem
      </>
    )
  }

  private openSaveFormulaire(is_validated: boolean) {
    const envelope = this.state.formulaire.fr.envelope

    let title = '',
      content = ''
    if (envelope === 'completed') {
      title = this.props.i18n._('Envoyer le document signé à la sacem ?')
      content = this.props.i18n._(
        'Êtes vous sûr de vouloir envoyer le document signé electroniquement à la sacem ?'
      )
    } else if (
      is_validated &&
      !this.state.identity_confirmed &&
      envelope !== 'completed'
    ) {
      title = this.props.i18n._('Signer ma déclaration')
      content = this.userFormIdentity() as any
    } else {
      title = this.props.i18n._('Save Draft ?')
      content = this.props.i18n._('Are you sure you want to save the draft ?')
    }

    this.setState({
      is_editing: false,
      openDialog: true,
      sendFormulaire: is_validated,
      dialogTitle: title,
      dialogContent: content,
    })
  }

  private saveFormulaire = (is_validated: boolean, download?: boolean) => {
    const userInfo = this.props.user.userInfo
    const country = this.props.search.params.country as COUNTRY_OPTIONS
    const envelope = this.state.formulaire.fr.envelope

    this.props.saveFormulaire({
      ...this.state.formulaire[country],
      is_validated: is_validated,
      is_signed: envelope === 'completed',
      download,
      user_id: userInfo.id,
      user_group: userInfo.parent_info.name,
      user_email: userInfo.email,
      society: {
        id: userInfo.id,
        name: userInfo.firstname,
        surname: userInfo.name,
        email: userInfo.email,
        date: new Date().toISOString().substring(0, 10),
        user_group_info: userInfo.parent_info,
        signature: '',
      },
    })
    let content = (envelope === 'completed' ?
      <div>
        <p>
          {this.props.i18n._(
            'Votre document signé électroniquement a été envoyé à la Sacem.'
          )}
        </p>
        <p>
          {this.props.i18n._(
            "L'équipe d'Adtrack vous remercie pour votre collaboration."
          )}
        </p>
      </div> : envelope === 'sent' ?
        <div>
          <p>
            {this.props.i18n._(
              'Le document vous a été renvoyé en signature électronique.'
            )}
          </p>
          <p>
            {this.props.i18n._(
              "L'équipe d'Adtrack vous remercie pour votre collaboration."
            )}
          </p>
        </div> :
        is_validated ?
          <div>
            <p>
              {this.props.i18n._(
                'Your declaration has been sent to our partner Docusign for electronic signature.'
              )}
            </p>
            <p>
              {this.props.i18n._(
                'You will receive an email in order to sign your document.'
              )}
            </p>
            <p>
              {this.props.i18n._(
                "L'équipe d'Adtrack vous remercie pour votre collaboration."
              )}
            </p>
          </div> : <div>
            <p>
              {this.props.i18n._(
                'Les modifications ont été sauvegardées.'
              )}
            </p>
            <p>
              {this.props.i18n._(
                "L'équipe d'Adtrack vous remercie pour votre collaboration."
              )}
            </p>
          </div>
    ) as any

    this.setState({
      identity_confirmed: true,
      dialogTitle: envelope === 'completed' ? this.props.i18n._('Envoie à la sacem') : envelope === 'sent' ? this.props.i18n._('Resend Electronic signature') : is_validated ? this.props.i18n._('Electronic signature') : this.props.i18n._('Sauvegarde'),
      dialogContent: content,
    })
  }

  private downloadPdf = () => {
    this.setState({ openConfirmDownload: false })
    this.saveFormulaire(false, true)
  }

  private drawButtons = () => {
    const country = this.props.search.params.country as COUNTRY_OPTIONS

    if (this.props.formulaire.isLoading) {
      return (
        <MD.Grid container spacing={1}>
          <MD.Grid item xs={12}>
            Loading...
          </MD.Grid>
        </MD.Grid>
      )
    }

    if (isNullOrUndefinedOrEmptyString(this.state.formulaire.fr.envelope)) {
      return (
        <MD.Grid container spacing={1}>
          <MD.Grid item xs={6}>
            <MD.Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() =>
                this.setState({
                  openConfirmDownload: true,
                  dialogTitle: this.props.i18n._('Download form ?'),
                  dialogContent: this.props.i18n._(
                    "Êtes vous sûr(e) d'enregistrer ce document ?"
                  ),
                })
              }
            >
              <SaveAltIcon style={{ marginRight: '7px' }} />
              {this.props.i18n._('Télécharger le PDF')}
            </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={6}>
            <MD.Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={this.openSaveFormulaire.bind(this, false)}
            >
              <SaveIcon style={{ marginRight: '7px' }} />
              {this.props.i18n._('Enregistrer le brouillon')}
            </MD.Button>
          </MD.Grid>
          <MD.Grid item xs={12}>
            <MD.Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.openSaveFormulaire.bind(this, true)}
            >
              <DoneOutlineIcon style={{ marginRight: '7px' }} />
              {country === COUNTRY_OPTIONS.FRANCE
                ? this.props.i18n._('Valider pour signature électronique')
                : this.props.i18n._('Valider et envoyer')}
            </MD.Button>
          </MD.Grid>
        </MD.Grid>
      )
    } else {
      const envelope = this.state.formulaire.fr.envelope
      if (envelope === 'sent') {
        return (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {country === COUNTRY_OPTIONS.FRANCE
                  ? this.props.i18n._('Renvoyer pour signature électronique')
                  : this.props.i18n._('Valider et envoyer')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }
      if (envelope === 'completed' && this.props.formulaire.fr.is_signed) {
        return (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {country === COUNTRY_OPTIONS.FRANCE
                  ? this.props.i18n._('Renvoyer à la Sacem')
                  : this.props.i18n._('Valider et envoyer')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }
      if (envelope === 'completed') {
        return (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {country === COUNTRY_OPTIONS.FRANCE
                  ? this.props.i18n._('Envoyer à la Sacem')
                  : this.props.i18n._('Valider et envoyer')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }
    }
  }

  private drawWarningMessageContainer = () => {
    const { theme } = this.props
    if (this.state.warningMessage) {
      return (
        <MD.Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '25px',
            backgroundColor: theme.palette.primary.main,
            top: '15vh',
            textAlign: 'center',
            lineHeight: '25px',
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          {this.state.warningMessage}
        </MD.Box>
      )
    }
  }

  private drawConfirmationDialog() {
    return (
      <MD.Dialog
        open={this.state.openDialog}
        onClose={this.closeDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MD.DialogTitle id="alert-dialog-title">
          {this.state.dialogTitle}
        </MD.DialogTitle>
        <MD.DialogContent dividers>
          <MD.DialogContentText id="alert-dialog-description">
            {this.state.dialogContent}
          </MD.DialogContentText>
        </MD.DialogContent>
        {this.state.identity_confirmed ? (
          <MD.DialogActions>
            <Route
              render={({ history }) => (
                <MD.Button
                  onClick={() => {
                    this.closeDialog()
                    history.push('/declarations')
                  }}
                >
                  {this.props.i18n._('Close')}
                </MD.Button>
              )}
            />
          </MD.DialogActions>
        ) : (
          <MD.DialogActions>
            <MD.Button onClick={() => this.closeDialog()}>
              {this.props.i18n._('Cancel')}
            </MD.Button>
            <MD.Button
              onClick={() => this.saveFormulaire(this.state.sendFormulaire)}
              autoFocus
            >
              {this.props.i18n._('Confirm')}
            </MD.Button>
          </MD.DialogActions>
        )}
      </MD.Dialog>
    )
  }

  private drawConfirmationDownload() {
    return (
      <MD.Dialog
        open={this.state.openConfirmDownload}
        onClose={this.closeConfirmDialog.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MD.DialogTitle id="alert-dialog-title">
          {this.state.dialogTitle}
        </MD.DialogTitle>
        <MD.DialogContent dividers>
          <MD.DialogContentText id="alert-dialog-description">
            {this.state.dialogContent}
          </MD.DialogContentText>
        </MD.DialogContent>
        <MD.DialogActions>
          <MD.Button onClick={() => this.closeConfirmDialog()}>
            {this.props.i18n._('Cancel')}
          </MD.Button>
          <MD.Button onClick={() => this.downloadPdf()} autoFocus>
            {this.props.i18n._('Confirm')}
          </MD.Button>
        </MD.DialogActions>
      </MD.Dialog>
    )
  }

  private closeDialog() {
    this.setState({
      openDialog: false,
      dialogContent: '',
      dialogTitle: '',
      sendFormulaire: false,
      identity_confirmed: false,
    })
  }

  private closeConfirmDialog() {
    this.setState({
      openConfirmDownload: false,
      dialogContent: '',
      dialogTitle: '',
      sendFormulaire: false,
    })
  }

  private renderFormulaireContentFrance = () => {
    const { classes } = this.props

    if (this.props.formulaire.formulairesPDF) {
      // on est ici si on a reçu un formulaire en PDF et on le genere avec un iframe

      let button: JSX.Element | undefined = undefined
      const envelope = this.state.formulaire.fr.envelope
      if (envelope === 'sent') {
        button = (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {this.props.i18n._('Renvoyer pour signature électronique')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }
      if (envelope === 'completed' && !this.props.formulaire.fr.is_signed) {
        button = (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {this.props.i18n._('Envoyer à la Sacem')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }
      if (envelope === 'completed' && this.props.formulaire.fr.is_signed) {
        button = (
          <MD.Grid container>
            <MD.Grid item xs={12}>
              <MD.Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.openSaveFormulaire.bind(this, true)}
              >
                <DoneOutlineIcon style={{ marginRight: '7px' }} />
                {this.props.i18n._('Renvoyer à la Sacem')}
              </MD.Button>
            </MD.Grid>
          </MD.Grid>
        )
      }

      return (
        <MD.Paper
          className={classes.declarationPaper}
          style={{ height: '100%' }}
        >

          {/* ici on affiche le PDF dans l'iframe */}
          <iframe
            style={{ height: '95%', width: '100%' }}
            src={this.props.formulaire.formulairesPDF.fileURL}
          >
          </iframe>
          {/* ici on affiche soit le bouton de demande de renvoie en 
          signature electronique soit le bouton d'envoie à la sacem */}

          {button}


        </MD.Paper>
      )
    }

    /* si on a pas reçu de formulaire en PDF on affiche les différents champs du 
    formulaire à complété afin de généré plus tard un pdf à envoyer en signature
    électronique.
    */

    return (
      <MD.Paper
        className={classes.declarationPaper}
        style={{ height: 'max-content' }}
      >
        {this.drawHeader()}
        {this.drawAddress()}
        {this.drawInfoNotice(
          this.props.i18n._(
            'Le dépôt à la Sacem d’un spot publicitaire doit impérativement comprendre toutes les mentions relatives à l’identification des oeuvres musicales utilisées dans le spot.'
          )
        )}
        {this.drawProductInfo()}
        {this.drawSpotInfo()}
        {this.drawSectionHelperText(
          1,
          this.props.i18n._(
            'Le Code ARPP (Autorité de Régulation Professionnelle de la Publicité) et/ou le Code PUB ID peuvent vous être fournis par l’agence qui a réalisé le spot.'
          )
        )}
        {this.drawMediaInfo()}
        {this.drawSectionHelperText(
          2,
          this.props.i18n._(
            'Pour une diffusion en télévision française, merci de préciser une date, une heure et une chaîne en particulier'
          )
        )}
        {this.drawListingsMusic()}
        {this.drawAddMusicButton()}
        {this.drawInfoNotice(
          this.props.i18n._(
            'En cas de création d’une nouvelle bande sonore incluant la signature musicale de la marque (ou logo musical de marque), il vous appartient d’obtenir l’accord des ayants droit concernés (votre responsabilité à l’égard de ces ayants droit est engagée).'
          )
        )}
        {this.drawEnterpriseInformation()}
        {this.drawButtons()}
      </MD.Paper>
    )
  }

  private renderFormulaireContentGermany = () => {
    const { classes } = this.props
    return (
      <MD.Paper
        className={classes.declarationPaper}
        style={{ height: 'max-content' }}
      >
        {this.drawConfirmationDownload()}
        {this.drawConfirmationDialog()}
        {this.drawHeaderGema()}
        {this.drawSubHeaderGema()}
        {this.drawHeaderTitleGema()}
        {this.drawHeaderSubTitleGema()}
        {this.drawProductionSectionGema()}
        {this.drawUtilisationSectionGema()}
        {this.drawTypeSectionGema()}
        {this.drawRegionSectionGema()}
        {this.drawMusicSectionGema()}
        {this.drawDeclarationSectionGema()}
        {this.drawSignatureSectionGema()}
        {this.drawFooterGema()}
        {this.drawButtons()}
      </MD.Paper>
    )
  }

  private renderFormulaireContent = () => {
    const { country } = this.props.search.params

    if (country === COUNTRY_OPTIONS.FRANCE) {
      return this.renderFormulaireContentFrance()
    } else if (country === COUNTRY_OPTIONS.GERMANY) {
      return this.renderFormulaireContentGermany()
    }
  }

  public render(): React.ReactNode {
    return (
      <MD.Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ overflow: 'scroll', height: '95vh' }}
      >

        {this.drawConfirmationDownload()}
        {this.drawConfirmationDialog()}
        {this.props.formulaire.isLoading && <MD.CircularProgress />}
        {/* {this.drawWarningMessageContainer()} */}
        {this.props.formulaire.isReceived && this.renderFormulaireContent()}
      </MD.Grid>
    )
  }
}

const mapStateToProps = ({
  i18n,
  formulaire,
  search,
  user,
  router,
}: IApplicationState) => ({
  i18n: i18n.i18n,
  formulaire,
  search,
  user,
  router,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFormulaire: (fingerprint_list: IFingerprintBucketPair[]) =>
    dispatch(fetchFormulaire(fingerprint_list)),
  saveFormulaire: (params: IFormulaireFranceState | IFormulaireGemaState) =>
    dispatch(saveFormulaire(params)),
  getFormulairePreview: (
    regroupingId: string,
    bucketId: number,
    spotId: number,
    fingerprint_list: IFingerprintBucketPair[]
  ) =>
    dispatch(
      getFormulairePreview(regroupingId, bucketId, spotId, fingerprint_list)
    ),
})

export const FormulaireContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(FormulaireComponent)))
